import {
	PartnerItem,
	PartnersText,
	SeventhSectionContainer,
} from './SeventhSection.styles';
import filecoin from '../../../../assets/filecoin.png';
import c from '../../../../assets/c.png';
import bloxberg from '../../../../assets/bloxberg.png';
import desciLondon from '../../../../assets/desciLondon.png';
export function SeventhSection() {
	return (
		<SeventhSectionContainer>
			<PartnersText>Partners:</PartnersText>
			<PartnerItem url={filecoin} />
			<PartnerItem url={c} />
			<PartnerItem url={bloxberg} />
			<PartnerItem url={desciLondon} />
		</SeventhSectionContainer>
	);
}
