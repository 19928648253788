import axios from 'axios';
import { createContext, ReactElement, useContext, useState } from 'react';
import {
	MosaicAnswer,
	MosaicQuestion,
	MosaicResponse,
	MosaicTag,
} from '../types';
const test = true; // change on dev time
const MOSAIC_API_GET_BASE_URL =
	test === true
		? 'https://mosaicnetwork.co/version-test/api/1.1/obj'
		: 'https://mosaicnetwork.co/api/1.1/obj';
const MOSAIC_API_POST_BASE_URL =
	test === true
		? 'https://mosaicnetwork.co/version-test/api/1.1/wf'
		: 'https://mosaicnetwork.co/api/1.1/wf';
const MOSAIC_API_KEY = 'a141bd4fc46d5d9ce6f4e4135d0e0aa3';
export function useMosaicAPIState() {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);
	const [questions, setQuestions] = useState<MosaicQuestion[]>([]);
	const [tags, setTags] = useState<MosaicTag[]>([]);
	const [answers, setAnswers] = useState<MosaicAnswer[]>([]);
	const [pagination, setPagination] = useState<{
		cursor: number;
		count: number;
		remaining: number;
	}>({ cursor: 1, count: 1, remaining: 2 });
	const [answerPagination, setAnswerPagination] = useState<{
		cursor: number;
		count: number;
		remaining: number;
	}>({ cursor: 1, count: 1, remaining: 2 });
	const [currentQuestion, setCurrentQuestion] = useState<MosaicQuestion>();
	function clearState() {}

	async function getMosaicTags() {
		try {
			setLoading(true);
			console.log('get questions!');
			const {
				data: {
					response: { results: tags, ...pagination },
				},
			} = await axios.get<{
				response: MosaicResponse & { results: MosaicQuestion[] };
			}>(
				//,{"key":"Tags","constraint_type":"contains","value":"DeSci"}
				`${MOSAIC_API_GET_BASE_URL}/tag?constraints=[{"key":"Client","constraint_type":"contains","value":"desci-world"}]&limit=1000`,
			);
			setTags(tags);
			setLoading(false);
			return tags;
		} catch (e) {
			setLoading(false);
			// console.log('twitter', { e });
			setError(e);
			console.log(e);
		}
	}

	async function getQuestionById(id: string) {
		try {
			setLoading(true);
			const {
				data: {
					response: {
						results: [question],
					},
				},
			} = await axios.get<{
				response: MosaicResponse & { results: MosaicQuestion[] };
			}>(
				//,{"key":"Tags","constraint_type":"contains","value":"DeSci"}
				`${MOSAIC_API_GET_BASE_URL}/question?constraints=[{"key":"_id","constraint_type":"equals","value":"${id}"}]&sort_field=Created Date&descending=true&exclude_remaining=false&limit=1`,
			);
			setLoading(false);
			setCurrentQuestion(question);
			return question;
		} catch (e) {
			setLoading(false);
			setError(e);
			console.log(e);
		}
	}

	async function searchQuestionsByKeyword({
		keyword,
		tag,
		cursor,
		sortOrder = 'desc',
		sortBy = 'Created Date',
	}: {
		keyword: string;
		tag?: string;
		cursor?: number;
		sortOrder?: string;
		sortBy?: string;
	}) {
		try {
			setLoading(true);
			console.log('get questions!');
			const {
				data: {
					response: { results: questions, ...pagination },
				},
			} = await axios.get<{
				response: MosaicResponse & { results: MosaicQuestion[] };
			}>(
				//,{"key":"Tags","constraint_type":"contains","value":"DeSci"}
				`${MOSAIC_API_GET_BASE_URL}/question?constraints=[{"key":"Title","constraint_type":"text contains","value":"${keyword}"},{"key": "Status","constraint_type":"equals","value":"allowed"}${
					tag
						? `,{"key":"Tags","constraint_type":"contains","value":"${tag}"}`
						: ''
				}]&exclude_remaining=false&limit=10${
					cursor ? `&cursor=${cursor}` : ''
				}&sort_field=${sortBy}&descending=${sortOrder === 'desc'}`,
			);
			setQuestions(questions);
			setPagination(pagination);
			setLoading(false);

			return questions;
		} catch (e) {
			setLoading(false);
			// console.log('twitter', { e });
			setError(e);
			console.log(e);
		}
	}

	async function getAnswersByQuestionId(questionId: string, cursor?: number) {
		setLoading(true);
		console.log('get answers!');
		const {
			data: {
				response: { results: answers, ...pagination },
			},
		} = await axios.get<{
			response: MosaicResponse & { results: MosaicAnswer[] };
		}>(
			`${MOSAIC_API_GET_BASE_URL}/response?constraints=[{"key":"Associated question", "constraint_type": "equals", "value": "${questionId}"}, {"key": "Status", "constraint_type": "equals", "value": "allowed"}]&sort_field=Created Date&descending=true&exclude_remaining=false&limit=10${
				cursor ? `&cursor=${cursor}` : ''
			}`,
		);
		setAnswers(answers);
		setAnswerPagination(pagination);
		setLoading(false);

		return answers;
	}

	async function postMosaicQuestion(
		title: string,
		description: string,
		tags: { label: string; value: string }[],
	) {
		try {
			setLoading(true);

			const {
				data: {
					response: {
						results: [question],
					},
				},
			} = await axios.post<{
				response: MosaicResponse & { results: MosaicQuestion[] };
			}>(
				`${MOSAIC_API_POST_BASE_URL}/desci-question`,
				{
					title: title,
					text: description,
					tag_1: 'DeSci', // hardcoded tag value
					tag_2: tags[0]?.label,
					tag_3: tags[1]?.label,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + MOSAIC_API_KEY,
					},
				},
			);
			setLoading(false);
			setError(null);
			setResponse(question);
			return question;
		} catch (e) {
			setLoading(false);
			setError(e);
			console.log(e);
		}
	}

	async function postMosaicAnswer(questionId: string, answerText: string) {
		try {
			setLoading(true);
			const {
				data: {
					response: { answer },
				},
			} = await axios.post<{
				response: MosaicResponse & { answer: MosaicAnswer };
			}>(
				`${MOSAIC_API_POST_BASE_URL}/desci-answer`,
				{
					associated_question: questionId,
					text: answerText,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + MOSAIC_API_KEY,
					},
				},
			);
			setLoading(false);
			setAnswers([answer, ...answers]);
			setResponse(answer);
			return answer;
		} catch (e) {
			setLoading(false);
			setError(e);
			console.log(e);
		}
	}

	return {
		loading,
		error,
		response,
		questions,
		pagination,
		answerPagination,
		currentQuestion,
		answers,
		tags,
		clearState,
		searchQuestionsByKeyword,
		getQuestionById,
		getAnswersByQuestionId,
		postMosaicQuestion,
		postMosaicAnswer,
		getMosaicTags,
	};
}

export const MosaicAPIContext: React.Context<
	ReturnType<typeof useMosaicAPIState>
> = createContext({} as any);

export function MosaicAPIProvider({ children }: { children?: ReactElement }) {
	const api = useMosaicAPIState();
	return (
		<MosaicAPIContext.Provider value={api}>
			{children}
		</MosaicAPIContext.Provider>
	);
}

export function useMosaic() {
	return useContext(MosaicAPIContext);
}
