import styled from 'styled-components';

export const CommentSectionContainer = styled.div`
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: space-between;
	background: #a8c0c3;
`;

export const CommentsList = styled.ul`
	width: 30%;
	margin: 3% auto;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 95%;
	}
`;

export const CommentsListItem = styled.li`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
	border-bottom: 1px solid
		${({
			theme: {
				colors: { accent },
			},
		}) => accent};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 2rem 0;
	}
`;

export const CommentsListHeaderContainer = styled.div`
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const CommentsListHeaderTitle = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: #585a5e;
`;

export const CommentsListHeaderLikes = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	text-align: right;
	color: #585a5e;
`;

export const CommentsListUserContainer = styled.div`
	width: 90%;
	display: flex;
	justify-content: start;
	align-items: center;
	margin: 20px 0;
`;

export const CommentsListUsernameContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 40px;
`;

export const UserName = styled.p`
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin: 0;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-family: 'Space Grotesk';
		font-style: normal;
		font-weight: 700;
		font-size: 1.2rem;
		line-height: 32px;
	}
`;

export const DatePosted = styled.p`
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin: 0;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-family: 'Space Mono';
		font-style: normal;
		font-weight: 400;
		font-size: 0.8rem;
		line-height: 30px;
	}
`;

export const WriteAComment = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	text-decoration-line: underline;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin-top: 40px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 1.5rem 1rem;
	}
`;
