import styled from 'styled-components';

export const StyledInput = styled.input<{ disabled: boolean }>`
	width: 100%;
	/* border: ${({
		theme: {
			colors: { accent },
		},
	}) => accent}
		solid 1px; */

	&:focus {
		box-shadow: 0 0 0px 2px
			${({
				theme: {
					colors: { primary },
				},
			}) => primary};
		outline: 0;
	}
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	padding: 8px;
	box-sizing: border-box;
`;

export const InputContainer = styled.div<{ width?: string }>`
	display: flex;
	width: ${({ width }) => width || '100%'};
	min-width: 120px;
	flex-direction: column;
`;
