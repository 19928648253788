import styled from 'styled-components';

export const CardContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	position: relative;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	width: 100%;
	min-width: 22rem;
	min-height: 10rem;
`;

export const CardContentContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	box-sizing: border-box;
	background: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	width: 100%;
	min-height: 10rem;
	padding: 1rem;
	display: flex;
	align-items: stretch;
`;

export const CardContentContainerOffsetBorder = styled.div`
	box-sizing: border-box;
	box-shadow: 4px 4px 0px
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};
	min-width: 10rem;
	min-height: 10rem;
	width: 100%;
	padding: 1rem;
	display: flex;
	align-items: stretch;
	flex-grow: 1;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		box-shadow: none;
		padding: 0rem;
	}
`;
