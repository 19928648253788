import { useState } from 'react';
import { Button } from '../../../../components/Button';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import {
	ContactContainer,
	Description,
	FormContainer,
	MainSectionContainer,
	Title,
	FormLabel,
	FormInput,
	FormTextArea,
	DropdownContainer,
} from './Contact.styles';

const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);

const subjectOptions = [
	{
		value: 'Project Dashboard',
		label: 'Project Dashboard',
	},
	{
		value: 'NFT Launchpad',
		label: 'NFT Launchpad',
	},
	{
		value: 'Community',
		label: 'Community',
	},
	{
		value: 'Funding',
		label: 'Funding',
	},
	{
		value: 'Partnerships',
		label: 'Partnerships',
	},
	{
		value: 'other',
		label: 'other',
	},
];

export function Contact() {
	const [contactFormData, setContactFormData] = useState({
		name: '',
		email: '',
		message: '',
		subject: '',
	});

	const { name, email, message } = contactFormData;
	const {
		contactForm: { sendContactDetail },
	} = useAPI();

	const handleChange = (e: any) => {
		setContactFormData({ ...contactFormData, [e.target.name]: e.target.value });
	};

	const onSubmit = (e: any) => {
		e.preventDefault();
		if (
			contactFormData.name.length !== 0 &&
			contactFormData.email.length !== 0
		) {
			sendContactDetail(contactFormData);
		}
	};
	return (
		<ContactContainer>
			<Title>Contact</Title>
			<MainSectionContainer>
				<Description>
					For any enquiries, please fill out the contact form and the DeSciWorld
					team will get back to you.
				</Description>
				<FormContainer onSubmit={onSubmit}>
					<FormLabel>name</FormLabel>
					<FormInput
						name="name"
						value={name}
						onChange={handleChange}
						type="text"
						placeholder="Enter name"
					/>
					<FormLabel>email</FormLabel>
					<FormInput
						name="email"
						value={email}
						onChange={handleChange}
						type="text"
						placeholder="Enter email"
					/>
					<FormLabel>Subject</FormLabel>
					<DropdownContainer>
						<Dropdown
							placeholder="Select a subject"
							onChange={({ value }) => {
								setContactFormData({ ...contactFormData, subject: value });
							}}
							options={subjectOptions}
						/>
					</DropdownContainer>
					<FormLabel>message</FormLabel>
					<FormTextArea
						name="message"
						value={message}
						onChange={handleChange}
						placeholder="Enter message"
					/>
					<Button
						className="submit-btn"
						type="submit"
						disabled={
							!contactFormData.subject ||
							!contactFormData.email ||
							!contactFormData.name ||
							!contactFormData.message
						}
						onClick={() => {}}
					>
						Submit
					</Button>
				</FormContainer>
			</MainSectionContainer>
		</ContactContainer>
	);
}
