import { useEffect, useState } from 'react';
import { MosaicContainer } from '../Mosaic.styles';

import { useNavigate, useParams } from 'react-router-dom';
import { useMosaic } from '../../../../../hooks/useMosaic';
import {
	AskFormContainer,
	PageTitle,
	TopFormContainer,
} from './AskQuestion.styles';
import { Input, TextArea } from '../../../../../components/Form';
import { Button } from '../../../../../components/Button';
import { Dropdown } from '../../../../../components/Dropdown/Dropdown';
import {
	BackButtonContainer,
	BackButtonText,
} from '../QuestionDetail/QuestionDetail.styles';
import { Icon } from '../../../../../components/Icon';

export function MosaicQuestionAsk() {
	const [questionTitle, setQuestionTitle] = useState('');
	const [tags, setTags] = useState([]);
	const [questionContent, setQuestionContent] = useState('');
	const [shouldClose, setShouldClose] = useState(false);
	const { id } = useParams();
	const navigate = useNavigate();
	const {
		loading,
		postMosaicQuestion,
		getMosaicTags,
		tags: mosaicTags,
		response,
		error,
	} = useMosaic();
	useEffect(() => {
		if (!tags?.length) {
			getMosaicTags();
		}
	}, [id]);

	console.log({ tags });
	return (
		<MosaicContainer>
			<BackButtonContainer
				onClick={() => {
					navigate('/mosaic');
				}}
			>
				<Icon name="arrowLeft" width="1.2rem" />
				<BackButtonText>back</BackButtonText>
			</BackButtonContainer>
			<PageTitle>Ask a question</PageTitle>
			<AskFormContainer>
				<TopFormContainer>
					<Input
						placeholder="Short title"
						onChange={setQuestionTitle}
						value={questionTitle}
					/>
					<Dropdown
						placeholder="Select topics"
						options={mosaicTags?.map((tag) => ({
							label: tag.Name,
							value: tag._id,
						}))}
						onChange={(opts) => {
							setTags(opts);
						}}
						isMulti
						limit={2}
					/>
				</TopFormContainer>
				<TextArea
					placeholder="What is your question?"
					onChange={setQuestionContent}
					value={questionContent}
				/>
				<Button
					onClick={() => {
						postMosaicQuestion(questionTitle, questionContent, tags).then(
							() => {
								navigate('/mosaic');
							},
						);
					}}
					disabled={loading || !questionTitle || !questionContent}
				>
					Ask
				</Button>
			</AskFormContainer>
		</MosaicContainer>
	);
}
