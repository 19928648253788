import axios from 'axios';
import { createContext, ReactElement, useContext, useState } from 'react';
import { Tweet } from '../types';
const GITCOIN_TAG_FILTER_API_URL =
	'https://gitcoin.co/grants/cards_info?page=1&limit=500&me=false&sort_option=weighted_shuffle&collection_id=false&network=mainnet&state=active&profile=false&sub_round_slug=false&collections_page=1&grant_regions=&grant_types=&grant_tags=*Climate Solutions,*Longevity,COVID19 research,COVID19 response&tenants=ETH&idle=false&featured=true&round_type=false&tab=grants';
const TWITTER_API_URL = 'https://api.twitter.com/2/';
const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3/';
export function useThirdPartyAPIState() {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);
	/** twitter */
	const [recentTweets, setRecentTweets] = useState<Tweet[]>();
	/**gitcoin  */
	const [grantCount, setGrantCount] = useState<number>(0);
	/** coingecko */
	const [historicalPrices, setHistoricalPrices] = useState([]);
	async function getTotalGitcoinGrants() {
		try {
			setLoading(true);
			const {
				data: { count },
			} = await axios.get<{ count: number }>(`${GITCOIN_TAG_FILTER_API_URL}`, {
				headers: {
					// 'User-Agent': 'PostmanRuntime/7.26.8',
					Accept: '*/*',
				},
			});
			console.log({ count });
			setGrantCount(count);
			setResponse(count);
			setLoading(false);
			return count;
		} catch (e) {
			// console.log('gitcoin', { e });
			setError(e);
		}
	}

	async function getRecentTweets(username: string) {
		try {
			setLoading(true);
			console.log({ key: process.env });
			const {
				data: { data: tweets },
			} = await axios.get<{ data: Tweet[] }>(
				`${TWITTER_API_URL}tweets/search/recent?query=from:${username}&tweet.fields=created_at&expansions=author_id&user.fields=created_at`,
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_TWITTER_BEARER_TOKEN}`,
						Accept: '*/*',
					},
				},
			);
			tweets.splice(0, 3);
			setRecentTweets(tweets.slice(0, 3));
			setLoading(false);
			return tweets;
		} catch (e) {
			// console.log('twitter', { e });
			setError(e);
		}
	}

	async function getPriceChartData(id: string) {
		setLoading(true);
		const {
			data: { prices },
		} = await axios.get(
			`${COINGECKO_API_URL}coins/${id}/market_chart?vs_currency=usd&days=30&interval=daily`,
		);
		setHistoricalPrices(prices);
		setLoading(false);
		return prices;
	}

	function clearState() {
		setRecentTweets(null);
		setHistoricalPrices(null);
	}

	return {
		loading,
		error,
		response,
		grantCount,
		recentTweets,
		historicalPrices,
		getRecentTweets,
		getTotalGitcoinGrants,
		getPriceChartData,
		clearState,
	};
}

export const ThirdPartyAPIContext: React.Context<{
	error: any;
	loading: boolean;
	response: any;
	grantCount: number;
	getTotalGitcoinGrants: () => Promise<number>;
	recentTweets: Tweet[];
	getRecentTweets: (username: string) => Promise<Tweet[]>;
	historicalPrices: number[][];
	getPriceChartData: (id: string) => Promise<number[][]>;
	clearState: () => void;
}> = createContext({} as any);

export function ThirdPartyAPIProvider({
	children,
}: {
	children?: ReactElement;
}) {
	const api = useThirdPartyAPIState();
	return (
		<ThirdPartyAPIContext.Provider value={api}>
			{children}
		</ThirdPartyAPIContext.Provider>
	);
}

export function useThirdPatryAPIs() {
	return useContext(ThirdPartyAPIContext);
}
