import {
	ImageContainer,
	LeftContentContainer,
	MainSectionContainer,
	MintButton,
	NetworkName,
	WhitelistCurrencyText,
	WhitelistDescription,
	WhitelistDescriptionContainer,
	WhitelistDescriptionFooterContainer,
	WhitelistInfoContainer,
	WhitelistName,
	WhitelistPriceContainer,
	WhitelistPriceText,
	WhitelistSuply,
	RightContentContainer,
	ButtonContainer,
	DisclaimerText,
	JoinLink,
	InfoMessage,
	Container,
} from './MainSection.styles';
import proteinGif from '../../../../../../assets/Cryptoproteins.gif';

export function MainSection({ whitelistInfo }: any) {
	return (
		<MainSectionContainer>
			<InfoMessage>
				Disclaimer: In a special partnership between DNAVERSE and DeSciWorld,
				our community has been given 15 spots for whitelist in the upcoming
				CRYPTOPROTEINS mint planned on 5-8 august. Please note: the whitelist
				spots are limited in number and thus are valuable. Please do not apply
				for whitelist if you do not plan to purchase. DeSciWorld members that
				whitelist their addresses, and purchase a Cryptoprotein will also
				receive a free Microbes NFT.
			</InfoMessage>
			<Container>
				<LeftContentContainer>
					<ImageContainer src={proteinGif}></ImageContainer>
				</LeftContentContainer>
				<RightContentContainer>
					<WhitelistDescriptionContainer>
						<NetworkName>
							Network: <span>{whitelistInfo?.network}</span>
						</NetworkName>
						<WhitelistInfoContainer>
							<WhitelistName>{whitelistInfo?.title}</WhitelistName>
							<WhitelistPriceContainer>
								<WhitelistCurrencyText>eth</WhitelistCurrencyText>
								<WhitelistPriceText>
									{whitelistInfo?.whitelistPrice}
								</WhitelistPriceText>
							</WhitelistPriceContainer>
						</WhitelistInfoContainer>
						<WhitelistDescription>
							{whitelistInfo.description}
						</WhitelistDescription>
						<WhitelistDescriptionFooterContainer>
							<WhitelistSuply>
								<span>Total Supply: {whitelistInfo.totalSupply}</span>
							</WhitelistSuply>

							<MintButton
								// disabled={
								// 	collectionInfo?.count >=
								// 	(mintContract?.totalSupply || collectionInfo?.totalSupply)
								// 		? true
								// 		: !ready
								// }
								onClick={() => {}}
							>
								<JoinLink
									href="https://dnaverse.io/desciworld/"
									target="_blank"
								>
									Join WhiteList
								</JoinLink>
							</MintButton>
						</WhitelistDescriptionFooterContainer>
						<DisclaimerText>
							* Remember to get your guaranteed spot in the whitelist you need
							to use referral code DESCIWORLD *
						</DisclaimerText>
					</WhitelistDescriptionContainer>
				</RightContentContainer>
			</Container>
		</MainSectionContainer>
	);
}
