import {
	CaptionedImageContainer,
	CaptionedImageImage,
	CaptionedImageText,
} from './CaptionedImage.styles';

export function CaptionedImage({
	url,
	caption,
	link,
}: {
	url: string;
	caption: string;
	link?: string;
}) {
	return (
		<CaptionedImageContainer
			onClick={() => {
				if (link) {
					window.open(link, '_blank');
				}
			}}
		>
			<CaptionedImageImage url={url} />
			<CaptionedImageText>{caption}</CaptionedImageText>
		</CaptionedImageContainer>
	);
}
