import React from 'react';
import { StyledBurger } from './Burger.styles';


export function Burger({
  open,
  setOpen
}: {
  open?:boolean, setOpen?:any ;
}) {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
    </StyledBurger>
  )
}

