import styled from 'styled-components';

export const StyledLabel = styled.span`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	margin-left: 12px;
`;
