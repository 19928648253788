import styled from 'styled-components';

export const VerticalNoteCardContainer = styled.div`
	position: relative;
	display: inline-block;
	margin: 0 30px;
`;

export const VerticalNoteCardContentContainer = styled.div`
	background: white;
	clip-path: polygon(100% 0%, 100% 90%, 83% 100%, 0% 100%, 0% 10%, 17% 0%);
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	max-height: 600px;
	max-width: 360px;
	height: 70vh;
	width: 320px;
	position: relative;
	box-shadow: 97px 16px 24px black;
`;

export const TopContainer = styled.div`
	height: 25%;
	display: flex;
	align-items: center;
`;

export const BottomContainer = styled.div`
	display: flex;
	height: 75%;
	flex-direction: column;
	padding: 0px 28px;
`;
export const VerticalNoteCardImage = styled.div<{ src: string }>`
	border-radius: 100%;
	background: black url(${({ src }) => src}) no-repeat center center/cover;
	width: 96px;
	height: 96px;
	margin: 40px 40px;
	border: 4px solid black;
`;

export const VerticalNoteCardTitleContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: left;
	align-items: start;
`;

export const VerticalNoteCardTitle = styled.span`
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	font-style: normal;
	font-weight: bold;
	font-size: 3vh;
	line-height: 30px;
	text-align: left;
	align-self: start;
`;
