import styled from 'styled-components';
import { Button } from '../../../../components/Button';

export const CardContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	flex-direction: column;
	align-items: center;
`;

export const ImageOffsetBorder = styled.div`
	width: 87%;
	min-width: 220px;
	min-height: 120px;
	height: 30%;
	background: transparent;
	box-shadow: 4px 0px 0px
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};
	display: block;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		box-shadow: none;
		width: 77%;
		height: 40%;
	}
`;
export const StyledVideoContainer = styled.div`
	width: 80%;
	height: 280px;
	box-shadow: 8px 8px 20px
		${({
			theme: {
				colors: { accent },
			},
		}) => accent}32;
	position: absolute;
	top: 8px;
	z-index: 1;
	border: 2px solid white;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const StyledCardVideo = styled.video`
	/* Setting width & height to auto prevents the browser from stretching or squishing the video */
	width: 100%;
	height: 100%;

	object-fit: fill;
`;

export const StyledCardImage = styled.div<{ url?: string }>`
	min-width: 200px;
	width: 80%;
	height: auto;
	box-shadow: 8px 8px 20px
		${({
			theme: {
				colors: { accent },
			},
		}) => accent}32;
	position: absolute;
	background: ${({
		theme: {
			colors: {
				background: { lightGray },
			},
		},
		url,
	}) => `url(${url}) 100% 100%/cover` || lightGray};
	top: 8px;
	z-index: 1;
	border: 2px solid white;
	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;
export const CardTextContainer = styled.div`
	top: 10%;
	left: 2%;
	width: 94%;
	height: 80%;
	background: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	position: relative;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		top: 5%;
		left: 3%;
		width: 94%;
		height: 75%;
	}
`;

export const TextContainer = styled.div`
	padding: 35% 42px 0px 42px;
	color: black;
	height: 45%;
	display: flex;
	flex-direction: column;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 50% 45px 0px 45px;
	}
`;

export const CardTextContainerOffsetBorder = styled.div`
	box-shadow: 4px 4px 0px
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};
	width: 430px;
	height: 450px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		box-shadow: none;
		width: 350px;
		height: auto;
	}
`;

export const TopTextContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 2px;
`;
export const SmallCardText = styled.span`
	font-size: 16px;
	line-height: 30px;
`;
export const LargeCardText = styled.span`
	font-style: normal;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 30px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 24px;
	}
`;
export const DescriptionTextContainer = styled.div`
	margin-top: 10px;
	max-height: 80px;
	height: 120px;
`;

export const DescriptionText = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: block;
	height: 100%;
	overflow-y: hidden;
	text-overflow: ellipsis;
	text-align: left;
	display: -webkit-box;
	@media ${({
			theme: {
				device: { large },
			},
		}) => large} {
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`;

export const BottomContainer = styled.div`
	margin-top: 5%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-top: auto;
		flex-direction: column;
		padding: 18px 0px;
	}
`;

export const InvestButton = styled(Button)`
	width: 40%;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;
