import styled from 'styled-components';

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 40px auto;
`;
export const TableSectionContainer = styled.div`
	padding: 50px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		padding: 10px 0px;
	}
`;
export const TableTopSectionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 8px 0px;
`;
export const DropdownContainer = styled.div`
	width: 220px;
`;
