import styled from 'styled-components';
import Ellipse from '../../../../../../assets/ellipse.png';

export const Container = styled.div`
	width: 85%;
	margin: 0 10%;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 2%;
	}
`;

export const Title = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 76px;
	line-height: 76px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 400;
		font-size: 25px;
		line-height: 38px;
		text-align: left;
		letter-spacing: -0.03em;
	}
`;

export const DescriptionContainer = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: start;
	}
`;

export const DescriptionTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60%;
	margin-right: 8%;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 0;
	}
`;

export const DescriptionTitle = styled.span`
	font-style: normal;
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 700;
		font-size: 20px;
		line-height: 30px;
		text-align: left;
		letter-spacing: -0.03em;
	}
`;

export const Description = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const DescriptionImage = styled.div`
	width: 391px;
	height: 391px;
	background: url(${Ellipse});
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 150px;
		height: 150px;
		align-self: center;
	}
`;

export const TeamContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	align-items: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: center;
		align-content: center;
		width: 100%;
	}
`;
