import styled from 'styled-components';
import twitter from '../../../../assets/twitter.svg';
import discord from '../../../../assets/discord.svg';
import github from '../../../../assets/github.svg';
export const FooterContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	display: flex;
	/* justify-content:center;*/
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
`;

export const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 10%;
	margin-left: 100px;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const FooterLink = styled.a`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	text-decoration: none;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const SocialsContainer = styled.div`
	display: flex;
	width: 15%;
	justify-content: space-evenly;
	margin-right: 100px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 0;
	}
`;
export const SocialsIcon = styled.div`
	width: 48px;
	height: 48px;
	&:hover {
		cursor: pointer;
	}
`;

export const TwitterIcon = styled(SocialsIcon)`
	background: transparent url(${twitter}) no-repeat center center/cover;
`;

export const GithubIcon = styled(SocialsIcon)`
	background: transparent url(${github}) no-repeat center center/cover;
`;

export const DiscordIcon = styled(SocialsIcon)`
	background: transparent url(${discord}) no-repeat center center/cover;
`;
