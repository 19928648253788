import { useState } from 'react';
import { SortButton } from '../SortButton';
import { SortButtonType } from '../SortButton/types';
import { SortButtonBarContainer } from './SortButtonBar.styles';

export function SortButtonBar({
	buttons,
	onChange,
	activeKey,
}: {
	buttons: SortButtonType[];
	activeKey: string;
	onChange: (key: string) => void;
}) {
	return (
		<SortButtonBarContainer>
			{buttons.map(({ text, buttonKey, variant }, index) => {
				return (
					<SortButton
						active={buttonKey === activeKey}
						variant={variant}
						text={text}
						onClick={(buttonKey) => {
							onChange(buttonKey);
						}}
						buttonKey={buttonKey}
						key={index}
					/>
				);
			})}
		</SortButtonBarContainer>
	);
}
