import styled from 'styled-components';

export const JobCardContainer = styled.div``;

export const JobLogo = styled.img`
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
`;
