import {
	Badge,
	Description,
	ItemContainer,
	ItemLogo,
	TextContainer,
	Title,
} from './ListItem.styles';

export function ListItem({
	title,
	description,
	mediaUrl,
	badgeText,
	onClick,
}: {
	title: string;
	description: string;
	mediaUrl: string;
	badgeText?: string;
	onClick?: () => void;
}) {
	return (
		<ItemContainer onClick={onClick && onClick} clickable={!!onClick}>
			<ItemLogo src={mediaUrl} />
			<TextContainer>
				<Title>{title}</Title>
				<Description>{description}</Description>
			</TextContainer>
			{badgeText && <Badge>{badgeText}</Badge>}
		</ItemContainer>
	);
}
