import { Carousel } from '../../../../../../components/Carousel';
import { TabBar } from '../../../../../../components/TabBar';
import {
	SlidesSectionContainer,
	Slides,
	SlidesCarouselContainer,
} from './SlidesSection.styles';

export function SlidesSection({ routes }: any) {
	return (
		<SlidesSectionContainer>
			{routes && <TabBar color="softBlack" tabs={routes} />}
			<SlidesCarouselContainer>
				<Carousel>
					{[1, 2, 3, 4].map((item) => (
						<Slides />
					))}
				</Carousel>
			</SlidesCarouselContainer>
		</SlidesSectionContainer>
	);
}
