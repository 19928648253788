import { useAPI } from '../../../../../../hooks/useDesciWorldAPI';
import { useWeb3 } from '../../../../../../hooks/useWeb3';
import {
	CollectionInfo,
	MintContract,
	MintContractType,
} from '../../../../../../types';
import { useViewport } from '../../../../../../utils/viewPortHook';
import {
	ImageContainer,
	LeftContentContainer,
	MainSectionContainer,
	MintButton,
	MintMultipleContainer,
	NetworkName,
	NftCurrencyText,
	NftDescription,
	NftDescriptionContainer,
	NftDescriptionFooterContainer,
	NftInfoContainer,
	NftName,
	NftPriceContainer,
	NftPriceText,
	NftSuply,
	RightContentContainer,
	StyledLogoItem,
	TokenCategoryContainer,
	TokenCategoryName,
	TokenCategoryDescription,
	TokenNameAndInputContainer,
} from './MainSection.styles';
import opensea from '../../../../../../assets/icons/opensea.png';
import { useEffect, useReducer, useState } from 'react';
import { Modal } from '../../../../../../components/Modal/Modal';
import { Input } from '../../../../../../components/Form';
import { Dropdown } from '../../../../../../components/Dropdown/Dropdown';
import { arrayReducer } from '../../../../../../utils/arrayReducer';
export function MainSection({
	collectionInfo,
	mintContract,
}: {
	collectionInfo?: CollectionInfo;
	mintContract?: MintContract;
}) {
	const {
		mintToken,
		mintMultiToken,
		mintBatchUnique,
		mintingNFT,
		ready,
		setMintingContract,
		mintTokensByCategory,
	} = useWeb3();
	const [mintAmount, setMintAmount] = useState(null);
	const [selectedTokenId, setSelectedTokenId] = useState();
	const {
		contract: { contract },
	} = useAPI();
	const [isMultiTokenModalOpen, setIsMultiTokenModalOpen] = useState(false);
	const [isBatchUniqueModalOpen, setIsBatchUniqueModalOpen] = useState(false);
	const [isMintByCategoryModalOpen, setIsMintByCategoryModalOpen] =
		useState(false);
	const [amountByCategoryArray, setAmountByCategoryArray] = useReducer(
		arrayReducer,
		[],
	);
	const { width } = useViewport();
	const breakpoint = 475;

	function renderSupply() {
		const supply = mintContract?.totalSupply || collectionInfo?.totalSupply;
		if (collectionInfo?.count >= supply) {
			return 'Sold out';
		}
		return `Supply: ${collectionInfo?.count}/${supply}`;
	}

	function handleMintClick() {
		if (mintContract?.type === MintContractType.mintByCategory) {
			setIsMintByCategoryModalOpen(true);
		} else if (
			mintContract?.type === MintContractType.batchMintUnique ||
			mintContract?.batchMintUnique
		) {
			setIsBatchUniqueModalOpen(true);
		} else if (
			mintContract?.type === MintContractType.multiToken ||
			mintContract?.multiToken
		) {
			setIsMultiTokenModalOpen(true);
		} else {
			mintToken();
		}
	}

	useEffect(() => {
		if (mintContract) {
			setMintingContract(mintContract);
		}
	});
	return (
		<MainSectionContainer>
			<Modal
				onClose={() => {
					setIsMultiTokenModalOpen(false);
				}}
				title="Mint Tokens"
				isOpen={isMultiTokenModalOpen}
			>
				<MintMultipleContainer>
					<Dropdown
						onChange={(option) => {
							setSelectedTokenId(option.value);
						}}
						options={mintContract?.tokens?.map(({ name, id, price }) => ({
							value: id,
							label: `${name} | ${price} eth`,
						}))}
						placeholder="Select Token to mint"
					/>
					<Input
						type="number"
						onChange={(value) => {
							if (+value) {
								setMintAmount(Math.round(+value));
							} else {
								setMintAmount(value);
							}
						}}
						value={mintAmount}
						placeholder={
							width < breakpoint
								? 'Number of tokens'
								: 'How many tokens do you want to mint?'
						}
						min="1"
						step="1"
					/>
					<MintButton
						disabled={!mintAmount || !selectedTokenId}
						onClick={() => {
							//mint multiple
							mintMultiToken(selectedTokenId, mintAmount).then(() => {
								console.log('finished!');
								setIsMultiTokenModalOpen(false);
							});
						}}
					>
						Mint it!
					</MintButton>
				</MintMultipleContainer>
			</Modal>

			<Modal
				onClose={() => {
					setIsBatchUniqueModalOpen(false);
				}}
				isOpen={isBatchUniqueModalOpen}
				title="Mint Tokens"
			>
				<MintMultipleContainer>
					<Input
						type="number"
						onChange={(value) => {
							if (+value > mintContract?.maxMintPerTx) {
								setMintAmount(mintContract?.maxMintPerTx);
							} else if (+value) {
								setMintAmount(Math.round(+value));
							} else {
								setMintAmount(value);
							}
						}}
						value={mintAmount}
						placeholder={
							width < breakpoint
								? 'Number of tokens'
								: 'How many tokens do you want to mint?'
						}
						min="1"
						max={mintContract?.maxMintPerTx?.toString()}
						step="1"
					/>
					<MintButton
						disabled={!mintAmount}
						onClick={() => {
							//mint multiple
							mintBatchUnique(mintAmount).then(() => {
								console.log('finished!');
								setIsBatchUniqueModalOpen(false);
							});
						}}
					>
						Mint it!
					</MintButton>
				</MintMultipleContainer>
			</Modal>
			<Modal
				onClose={() => {
					setIsMintByCategoryModalOpen(false);
				}}
				isOpen={isMintByCategoryModalOpen}
				title="Mint Tokens by category"
			>
				<MintMultipleContainer customHeight="max-content;">
					{mintContract?.categories?.map((tokenCategory, index) => (
						<TokenCategoryContainer>
							<TokenNameAndInputContainer>
								<TokenCategoryName>
									{tokenCategory.name} | {tokenCategory.price} ETH
								</TokenCategoryName>
								<Input
									type="number"
									onChange={(value) => {
										setAmountByCategoryArray({
											index,
											value,
										});
									}}
									value={amountByCategoryArray[index]}
									placeholder={
										width < breakpoint ? '# of tokens' : 'Number of tokens'
									}
								/>
							</TokenNameAndInputContainer>
							<TokenCategoryDescription>
								{tokenCategory.description}
							</TokenCategoryDescription>
						</TokenCategoryContainer>
					))}
					<MintButton
						disabled={!amountByCategoryArray.length}
						onClick={() => {
							const amounts = [];
							console.log({ amountByCategoryArray });
							for (let i = 0; i < mintContract?.categories.length; i++) {
								if (amountByCategoryArray[i] > 0) {
									amounts[i] = +amountByCategoryArray[i];
								} else {
									amounts[i] = 0;
								}
							}
							mintTokensByCategory(amounts).then(() => {
								console.log('finished!');
								setIsMintByCategoryModalOpen(false);
							});
						}}
					>
						Mint it!
					</MintButton>
				</MintMultipleContainer>
			</Modal>
			<LeftContentContainer>
				<ImageContainer
					url={mintContract?.mediaUrl || collectionInfo?.mediaUrl}
				></ImageContainer>
			</LeftContentContainer>
			<RightContentContainer>
				<NftDescriptionContainer>
					<NetworkName>
						Network: <span>Ethereum</span>
					</NetworkName>
					<NftInfoContainer>
						<NftName>{mintContract?.name || collectionInfo?.name}</NftName>
						<NftPriceContainer>
							<NftCurrencyText>
								{mintContract?.dynamicPrice ? '' : 'eth'}
							</NftCurrencyText>
							<NftPriceText>{contract?.value}</NftPriceText>
						</NftPriceContainer>
					</NftInfoContainer>
					<NftDescription>
						{width < breakpoint
							? mintContract?.description.substring(0, 260) ||
							  collectionInfo?.description?.substring(0, 260)
							: mintContract?.description || collectionInfo?.description}
					</NftDescription>
					<NftDescriptionFooterContainer>
						<NftSuply>
							{!mintContract?.multiToken && <span>{renderSupply()}</span>}
							{mintContract?.multiToken && (
								<span>Total tokens supply: {mintContract.totalSupply} </span>
							)}
						</NftSuply>
						{collectionInfo?.url && (
							<StyledLogoItem
								src={opensea}
								href={collectionInfo?.url}
								target="_blank"
								title={`Check ${
									mintContract?.name || collectionInfo?.name
								} on OpenSea`}
							/>
						)}
						<MintButton
							disabled={
								collectionInfo?.count >=
								(mintContract?.totalSupply || collectionInfo?.totalSupply)
									? true
									: !ready
							}
							onClick={() => {
								const checkSupply =
									collectionInfo?.count <
									(mintContract?.totalSupply || collectionInfo?.totalSupply);
								// console.log('clicked', { checkSupply });
								if (checkSupply || !collectionInfo) {
									handleMintClick();
								}
							}}
						>
							{!!collectionInfo?.count &&
							collectionInfo?.count >=
								(mintContract?.totalSupply || collectionInfo?.totalSupply)
								? 'Sold Out'
								: !ready
								? 'Connect your wallet to mint!'
								: mintingNFT
								? 'Minting...'
								: 'Mint it!'}
						</MintButton>
					</NftDescriptionFooterContainer>
				</NftDescriptionContainer>
			</RightContentContainer>
		</MainSectionContainer>
	);
}
