import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FifthSection } from './components/FifthSection';
import { Footer } from './components/Footer';
import { FourthSection } from './components/FourthSection';
import { MainSection } from './components/MainSection';
import { NavBar } from './components/NavBar';
import { SecondSection } from './components/SecondSection';
import { SeventhSection } from './components/SeventhSection';
import { SixthSection } from './components/SixthSection';
import { ThirdSection } from './components/ThirdSection';
import { ScrollableToSection } from './Landing.styles';
export function Landing() {
	const navigate = useNavigate();
	const mainSectionRef = useRef(null);
	const secondSectionRef = useRef(null);
	const thirdSectionRef = useRef(null);
	const fourthSectionRef = useRef(null);
	const fifthSectionRef = useRef(null);
	const sixthSectionRef = useRef(null);
	const seventhSectionRef = useRef(null);
	const routes = [
		// {
		// 	title: 'Community',
		// 	onClick() {
		// 		if (fifthSectionRef?.current) {
		// 			(fifthSectionRef.current as any).scrollIntoView({
		// 				behavior: 'smooth',
		// 			});
		// 		}
		// 	},
		// },
		// {
		// 	title: 'NFT',
		// 	onClick() {
		// 		if (fourthSectionRef?.current) {
		// 			(fourthSectionRef.current as any).scrollIntoView({
		// 				behavior: 'smooth',
		// 			});
		// 		}
		// 	},
		// },
		{
			title: 'FAQ',
			onClick() {
				navigate('/faqs');
			},
		},
		{
			title: 'Documentation',
			onClick() {
				window.open(
					'https://firebasestorage.googleapis.com/v0/b/desci-5b3ec.appspot.com/o/DeSciWorld%20Whitepaper%20(3).pdf?alt=media&token=70573b9d-6236-4b05-83b9-11d1cb97a10a',
					'_blank',
				);
			},
		},
		{
			title: 'Contact Us',
			onClick() {
				navigate('/contact');
			},
		},
		{
			title: 'About Us',
			onClick() {
				navigate('/About');
			},
		},
		// {
		// 	title: 'Belgrade',
		// 	onClick() {
		// 		navigate('/belgrade');
		// 	},
		// },
	];
	return (
		<>
			<NavBar routes={routes} />
			<ScrollableToSection
				ref={mainSectionRef}
				customHeight="calc(100vh - 40px)"
				mobileHeight="calc(100vh - 40px)"
			>
				<MainSection scrollToRef={secondSectionRef} />
			</ScrollableToSection>
			<ScrollableToSection
				ref={secondSectionRef}
				customHeight="50vh"
				mobileHeight="calc(100vh - 40px)"
			>
				<SecondSection />
			</ScrollableToSection>
			<ScrollableToSection
				customHeight="50vh"
				ref={thirdSectionRef}
				mobileHeight="calc(100vh - 40px)"
			>
				<ThirdSection />
			</ScrollableToSection>
			<ScrollableToSection
				fullHeight
				ref={fourthSectionRef}
				mobileHeight="calc(120vh - 40px)"
			>
				<FourthSection />
			</ScrollableToSection>
			<ScrollableToSection
				fullHeight
				ref={fifthSectionRef}
				mobileHeight="calc(200vh - 40px)"
			>
				<FifthSection />
			</ScrollableToSection>
			<ScrollableToSection customHeight="70vh" ref={sixthSectionRef}>
				<SixthSection />
			</ScrollableToSection>
			<ScrollableToSection
				customHeight="30vh"
				ref={seventhSectionRef}
				mobileHeight="calc(80vh - 40px)"
			>
				<SeventhSection />
			</ScrollableToSection>
			<ScrollableToSection customHeight="20vh" mobileHeight="calc(20vh - 40px)">
				<Footer />
			</ScrollableToSection>
		</>
	);
}
