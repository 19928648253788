import { StyledMenu } from "./Menu.styles";

export function Menu ({
    open,
    children
  }: {
    open?:boolean,  children?: any ;
  }) {
    return (
      <StyledMenu open={open}>
        {children}
      </StyledMenu>
    )
  }