import { useMemo } from 'react';
import {
	Proposal,
	ProposalStatus,
	ProposalGroupType as ProposalGroup,
} from '../../types';
import { Icon } from '../Icon';
import {
	GroupTypeContainer,
	ProposalAmount,
	ProposalCardContainer,
	ProposalContentContainer,
	ProposalGroupType,
	ProposalLeftContent,
	ProposalName,
	ProposalRightContent,
	ProposalTokenIconImg,
	ProposalUser,
} from './ProposalCard.styles';

export function ProposalCard({
	_id,
	name,
	tokenIconUrl,
	token,
	address,
	groupType,
	status,
	amount,
	onClick,
}: Partial<Proposal & { onClick?: (id: string) => void }>) {
	const iconNameByStatus = useMemo(() => {
		switch (status) {
			case ProposalStatus.active:
				return 'roundYellow';
			case ProposalStatus.complete:
				return 'roundGreen';
			case ProposalStatus.proposed:
				return 'roundRed';
		}
	}, [status]);

	const colorByGroupType = useMemo(() => {
		switch (groupType) {
			case ProposalGroup.individual:
				return 'primary';
			case ProposalGroup.consultation:
				return 'tertiary';
			case ProposalGroup.sponsor:
				return 'secondary';
		}
	}, [groupType]);

	return (
		<ProposalCardContainer>
			<GroupTypeContainer>
				<ProposalGroupType noTransform top color={colorByGroupType}>
					{groupType}
				</ProposalGroupType>
			</GroupTypeContainer>
			<ProposalContentContainer>
				<ProposalLeftContent>
					<ProposalName
						onClick={() => {
							if (onClick) {
								onClick(_id);
							}
						}}
					>
						{name}
					</ProposalName>
					<ProposalUser>{address}</ProposalUser>
					<Icon width="25px" name={iconNameByStatus} />
				</ProposalLeftContent>
				<ProposalRightContent>
					<ProposalAmount>{amount}</ProposalAmount>
					<ProposalTokenIconImg src={tokenIconUrl} title={token} />
				</ProposalRightContent>
			</ProposalContentContainer>
		</ProposalCardContainer>
	);
}
