import styled from 'styled-components';
import { HighlightedText } from '../HighlightedText';

export const ProposalCardContainer = styled.div`
	min-width: 250px;
	min-height: 200px;
	width: 100%;
	background: ${({
		theme: {
			colors: {
				background: { white },
			},
		},
	}) => white};
	box-shadow: 6px 4px 20px
		${({
			theme: {
				colors: { accent },
			},
		}) => accent};
	display: flex;
	flex-direction: column;
`;

export const GroupTypeContainer = styled.div`
	margin-top: 0px;
	margin-left: 12px;
`;

export const ProposalGroupType = styled(HighlightedText)`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: italic;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	padding: 0px 12px;
	text-transform: none !important;
`;
export const ProposalRibbon = styled.div``;
export const ProposalContentContainer = styled.div`
	display: flex;
	padding: 24px 24px;
	box-sizing: border-box;
	width: 100%;
`;
export const ProposalLeftContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 60%;
`;

export const ProposalName = styled.span`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	&:hover {
		${({ onClick }) =>
			onClick
				? `
		text-decoration:underline;
		cursor:pointer;
		`
				: ''}
	}
`;

export const ProposalUser = styled.span`
	font-style: italic;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin-bottom: 6px;
`;

export const ProposalRightContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: end;
	justify-content: end;
	width: 40%;
`;
export const ProposalAmount = styled.span`
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 42px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;
export const ProposalTokenIconImg = styled.img`
	width: 42px;
	height: 42px;
`;
