import styled from 'styled-components';

export const HighlightedTextContainer = styled.div`
	position: relative;
	display: inline-block;
`;

export const TextContainer = styled.div<{ noTransform?: boolean }>`
	text-transform: ${({ noTransform }) => !noTransform && 'uppercase'};
	color: black;
	position: relative;
	display: inline-block;
	z-index: 2;
`;

export const HighlightedTextBackground = styled.div<{
	top?: boolean;
	color?: string;
}>`
	background: ${({ theme: { colors }, color }) =>
		color ? colors[color] || color : colors.tertiary};
	display: inline-block;
	z-index: 1;
	position: absolute;
	${({ top }) => (top ? 'top:0px;' : 'bottom: 0px;')}
	left: 0px;
	width: 100%;
	height: 50%;
`;
