import { CaptionedImage } from '../../../../../../components/CaptionedImage';
import { Container, TeamContainer } from '../MainSection/MainSection.styles';
import {
	Description,
	DescriptionContainer,
	DescriptionImage,
	DescriptionTextContainer,
	DescriptionTitle,
	Title,
} from './MainSection.styles';

import josh from '../../../../../../assets/josh.png';
import jelani from '../../../../../../assets/jelani.png';
import caro from '../../../../../../assets/caro.png';
import talyssa from '../../../../../../assets/talyssa.png';

export function MainSection() {
	return (
		<Container>
			<Title>About Us</Title>
			<DescriptionContainer>
				<DescriptionTextContainer>
					<DescriptionTitle>
						Decentralised, united and passionate.
					</DescriptionTitle>
					<Description>
						DeSciWorld is being built by contributors: researchers; designers;
						businesspeople; creators. Situated around the world, we bring
						diverse views and people together to unite with the goal of
						Decentralising Science.
					</Description>
				</DescriptionTextContainer>
				<DescriptionImage />
			</DescriptionContainer>
			<Title>Core Team</Title>
			<TeamContainer>
				<CaptionedImage
					link="https://t.me/jbate7/"
					url={josh}
					caption={'Joshua Bate'}
				/>
				<CaptionedImage
					link="https://www.linkedin.com/in/jelani-clarke-ph-d-920b1080/"
					url={jelani}
					caption={'Dr. Jelani Clarke PhD'}
				/>
				<CaptionedImage
					link="https://twitter.com/gingerheart86"
					url={caro}
					caption={'Carolina Menchaca'}
				/>
				<CaptionedImage
					link="https://twitter.com/T4LYSSA"
					url={talyssa}
					caption={'Talyssa'}
				/>
			</TeamContainer>
		</Container>
	);
}
