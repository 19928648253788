import { Carousel } from '../../../../components/Carousel';
import { MintContract } from '../../../../types';
import { NftDisplayCard } from '../NftDisplayCard';
import {
	CarouselContainer,
	FeaturedCollectionContainer,
	FeaturedCollectionTitle,
	NftDisplayCardContainer,
} from './FeaturedCollection.styles';

export function FeaturedContracts({
	title,
	contracts,
	fallbackText,
	loading,
}: {
	title: string;
	contracts: MintContract[];
	fallbackText?: string;
	loading?: boolean;
}) {
	return (
		<FeaturedCollectionContainer>
			<FeaturedCollectionTitle>{title}</FeaturedCollectionTitle>
			<CarouselContainer>
				{!!contracts?.length && (
					<Carousel>
						{!!contracts?.length &&
							contracts.map(
								(
									{
										name,
										_id,
										mediaUrl,
										description,
										value,
										totalSupply,
										openseaCollectionSlug,
									},
									index,
								) => (
									<NftDisplayCardContainer key={index + 'nft'}>
										<NftDisplayCard
											name={name}
											link={`/nft/${openseaCollectionSlug || _id}`}
											mediaUrl={mediaUrl}
											buttonText="Show"
											description={description}
											totalSupply={totalSupply}
										/>
									</NftDisplayCardContainer>
								),
							)}
					</Carousel>
				)}
				{!contracts?.length && !loading && (
					<FeaturedCollectionTitle>{fallbackText}</FeaturedCollectionTitle>
				)}
			</CarouselContainer>
		</FeaturedCollectionContainer>
	);
}
