import styled from 'styled-components';
import { HighlightedText } from '../../../../../../components/HighlightedText';

export const RequestContainer = styled.div`
	width: 70%;
	margin: 3% auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const FundingContainer = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	margin: 0 40px;
`;

export const ResearchContainer = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	margin: 0 40px;
`;
export const Title = styled(HighlightedText)`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	padding: 10px 40px 10px 20px;
	line-height: 48px;
	letter-spacing: -0.02em;
	text-transform: uppercase !important;
`;

export const CommentText = styled.p`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: italic;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
`;

export const FundingList = styled.ul`
	background-color: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	padding: 20px;
	width: 100%;
	list-style: none;
`;

export const ResearchList = styled.ul`
	background-color: ${({
		theme: {
			colors: { tertiary },
		},
	}) => tertiary};
	padding: 20px;
	width: 100%;
	list-style: none;
`;

export const ListItem = styled.li`
	margin: 20px 0;
`;

export const SeeMoreText = styled.p`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
`;
