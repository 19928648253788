import styled from 'styled-components';

import SectionImage from '../../../../../assets/second-section-image.png';
export const FloatingCircle = styled.img`
	position: absolute;
	top: 0rem;
	left: 0.2rem;
`;

export const IntroSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({
		theme: {
			colors: { secondary },
		},
	}) => secondary};
	display: flex;
	padding: 2rem 6rem;
	align-items: center;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		align-items: flex-start;
		padding: 2rem 1rem;
	}
`;

export const TopContentContainer = styled.div`
	display: flex;
	width: 90%;
	height: 50%;
	box-sizing: border-box;
	padding-left: 4rem;
	align-items: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		align-items: flex-start;
		padding: 0rem;
	}
`;

export const BottomContentContainer = styled.div`
	width: 100%;
	height: 50%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		align-items: flex-start;
		padding: 1rem;
		box-sizing: border-box;
	}
`;

export const StyledSectionImage = styled.div`
	width: 35%;
	min-width: 12rem;
	height: 100%;
	background: transparent url(${SectionImage});
	background-size: contain;
	background-repeat: no-repeat;

	z-index: 10;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 10px 0;
		width: 100%;
		height: 20%;
		background: transparent url(${SectionImage}) no-repeat 10% 70%;
		background-size: 140px;
	}
`;

export const StyledTopCornerImage = styled.img`
	position: absolute;
	top: -5rem;
	right: -6rem;
	width: 420px;
	z-index: 0;
`;

export const DownArrow = styled.img`
	width: 30px;
	height: 42px;
	position: absolute;
	top: 0;
	left: calc(50% - 15px);
`;
