import styled from 'styled-components';

export const BackButtonContainer = styled.div`
	margin: 1rem 0rem;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	cursor: pointer;
	width: fit-content;
`;

export const BackButtonText = styled.span`
	font-size: 1.2rem;
	line-height: 1.2rem;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.accent};
`;

export const QuestionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	color: ${({ theme }) => theme.colors.accent};
	padding: 1rem 1rem;
`;
export const QuestionTitle = styled.span``;
export const FlagButtonContainer = styled.div``;
export const AnswerInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
`;
export const AnswerListContainer = styled.div`
	padding: 1rem;
	gap: 1rem;
	display: flex;
	flex-direction: column;
	color: ${({ theme }) => theme.colors.accent};
`;
export const AnswersTitleText = styled.span`
	font-size: 1.2rem;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.accent};
	margin-bottom: 1rem;
	display: inline-block;
`;
export const AnswerItemContainer = styled.div`
	display: flex;
	background-color: ${({ theme }) => theme.colors.background.default};
	border: 1px solid ${({ theme }) => theme.colors.primary};
	padding: 1rem;
`;
export const AnswerText = styled.span`
	color: ${({ theme }) => theme.colors.accent};
	font-size: 1rem;
`;
