import styled from 'styled-components';

export const SlidesSectionContainer = styled.div`
	width: 100%;
	margin: 5% auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
`;

export const SlidesContainer = styled.div`
	background: #a8c0c3;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 350px;
	width: 100%;
	margin-top: 30px;
`;

export const Slides = styled.img`
	width: 288px;
	height: 288px;
	border: 2px solid #ffffff;
	box-sizing: border-box;
	filter: drop-shadow(8px 8px 20px rgba(0, 48, 63, 0.2));
	margin: 0 20px;
`;

export const SlidesCarouselContainer = styled.div`
	width: 100%;
	background: ${({
		theme: {
			colors: { background },
		},
	}) => background.gray};
	align-self: center;
	display: flex;
	justify-content: flex-end;
	margin-left: 24px;
	box-sizing: border-box;
	flex-direction: column;
`;
