import { DesciTheme } from '../../../../theme';
import { AboutContainer, MobileButton, SectionContainer } from './About.styles';
import { ContributorSection } from './components/ContributorSection';
import { MobileParragraph } from './components/ContributorSection/ContributorSection.styles';
import { MainSection } from './components/MainSection';
import { useNavigate } from 'react-router-dom';

export function About() {
	const navigate = useNavigate();
	return (
		<AboutContainer>
			<MainSection />

			<SectionContainer background="#A8C0C3">
				<ContributorSection />
			</SectionContainer>
			<MobileParragraph>
				DeSciWorld is a constantly evolving ecosystem with a broad scope for its
				future direction. We encourage researchers, builders, community leaders,
				artists and creators - anyone - to contribute and find a place in the
				community. Join our Discord and get involved!
			</MobileParragraph>

			<MobileButton onClick={() => null}>Connect your wallet</MobileButton>
			<MobileButton
				color={DesciTheme.colors.background.lightGray}
				onClick={() => navigate('/contact')}
			>
				Contact us
			</MobileButton>
		</AboutContainer>
	);
}
