import axios from 'axios';
import { createContext, ReactElement, useContext, useState } from 'react';
import { SnapshotProposal, SnapshotSpace } from '../types';

const SNAPSHOT_API_URL = 'https://hub.snapshot.org/graphql';
// const TEST_SNAPSHOT_API: string = 'https://testnets-api.opensea.io/api/v1/';
export function useSnapshotAPIState() {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);
	const [totalMembers, setTotalMembers] = useState<number>(0);
	const [proposals, setProposals] = useState<SnapshotProposal[]>([]);
	async function getTotalFollowerCountByIds(ids: string[]) {
		try {
			setLoading(true);
			const {
				data: {
					data: { spaces },
				},
			} = await axios.post<{ data: { spaces: SnapshotSpace[] } }>(
				`${SNAPSHOT_API_URL}`,
				{
					query: `query Spaces {
          spaces(
            first: 200,
            skip: 0,
            orderBy: "created",
            orderDirection: asc
            where:{
            id_in:["${ids.join('","')}"]
        }
          ) {
            id
            followersCount
            proposalsCount
          }
        }`,
					variables: null,
					operationName: 'Spaces',
				},
			);
			let totalMembers = 0;
			if (!spaces) {
				setTotalMembers(totalMembers);
				setResponse(spaces);
				setLoading(false);
				return totalMembers;
			}
			for (const space of spaces) {
				totalMembers += space.followersCount;
			}
			setTotalMembers(totalMembers);
			setResponse(spaces);
			setLoading(false);
			return totalMembers;
		} catch (e) {
			console.log({ e });
			setError(e);
		}
	}

	async function getTotalFollowerCountById(id: string) {
		try {
			setLoading(true);
			const {
				data: {
					data: { space },
				},
			} = await axios.post<{ data: { space: SnapshotSpace } }>(
				`${SNAPSHOT_API_URL}`,
				{
					query: `query {
          space( id: "${id}" ) {
            id
            followersCount
            proposalsCount
          }
        }`,
					variables: null,
				},
			);
			let totalMembers = space?.followersCount || 0;
			setTotalMembers(totalMembers);
			setResponse(space);
			setLoading(false);
			return totalMembers;
		} catch (e) {
			console.log({ e });
			setError(e);
		}
	}

	async function getProposalsById(id: string) {
		try {
			setLoading(true);
			setProposals([]);
			const {
				data: {
					data: { proposals: proposalsFromAPI },
				},
			} = await axios.post<{ data: { proposals: SnapshotProposal[] } }>(
				`${SNAPSHOT_API_URL}`,
				{
					query: `query Proposals {
						proposals (
							first: 3,
							skip: 0,
							where: {
								space_in: ["${id}"],
							},
							orderBy: "created",
							orderDirection: desc
						) {
							id
							title
							snapshot
							state
							body
							link
						}
					}`,
					variables: null,
					operationName: 'Proposals',
				},
			);
			if (!proposals) {
				setProposals(proposalsFromAPI);
				setResponse(proposalsFromAPI);
				setLoading(false);
				return proposalsFromAPI;
			}
			setProposals(proposalsFromAPI);
			setResponse(proposalsFromAPI);
			setLoading(false);
			return proposalsFromAPI;
		} catch (e) {
			console.log({ e });
			setError(e);
		}
	}

	function clearProposals() {
		console.log('clearing');
		setProposals(null);
		setTotalMembers(null);
	}

	return {
		loading,
		error,
		response,
		totalMembers,
		proposals,
		getProposalsById,
		getTotalFollowerCountByIds,
		getTotalFollowerCountById,
		clearProposals,
	};
}

export const SnapshotContext: React.Context<{
	error: any;
	loading: boolean;
	response: any;
	totalMembers: number;
	proposals: SnapshotProposal[];
	getProposalsById: (id: string) => Promise<SnapshotProposal[]>;
	getTotalFollowerCountByIds: (ids: string[]) => Promise<number>;
	getTotalFollowerCountById: (id: string) => Promise<number>;
	clearProposals: () => void;
}> = createContext({} as any);

export function SnapshotProvider({ children }: { children?: ReactElement }) {
	const snapshotAPI = useSnapshotAPIState();

	return (
		<SnapshotContext.Provider value={snapshotAPI}>
			{children}
		</SnapshotContext.Provider>
	);
}

export function useSnapshotAPI() {
	return useContext(SnapshotContext);
}
