import styled from 'styled-components';
import { Icon } from '../../../../../../components/Icon';

export const LotteryDetailContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 2%;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const RowContainer = styled.div`
	display: flex;
	justify-content: start;
	gap: 2rem;
	align-items: center;
	padding: 1rem;
	width: 100%;
`;

export const ContentTextSection = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 2;
	height: 100%;
`;

export const BigText = styled.span<{ bold?: boolean }>`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-weight: ${({ bold }) => (bold ? 'bold' : '400')};
	font-size: 24px;
	line-height: 24px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
		line-height: 30px;
	}
`;

export const SmallText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
`;

export const StatusSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: row-reverse;
		&:first-child {
			display: none;
		}
	}
`;

export const DateSection = styled.div`
	display: flex;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: row-reverse;
	}
`;

export const IconWithMargin = styled(Icon)`
	margin: 0px 12px;
`;
