import { Footer } from './components/Footer';
import { NavBar } from './components/NavBar';
import { SideBar } from './components/SideBar';
import { PageContainer } from './containers/Dashboard/Dashboard.styles';

export const routes = [
	{ link: '/', title: 'Home', active: false },
	{ link: '/dashboard', title: 'My Dashboard', active: false },
	{ link: '/nft', title: 'NFT', active: false },
];

export const AppRoute = ({ children }: { children?: any }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<SideBar />
			<PageContainer>
				<NavBar/>
				{children}
			</PageContainer>
			<Footer />
		</div>
	);
};
