import styled from 'styled-components';

export const CaptionedImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	width: 200px;
	padding: 2rem;
	align-self: start;
	cursor: pointer;
`;

export const CaptionedImageImage = styled.div<{ url: string }>`
	width: 200px;
	height: 200px;
	background: url(${({ url }) => url});
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 150px;
		height: 150px;
		align-self: center;
	}
`;

export const CaptionedImageText = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 2rem;
	line-height: 2.2rem;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	text-align: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.2rem;
		text-align: left;
		letter-spacing: -0.03em;
	}
`;
