import { useEffect } from 'react';
import { useOpenSeaAPI } from '../../../../../../hooks/useOpenseaAPI';
import { FeaturedAssets } from '../../../../components/Featured';
import {
	PrizeSectionContainer,
	PrizeSectionTitle,
} from './PrizeSection.styles';
import {
	Lottery,
	NFTForDisplay,
	Prize,
	PrizeType,
} from '../../../../../../types';

export function PrizeSection({ lottery }: { lottery: Lottery }) {
	const { getAssetsByPrize, featuredAssets } = useOpenSeaAPI();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			// ,behavior:'smooth'
		});
	}, []);

	useEffect(() => {
		if (lottery?.prizes?.length > 0) {
			getAssetsByPrize(lottery?.prizes);
		}
	}, [lottery]);

	function mergeAllPrizes(prizes: Prize[]) {
		const nonTokenPrizes: NFTForDisplay[] = [];
		prizes.forEach(
			({ type, description, name, link, mediaUrl, amount, symbol }) => {
				if (type === PrizeType.external) {
					nonTokenPrizes.push({
						name,
						link,
						buttonText: 'Check it out',
						description,
						mediaUrl,
					});
				}

				if (type === PrizeType.coin) {
					nonTokenPrizes.push({
						name: `${amount} $${symbol}`,
						link,
						buttonText: 'Check token info',
						description,
						mediaUrl,
					});
				}
			},
		);
		return [...featuredAssets, ...nonTokenPrizes];
	}

	return (
		<PrizeSectionContainer>
			<PrizeSectionTitle>🏆 Prize: </PrizeSectionTitle>
			<FeaturedAssets title="" assets={mergeAllPrizes(lottery?.prizes || [])} />
		</PrizeSectionContainer>
	);
}
