import styled from 'styled-components';
import bg from '../../../../assets/BKG_White-nft-mint.png';

export const DashboardContainer = styled.div`
	width: 70%;
	margin: 0px auto;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		margin: auto;
	}
`;

export const PageContainer = styled.div`
	background: url(${bg}) no-repeat;
	background-size: cover;
`;
