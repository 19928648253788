export const HOME_PAGE_COPIES = {
	firstPageopy: 'hey desciwor aslfjadslfje wfw',
};

export enum MediaType {
	image = 'image',
	video = 'video',
}

export const CHAIN_NAME_BY_ID: { [key: string]: string } = {
	1: 'Ethereum Mainnet',
	420: 'Optimism Goerli',
	10: 'Optimism Mainnet',
	137: 'Polygon Mainnet',
};
