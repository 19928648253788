import styled from "styled-components";

export const MapContainer = styled.div`
	width: 90%;
	margin: 0px 0px 0px 6rem; ;
    height:calc(80vh);
    display:block;
    justify-content:center;
    padding-top:2rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		margin: auto;
	}
`;

export const StyledIframe = styled.iframe`
	height: calc(100% - 1rem);
	@media ${({
			theme: {
				device: { small },
			},
		}) => small} {
		min-height: 65%;
	}
`;