import { Button } from '../../../../../../components/Button';
import {
    ButtonsContainer,
    FaqsText,
	MainSectionContainer,
	MainSectionDescription,
	MainSectionTitle,
	TextContainer,
} from './MainSection.styles';

export function MainSection() {
	return (
		<MainSectionContainer>
			<TextContainer>
				<MainSectionTitle>P2P funding</MainSectionTitle>
				<MainSectionDescription>
					Decentralised, Peer-to-Peer funding can unlock research opportunities
					previously closed to the majority. Below, researchers can apply for
					funding or take on the requests of others. Those that wish to can
					propose a research goal in return for on-chain funding.
				</MainSectionDescription>
			</TextContainer>
            <ButtonsContainer>
            <Button
					onClick={() => {
						// console.log('go to link');
					}}
				>
					Feat. Research project
				</Button>
                <Button
					onClick={() => {
						// console.log('go to link');
					}}
				>
					Meet our DeScientists
				</Button>
                <FaqsText>FAQs</FaqsText>
                <Button
					onClick={() => {
						// console.log('go to link');
					}}
				>
					Create a proposal ↗️
				</Button>
            </ButtonsContainer>
		</MainSectionContainer>
	);
}
