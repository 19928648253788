import {
	SpeakerImage,
	SpeakerItem,
	SpeakerName,
	SpeakerSectionContainer,
	SpeakersListContainer,
	Title,
} from './SpeakersSection.styles';

const speakers = [
	{
		name: 'Joshua Bate',
		organization: 'DeSciWorld',
		image: 'https://i.imgur.com/UtatSc1.jpg',
		link: 'https://twitter.com/jb87ua',
	},
	{
		name: 'Speaker',
		organization: 'Organization',
		image:
			'https://clipart-library.com/new_gallery/72-722963_circular-question-mark-button-question-mark-icon-white.png',
		link: 'https://desci.world',
	},
	{
		name: 'Speaker',
		organization: 'Organization',
		image:
			'https://clipart-library.com/new_gallery/72-722963_circular-question-mark-button-question-mark-icon-white.png',
		link: 'https://desci.world',
	},
];
export function SpeakerSection() {
	return (
		<SpeakerSectionContainer>
			<Title>Confirmed Speakers</Title>
			<SpeakersListContainer>
				{speakers.map((speaker) => (
					<SpeakerItem>
						<SpeakerImage
							onClick={() => {
								window.open(speaker.link, '_blank');
							}}
							url={speaker.image}
						/>
						<SpeakerName color="softBlack">{speaker.name}</SpeakerName>
						<SpeakerName>{speaker.organization}</SpeakerName>
					</SpeakerItem>
				))}
			</SpeakersListContainer>
		</SpeakerSectionContainer>
	);
}
