import { Button } from '../../../../../components/Button';
import { useViewport } from '../../../../../utils/viewPortHook';
import {
	FifthSectionContainer,
	FifthSectionPageTitle,
	FifthSectionCardsContainer,
	FifthSectionTextContainer,
	FifthSectionTitle,
	FifthSectionSubtitle,
	FifthSectionText,
} from '../../../components/FifthSection/FifthSection.styles';
import { SlimSeparatorHorizontal } from '../../../components/SlimSeparatorHorizontal';
import { SlimSeparator } from '../../../components/slimSeparator';
import firstBackground from '../../../../../assets/fifth-section-image1.png';
import secondBackground from '../../../../../assets/fifth-section-image2.png';
import thirdBackground from '../../../../../assets/fifth-section-image3.png';
export function DeSciSection() {
	const { width } = useViewport();
	const breakpoint = 475;

	return (
		<FifthSectionContainer>
			<FifthSectionPageTitle>Be a part of something big</FifthSectionPageTitle>
			<FifthSectionCardsContainer>
				<FifthSectionTextContainer
					background={firstBackground}
					position="top-left"
				>
					<FifthSectionTitle>Learn</FifthSectionTitle>
					<FifthSectionSubtitle>& contribute</FifthSectionSubtitle>
					<FifthSectionText>
						DeSciWorld’s inclusive community welcomes all, regardless of
						education or background. Come to our Discord to meet new people,
						discover new ideas and find your place in the changing paradigm. No
						qualifications are required to be a valued contributor in this
						space.
					</FifthSectionText>
				</FifthSectionTextContainer>
				{width < breakpoint ? <SlimSeparatorHorizontal /> : <SlimSeparator />}

				<FifthSectionTextContainer
					background={secondBackground}
					position="top-right"
				>
					<FifthSectionTitle>Build</FifthSectionTitle>
					<FifthSectionSubtitle>& regenerate</FifthSectionSubtitle>
					<FifthSectionText>
						Web3 and cryptocurrencies provide brand new toolsets and incentive
						mechanisms to experiment with. Now, the scope of innovation is bound
						only by one’s imagination. Experimentation in this field is
						encouraged and our network is at hand to help direct innovation.
					</FifthSectionText>
				</FifthSectionTextContainer>
				{width < breakpoint ? <SlimSeparatorHorizontal /> : <SlimSeparator />}
				<FifthSectionTextContainer
					background={thirdBackground}
					position="top-right"
				>
					<FifthSectionTitle>Spread</FifthSectionTitle>
					<FifthSectionSubtitle>like wildfire</FifthSectionSubtitle>
					<FifthSectionText>
						DeSciWorld enables and supports those that want to decentralise
						Science. Using our platform, a DeScientist can improve outreach for
						their work, grow networks, access resources from a huge and vibrant
						community and even apply for funding.
					</FifthSectionText>
				</FifthSectionTextContainer>
			</FifthSectionCardsContainer>
			<Button
				onClick={() => {
					window.open(
						'https://notionforms.io/forms/ticket-applications',
						'_blank',
					);
				}}
				color="primary"
			>
				Apply for tickets
			</Button>
		</FifthSectionContainer>
	);
}
