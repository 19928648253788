import styled from 'styled-components';

export const ItemContainer = styled.div<{ clickable: boolean }>`
	padding: 0.5rem 1rem;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	background: transparent;
	width: 100%;
	border: 2px solid ${({ theme: { colors } }) => colors.primary};
	background: ${({
		theme: {
			colors: { background },
		},
	}) => background.default};
	cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
	border-radius: 1rem;
	gap: 2rem;
`;

export const ItemLogo = styled.img`
	border-radius: 100rem;
	width: 5rem;
	height: 5rem;
`;

export const TextContainer = styled.div`
	display: flex;
	width: 70%;
	height: 100%;
	padding: 0px;
	box-sizing: border-box;
	flex-direction: column;
	gap: 1rem;
`;

export const Title = styled.span`
	color: ${({ theme: { colors } }) => colors.accent};
	font-size: 1.2rem;
	font-weight: bolder;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;

export const Description = styled.span`
	color: ${({ theme: { colors } }) => colors.background.lightGray};
	font-size: 1rem;
`;

export const Badge = styled.div`
	color: ${({ theme: { colors } }) => colors.background.default};
	padding: 4px 8px;
	font-size: 0.8rem;
	background: ${({ theme: { colors } }) => colors.background.lightGray};
	border-radius: 0.5rem;
`;
