import {
	LeftContainer,
	NoteCardContainer,
	NoteCardContentContainer,
	NoteCardDecoration,
	NoteCardImage,
	NoteCardLink,
	NoteCardSubtitle,
	NoteCardText,
	NoteCardTitle,
	NoteCardTitleContainer,
	RightArrow,
	RightContainer,
	ShadowContainer,
} from './NoteCard.styles';

export function NoteCard({
	image,
	title,
	subtitle,
	text,
	link,
}: {
	subtitle?: string;
	link?: string;
	image?: string;
	title?: string;
	text: any;
}) {
	return (
		<NoteCardContainer>
			<NoteCardDecoration />
			<ShadowContainer>
				<NoteCardContentContainer>
					<LeftContainer>
						{image && <NoteCardImage src={image} />}
						{title && (
							<NoteCardTitleContainer>
								<LeftContainer>
									<NoteCardTitle>
										{title?.substring(0, 2).toUpperCase()}
									</NoteCardTitle>
									<NoteCardSubtitle>{subtitle}</NoteCardSubtitle>
								</LeftContainer>
								<RightArrow />
								<RightArrow />
								<RightArrow />
							</NoteCardTitleContainer>
						)}
					</LeftContainer>
					<RightContainer>
						<NoteCardText>
							{text}
							{link ? (
								<NoteCardLink href={link} target="_blank" rel="noreferrer">
									read more ↗
								</NoteCardLink>
							) : (
								''
							)}
						</NoteCardText>
					</RightContainer>
				</NoteCardContentContainer>
			</ShadowContainer>
		</NoteCardContainer>
	);
}
