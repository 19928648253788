import { useViewport } from '../../../../utils/viewPortHook';
import { DecoratedParagraph } from '../DecoratedParagraph';
import { LandingHighlightedText } from '../LandingHighlightedText';
import {
	SecondSectionContainer,
	SecondSectionText,
	SecondSectionTextContainer,
	StyledSectionImage,
} from './SecondSection.styles';

export function SecondSection() {
	const { width } = useViewport();
	const breakpoint = 475;
	return (
		<SecondSectionContainer>
			<SecondSectionTextContainer>
				<SecondSectionText>
					<LandingHighlightedText>Support</LandingHighlightedText> scientists
					worldwide and get inspired like never before.{' '}
				</SecondSectionText>
				<DecoratedParagraph lineCount={width < breakpoint ? 2 : null}>
					Aspiring researchers, scientists, technicians and visionaries often
					must put their passions on ice in order to jump through hoops held up
					by instituions and traditions. Here though, all ideas are valid and
					anyone, anywhere can see their dreams realised. DeScientists are in
					the business of defeating gatekeepers and inspiring innovation.
				</DecoratedParagraph>
			</SecondSectionTextContainer>
			<StyledSectionImage />
		</SecondSectionContainer>
	);
}
