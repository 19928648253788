export function arrayReducer(
	state: any,
	{
		index,
		value,
		remove,
		reset,
	}: { index?: number; value: string; remove?: boolean; reset?: boolean },
) {
	if (reset) {
		return [];
	}
	if (remove) {
		return state.filter((item: any, index: number) => index !== index);
	}
	if (index) {
		const stateCopy = [...state];
		stateCopy[index] = value;
		return stateCopy;
	}
	return [...state, value];
}
