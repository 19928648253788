import { Carousel } from '../../../../components/Carousel';
import { NFTForDisplay } from '../../../../types';
import { useViewport } from '../../../../utils/viewPortHook';
import { NftDisplayCard } from '../NftDisplayCard';
import {
	CarouselContainer,
	FeaturedCollectionContainer,
	FeaturedCollectionTitle,
	MobileSwipeText,
	NftDisplayCardContainer,
} from './FeaturedCollection.styles';

export function FeaturedAssets({
	title,
	assets,
}: {
	title: string;
	assets: NFTForDisplay[];
}) {
	const { width } = useViewport();
	const mobile = width < 475;
	return (
		<FeaturedCollectionContainer>
			<FeaturedCollectionTitle>{title}</FeaturedCollectionTitle>
			<CarouselContainer>
				{assets?.length && (
					<Carousel>
						{assets?.length &&
							assets.map(({ name, link, mediaUrl, description }, index) => (
								<NftDisplayCardContainer key={index + 'nft'}>
									<NftDisplayCard
										name={name}
										link={link}
										mediaUrl={mediaUrl}
										buttonText="Show"
										description={description}
									/>
								</NftDisplayCardContainer>
							))}
					</Carousel>
				)}
				{mobile && <MobileSwipeText>Swipe right to see more</MobileSwipeText>}
			</CarouselContainer>
		</FeaturedCollectionContainer>
	);
}
