import {
	HighlightedTextBackground,
	HighlightedTextContainer,
	TextContainer,
} from './HighlightedText.styles';

export function HighlightedText({
	children,
	color,
	top,
	noTransform,
	className,
}: {
	children: any;
	top?: boolean;
	color?: string;
	noTransform?: boolean;
	className?: string;
}) {
	return (
		<HighlightedTextContainer className={className}>
			<TextContainer noTransform={noTransform}>{children}</TextContainer>
			<HighlightedTextBackground
				top={top}
				color={color}
			></HighlightedTextBackground>
		</HighlightedTextContainer>
	);
}
