import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from '../../../../../../components/Dropdown/Dropdown';
import { Input } from '../../../../../../components/Form';
import { ListItem } from '../../../../../../components/ListItem';
import { SortButtonBar } from '../../../../../../components/SortButtonBar';
import { LoaderContainer } from '../../../../../../components/Table/Table.styles';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import {
	FilterOptions,
	Organization,
	OrganizationCategory,
} from '../../../../../../types';
import { sortObjectArray } from '../../../../../../utils/sortObjectArray';
import { useViewport } from '../../../../../../utils/viewPortHook';
import {
	DropdownContainer,
	InputContainer,
	ListContainer,
	ListSectionContainer,
	ListTopSectionContainer,
} from './ListSection.styles';

export function ListSection({
	organizations,
	filterOrganizations,
	loading,
}: {
	organizations: Organization[];
	loading?: boolean;
	filterOrganizations?: (filter: Partial<FilterOptions>) => void;
}) {
	const navigate = useNavigate();
	const { width } = useViewport();
	const [sortKey, setSortKey] = useState<string>();
	const [nameSearch, setNameSearch] = useState(null);
	const debouncedSearch = useDebounce(nameSearch, 500);
	const [tag, setTag] = useState(null);
	const breakpoint = 475;
	const mobile = width <= breakpoint;
	useEffect(() => {
		console.log('filtering');
		const filter = {
			filter: tag ? [{ field: 'tags', value: tag }] : [],
			...(debouncedSearch
				? { search: { fields: ['name'], value: debouncedSearch } }
				: {}),
		};
		filterOrganizations && filterOrganizations(filter);
	}, [debouncedSearch, tag]);
	return (
		<ListSectionContainer>
			<ListTopSectionContainer>
				<InputContainer>
					<SortButtonBar
						activeKey={sortKey}
						onChange={(key) => {
							setSortKey(key);
						}}
						buttons={[
							{
								text: 'Name',
								buttonKey: 'name',
							},
							{
								text: 'Tags',
								buttonKey: 'tags',
							},
						]}
					/>
					<Input
						value={nameSearch}
						onChange={(value) => {
							setNameSearch(value);
						}}
						placeholder="Search project by name"
					/>
				</InputContainer>
				<DropdownContainer>
					<Dropdown
						onChange={({ value }) => {
							setTag(value);
						}}
						options={[
							{ value: null, label: 'Any' },
							...Object.entries(OrganizationCategory).map(([key, value]) => {
								return { value, label: value };
							}),
						]}
						placeholder="Filter by tag"
					/>
				</DropdownContainer>
			</ListTopSectionContainer>
			<ListContainer>
				{loading && <LoaderContainer>loading...</LoaderContainer>}
				{(sortKey
					? organizations?.sort(sortObjectArray(sortKey))
					: organizations
				)?.map(({ _id, name, tags, logo, squareLogo, displayName }) => (
					<ListItem
						onClick={() => {
							(window as any).scrollTo({ top: 0 });
							navigate(`/project/${_id}`);
						}}
						title={displayName || name}
						description={tags.join(', ')}
						mediaUrl={squareLogo || logo}
						key={_id}
						// badgeText={`members`}
					/>
				))}
			</ListContainer>
		</ListSectionContainer>
	);
}
