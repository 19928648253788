import styled from 'styled-components';
import scrollIcon from '../../../../assets/scrollsymbolblack.png';
import BG from '../../../../assets/third-sectionBG.jpg';
import SectionImage from '../../../../assets/third-section-image.png';

export const ThirdSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({
			theme: {
				colors: {
					background: { gray },
				},
			},
		}) => gray}
		url(${BG}) no-repeat center center/cover;
	display: flex;
	/* justify-content:center;*/
	align-items: center;
	flex-direction: row;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const TopScrollIcon = styled.div`
	background: transparent url(${scrollIcon}) no-repeat center center/cover;
	width: 60px;
	height: 100px;
	padding: 2px;

	&:hover {
		cursor: pointer;
	}
`;

export const ThirdSectionTextContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 80%;
	flex-direction: column;
	justify-content: center;

	padding-left: 80px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		height: 80%;
		padding: 0px 12px;
		justify-content: flex-start;
	}
`;

export const ThirdSectionText = styled.span`
	color: black;
	font-size: 3.5vh;
	text-align: left;
	box-sizing: border-box;
	padding-bottom: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 2px;
		font-size: 20px;
	}
`;

export const StyledSectionImage = styled.div`
	width: 50%;
	height: 80%;
	background: transparent url(${SectionImage}) center/350px 400px no-repeat;

	@media (min-width: 1740px) {
		background: transparent url(${SectionImage}) center/350px 400px no-repeat;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		height: 20%;
		background: transparent url(${SectionImage}) no-repeat 10% 70%;
		background-size: 120px;
	}
`;
