import { LockIcon } from '../../../../components/Footer/Footer.styles';
import {
	DescriptionContainer,
	RecentDevelopmentContainer,
	RecentDevelopmentTitle,
} from './RecentDevelopmentSection.styles';

export function RecentDevelopment({ children, unclaimed }: any) {
	return (
		<RecentDevelopmentContainer unclaimed={unclaimed}>
			<RecentDevelopmentTitle>Recent development</RecentDevelopmentTitle>
			<LockIcon unclaimed={unclaimed} />
			<DescriptionContainer>{children}</DescriptionContainer>
		</RecentDevelopmentContainer>
	);
}
