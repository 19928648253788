import { useEffect } from 'react';
import { ImageDisplayCard } from '../../../../components/ImageDisplayCard';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { useViewport } from '../../../../utils/viewPortHook';
import { NftSpotlight } from '../../../App/containers/NftMain/NftMain.styles';
import { FourthSectionContainer, NFTContainer } from './FourthSection.styles';

const microbesProps = {
	name: 'Microbes',
	value: '0.088',
	mediaUrl:
		'https://lh3.googleusercontent.com/uR9tdBvX_pdPavsfDunP6qy9Ky37D3LICkLoMifCMfao3siXaSFL_alK7el8VIdh1X-GriRiMIKKiKqzveOBr-m2gCBGkk65VeEVIGw:s2500',
	totalSupply: 999,
	description:
		'DeSciWorld’s vision is to create a vibrant ecosystem that supports scientific communities from around the world by utilising decentralised and uncensorable blockchain technology. ',
	network: 'Ethereum',
	tokenSymbol: 'eth',
	link: '/nft',
	buttonText: 'Explore',
};

const axondaoProps = {
	name: 'AxonDAO Genesis NFT',
	value: '0.5 / 0.1',
	totalSupply: 240,
	mediaUrl:
		'https://lh3.googleusercontent.com/SjXrWPR5NgA9Ld4XkWaxc1OoSFWDBmA8XmU1HqvJ2wHoVztsW3z9VJ7L3zRXYc85fy5QhCcVgazENq87CkiMP8-EOrSm_B4qxMDPSg=w600',
	description:
		'This is renowned artist Clark Hipolito’s first-ever NFT. This is also AxonDAO’s first NFT, it supports the charity Thank You Life and DesciWorld. It is intended to bring awareness to AxonDAO’s mission while collecting funds to support meaningful projects.',
	network: 'Ethereum',
	tokenSymbol: 'eth',
	link: '/nft',
	buttonText: 'Explore',
};
export function FourthSection() {
	const {
		contract: {
			contract: spotlightContract,
			getSpotlightContract,
			loading,
		}
	} = useAPI();
	const { width } = useViewport();
	const breakpoint = 475;
	useEffect(()=>{
		getSpotlightContract();
	},[])
	console.log({spotlightContract})
	return (
		<FourthSectionContainer>
			<NFTContainer>
				{width > breakpoint && <NftSpotlight>NFT Spotlight</NftSpotlight>}
				<ImageDisplayCard network='Ethereum' link='/nft' buttonText='Explore' {...spotlightContract || {}} />
			</NFTContainer>
		</FourthSectionContainer>
	);
}
