import { UNSAFE_NavigationContext, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import {
	BottomContainer,
	DescriptionText,
	DiscoverText,
	HeaderText,
	LineBreak,
	MainSectionContainer,
	MainText,
	ScrollBottomButton,
	TextContainer,
} from './MainSection.styles';

export function MainSection({ scrollToRef }: { scrollToRef: any }) {
	const navigate = useNavigate();
	return (
		<MainSectionContainer>
			<TextContainer>
				<HeaderText>Explore the DeSciWorld</HeaderText>
				<MainText>
					From research
					<LineBreak /> to disruption; challenge <LineBreak />
					the status quo
				</MainText>
				<DescriptionText>
					Uniting and empowering the decentralised community; DeSciWorld is the
					one-stop shop for users to view, engage with, and understand all that
					is happening in the World of Decentralised Science.
				</DescriptionText>
				<Button
					onClick={() => {
						window.open('https://discord.gg/jnEUqVH8xv', '_blank');
					}}
					color="accent"
				>
					Join DeSciWorld
				</Button>
			</TextContainer>
			<BottomContainer>
				<DiscoverText>Discover More</DiscoverText>
				<ScrollBottomButton
					onClick={() => {
						if (scrollToRef.current) {
							scrollToRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start',
							});
						}
					}}
				/>
			</BottomContainer>
		</MainSectionContainer>
	);
}
