import { DetailCard } from '../../../../components/DetailCard';
import { ProposalContainer, SectionContainer } from './Proposal.styles';
import eth from '../../../../assets/icons/eth.svg';
import { ProposalGroupType, ProposalStatus } from '../../../../types';
import { formatDate } from '../../../../utils/formatDate';
import { ResearchSection } from './components/ResearchSection';
import { CommentsSection } from './components/CommentsSection';
import { SlidesSection } from './components/SlidesSection';

export function Proposal() {
	const routes = [
		{ link: '/', title: 'Overview', active: false },
		{ link: '/Nft', title: 'Notes', active: false },
		{ link: '/dashboard', title: 'Results', active: false },
	];
	return (
		<ProposalContainer>
			<SectionContainer>
				<DetailCard
					title="PhD Research of Funghi’s Communication Network"
					subTitle="vitalik.eth"
					token="eth"
					amount={5}
					logoUrl={eth}
					description="A P2P funded research grant for a PhD-level Mycologist to investigate the existence of mycellial intelligence and the communication networks they utilise. 
A P2P funded research grant for a PhD-level Mycologist to investigate the existence of"
					status={ProposalStatus.active}
					icons={[
						{
							iconName: 'calendar',
							text: formatDate(
								'Sat Feb 26 2022 01:40:00 GMT-0500 (Colombia Standard Time)',
							),
						},
						{
							iconName: 'clock',
							text: '2 years',
						},
						{ iconName: 'people', text: ProposalGroupType.sponsor },
					]}
				/>
			</SectionContainer>
			<SectionContainer>
				<SlidesSection routes={routes} />
			</SectionContainer>
			<SectionContainer customHeight="auto">
				<ResearchSection />
			</SectionContainer>
			<SectionContainer customHeight="auto">
				<CommentsSection />
			</SectionContainer>
		</ProposalContainer>
	);
}
