import { useState } from 'react';
import Collapsible from 'react-collapsible';
import {
	AccordionContentContainer,
	AccordionIcon,
	AccordionImage,
	AccordionImageContainer,
	AccordionStylingContainer,
	AccordionTriggerContainer,
	AccordionTriggerText,
	AccordionTriggerTextContainer,
} from './Accordion.styles';

export function Accordion({
	title,
	children,
	subtitle,
	image,
	backgroundColor,
}: {
	title?: string;
	children: any;
	subtitle?: string;
	image?: any;
	backgroundColor?: string;
}) {
	const [collapsed, setCollapsed] = useState<boolean>(true);
	return (
		<AccordionStylingContainer backgroundColor={backgroundColor}>
			<Collapsible
				trigger={
					<AccordionTriggerContainer backgroundColor={backgroundColor}>
						<AccordionImageContainer>
							{image && <AccordionImage src={image} />}
							<AccordionTriggerTextContainer>
								<AccordionTriggerText>{title}</AccordionTriggerText>
							</AccordionTriggerTextContainer>
						</AccordionImageContainer>

						{collapsed ? (
							<AccordionIcon name="accordionArrowDown" width="40px" />
						) : (
							<AccordionIcon name="accordionArrowUp" width="40px" />
						)}
					</AccordionTriggerContainer>
				}
				onOpen={() => {
					setCollapsed(false);
				}}
				onClose={() => {
					setCollapsed(true);
				}}
			>
				<AccordionContentContainer>{children}</AccordionContentContainer>
			</Collapsible>
		</AccordionStylingContainer>
	);
}
