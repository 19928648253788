import styled from "styled-components";

export const StyledSeparator = styled.div`
display: flex;
align-items: center;
flex-direction: row;
`

export const StyledLine = styled.div`
min-width:300px;
background:${({theme:{colors:{primary}}})=>primary}44;
height:2px;

`
export const StyledArrowLeft = styled.div`
    position: relative;
text-align: center;
height: 24px;
width: 24px;
transform: rotate(-90deg);
   &::after {
    content: '';
    position: absolute;
    bottom: 6px;
    right: 0;
    height: 2px;
    width: 50%;
    background: ${({theme:{colors:{primary}}})=>primary}44;
    transform: skew(0deg, -50deg);
    
}
&::before {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 0;
    height: 2px;
    width: 50%;
    background: ${({theme:{colors:{primary}}})=>primary}44;
    transform: skew(0deg, 50deg);
}

`


export const StyledArrowRight = styled.div`
    position: relative;
text-align: center;
height: 24px;
width: 24px;
transform: rotate(-90deg);
   &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 50%;
    background: ${({theme:{colors:{primary}}})=>primary}44;
    transform: skew(0deg, 50deg);
}
&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 50%;
    background: ${({theme:{colors:{primary}}})=>primary}44;
    transform: skew(0deg, -50deg);
}

`