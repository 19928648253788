import { useMemo } from 'react';
import { Lottery, LotteryStatus } from '../../../../../../types';
import {
	BigText,
	ContentTextSection,
	DateSection,
	RowContainer,
	IconWithMargin,
	LotteryDetailContainer,
	SmallText,
	StatusSection,
} from './LotteryDetail.styles';

export function LotteryDetail({ status, drawDate, description }: Lottery) {
	const iconNameByStatus = useMemo(() => {
		switch (status) {
			case LotteryStatus.closed:
				return 'roundYellow';
			case LotteryStatus.open:
				return 'roundGreen';
			case LotteryStatus.canceled:
				return 'roundRed';
		}
	}, [status]);

	const formattedDate = useMemo(() => {
		const d = new Date(drawDate || null);
		console.log({ drawDate, d });
		return `${d.getDate()} ${d.toLocaleString('default', {
			month: 'long',
		})} ${d.getFullYear()}`;
	}, [drawDate]);
	return (
		<LotteryDetailContainer>
			<RowContainer>
				<StatusSection>
					<BigText>Status: </BigText>
					<BigText bold>{status || ' '}</BigText>
					<IconWithMargin width="40px" name={iconNameByStatus} />
				</StatusSection>
				<DateSection>
					<ContentTextSection>
						<BigText>Date:</BigText>
						<SmallText>{formattedDate}</SmallText>
					</ContentTextSection>
					<IconWithMargin width=" 48px " name="calendar" />
				</DateSection>
			</RowContainer>
			<RowContainer>
				<ContentTextSection>
					<SmallText> {description} </SmallText>
				</ContentTextSection>
			</RowContainer>
		</LotteryDetailContainer>
	);
}
