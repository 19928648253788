import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { useOpenSeaAPI } from '../../../../hooks/useOpenseaAPI';
import { FeaturedAssets } from '../../components/Featured';
import { Features } from '../../components/Features';
import { MainSection } from './components/MainSection';
import { WhitelistPageContainer, SectionContainer } from './Whitelist.styles';
import { data } from './dataMock';

export function WhiteList() {
	const { id } = useParams();

	return (
		<WhitelistPageContainer>
			<SectionContainer
				customHeight="max-content"
				mobileHeight="auto"
				margin="5%"
			>
				<MainSection whitelistInfo={data} />
			</SectionContainer>

			{!!data?.info.length && (
				<SectionContainer
					customHeight="calc(100vh - 40px)"
					mobileHeight="calc(100vh - 40px)"
					background="linear-gradient(180deg, rgba(206, 253, 92, 0) 0%, rgba(117, 251, 76, 0.4) 0.01%, rgba(206, 253, 92, 0) 100%);"
				>
					<Features title={data.infoTitle} features={data?.info || []} />
				</SectionContainer>
			)}
		</WhitelistPageContainer>
	);
}
