import styled from 'styled-components';

export const PrizeSectionContainer = styled.div`
	margin: 2% 0;
`;

export const PrizeSectionTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
	letter-spacing: -0.02em;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin-bottom: 2%;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 400;
		font-size: 25px;
		line-height: 38px;
		margin-bottom: 5%;
	}
`;
