import styled from 'styled-components'


export const P2PContainer = styled.div`
width: 100%;
` 

export const SectionContainer = styled.section<{fullHeight?:boolean, customHeight?:string, mobileHeight?:string, background?:string, margin?:string}>`
height:${({fullHeight,customHeight})=> customHeight ?customHeight : fullHeight ? '100vh':'auto'};
background: ${({background}) => background};

@media ${({theme:{device:{mobile}}})=>mobile} {
    margin-bottom: ${({margin}) => margin};
    height:${({fullHeight,mobileHeight})=> mobileHeight ?mobileHeight : fullHeight ? '100vh':'auto'};
    }


`