import styled from 'styled-components';

export const PaginatorContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const PaginatorNumber = styled.div<{ $isCurrent: boolean }>`
	background-color: ${({ $isCurrent, theme: { colors } }) =>
		$isCurrent ? `${colors.primary}96` : 'transparent'};
	border: ${({ $isCurrent, theme: { colors } }) =>
		$isCurrent ? '1px solid #f2f2f2' : '1px solid transparent'};
	&:hover {
		background-color: ${({ $isCurrent, theme: { colors } }) =>
			$isCurrent ? colors.primary : '#cccccc'};
		border: 1px solid : ${({ theme: { colors } }) => colors.accent};
	}
	border-radius: 100%;
	cursor: pointer;
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0.25rem;
`;
