import styled from 'styled-components';
import rightArrow from '../../assets/right-arrow.png';
export const NoteCardContainer = styled.div`
	position: relative;
`;

export const ShadowContainer = styled.div`
	filter: drop-shadow(16px 16px 24px rgba(0, 48, 63, 0.2));
`;

export const NoteCardContentContainer = styled.div`
	background: white;
	clip-path: polygon(100% 0%, 100% 82%, 89% 100%, 0% 100%, 0% 20%, 10% 0%);
	display: flex;
	justify-content: space-around;

	max-width: 600px;
	max-height: 380px;
	width: 580px;
	height: 340px;
	position: relative;
	box-shadow: 97px 16px 24px black;
`;

export const NoteCardDecoration = styled.div`
	position: absolute;
	bottom: 0px;
	right: 0;
	width: 0;
	height: 0;
	border-bottom: 40px solid
		${({
			theme: {
				colors: { accent },
			},
		}) => accent};
	border-left: 40px solid transparent;
`;

export const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 40%;
`;

export const NoteCardImage = styled.div<{ src: string }>`
	border-radius: 100%;
	background: black url(${({ src }) => src}) no-repeat center center/cover;
	width: 160px;
	height: 160px;
	margin: auto;
	border: 4px solid black;
`;

export const NoteCardTitleContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35%;
`;

export const NoteCardTitle = styled.span`
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	font-style: normal;
	font-weight: normal;
	font-size: 76px;
	line-height: 80px;
	margin: none;
	box-sizing: border-box;
`;
export const NoteCardSubtitle = styled.span`
	color: ${({
		theme: {
			colors: {
				background: { lightGray },
			},
		},
	}) => lightGray};
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 30px;
	align-self: end;
	margin-top: -8px;
`;

export const RightArrow = styled.div`
	background: transparent url(${rightArrow}) no-repeat center center/cover;
	width: 34px;
	height: 34px;
`;

export const RightContainer = styled.div`
	display: flex;
	width: 60%;
`;

export const NoteCardText = styled.pre`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	font-style: normal;
	font-weight: normal;
	text-align: justify;
	font-size: 1.6vh;
	line-height: 25px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	padding: 6px 16px 24px 0px;
	white-space: pre-wrap;
`;

export const NoteCardLink = styled.a`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	font-style: normal;
	font-weight: bolder;
	font-size: 16px;
	line-height: 30px;
	display: block;
`;
