import './index.css';
import { ThemeProvider } from 'styled-components';
import { DesciTheme } from './theme';
import Routers from './routes';
import {
	PROJECT_ID,
	Web3Provider,
	ethereumClient,
	wagmiClient,
} from './hooks/useWeb3';
import { APIProvider } from './hooks/useDesciWorldAPI';
import { OpenSeaProvider } from './hooks/useOpenseaAPI';
import { ThirdPartyAPIProvider } from './hooks/useAPI';
import { SnapshotProvider } from './hooks/useSnapshotAPI';
import { Web3Modal } from '@web3modal/react';
import { WagmiConfig } from 'wagmi';
import { GlobalStyle } from './theme/GlobalStyles';
import { MosaicAPIProvider } from './hooks/useMosaic';

function App() {
	return (
		<>
			<WagmiConfig client={wagmiClient}>
				<GlobalStyle />
				<Web3Provider>
					<ThemeProvider theme={DesciTheme}>
						<APIProvider>
							<OpenSeaProvider>
								<ThirdPartyAPIProvider>
									<MosaicAPIProvider>
										<SnapshotProvider>
											<Routers />
										</SnapshotProvider>
									</MosaicAPIProvider>
								</ThirdPartyAPIProvider>
							</OpenSeaProvider>
						</APIProvider>
					</ThemeProvider>
				</Web3Provider>
			</WagmiConfig>
			<Web3Modal
				ethereumClient={ethereumClient}
				projectId={PROJECT_ID}
				themeBackground="gradient"
				themeColor="green"
				themeMode="dark"
			/>
		</>
	);
}

export default App;
