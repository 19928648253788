import styled from 'styled-components';
import { SortButtonType } from './types';

export const SortButtonContainer = styled.div<Partial<SortButtonType>>`
	clip-path: polygon(
		10% 0%,
		100% 0%,
		100% 0%,
		100% 100%,
		100% 100%,
		0% 100%,
		0% 100%,
		0% 35%
	);
	position: relative;
	top: 2px;
	left: 2px;
	margin-right: 2px;
	width: calc(max-content - 4px);
	height: calc(100% - 4px);
	background: ${({
		theme: {
			colors: {
				primary,
				background: { default: def, gray },
			},
		},
		active,
	}) => (!active ? def : `linear-gradient(0deg, ${def} 2%, ${gray} 98%);`)};

	padding: 0px 16px;
`;

export const SortButtonText = styled.span`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	width: max-content;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const SortButtonArrow = styled.div``;

export const SortButtonContainerClip = styled.div`
	clip-path: polygon(
		10% 0%,
		100% 0%,
		100% 0%,
		100% 100%,
		100% 100%,
		0% 100%,
		0% 100%,
		0% 35%
	);
	background: ${({
		theme: {
			colors: {
				background: { gray },
			},
		},
	}) => gray};
	height: 32px;
	width: max-content;
	padding-right: 2px;
	margin: 4px;
	&:hover {
		cursor: pointer;
	}
`;
