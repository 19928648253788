import styled from 'styled-components';
import download from '../../../../../../assets/icons/download.svg';

export const ResearchSectionContainer = styled.div`
	width: 100%;
	margin: 5% auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: space-between;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 0 auto;
	}
`;

export const ResearchCard = styled.div`
	width: 70%;
	margin: 20px auto;
	background: #a8c0c3;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
	}
`;

export const ResearchCardTitle = styled.h3`
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 30px;
	letter-spacing: 0.02em;
	margin: 20px 32px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	mix-blend-mode: normal;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 1.5rem;
	}
`;

export const ResearchCardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ResearchListContainer = styled.div`
	background: ${({
		theme: {
			colors: {
				background: { white },
			},
		},
	}) => white};
	margin: 15px 15px;
	min-height: 20vh;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 0;
	}
`;

export const ResearchList = styled.ul`
	list-style: none;
	padding: 1rem;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const ResearchListItem = styled.li`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 95%;
		border-bottom: 1px solid #585a5e;
	}
`;

export const ResearchListItemTitle = styled.h3`
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0.02em;
	text-align: left;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin-bottom: 0;
`;

export const ResearchListItemDescription = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const DownloadIcon = styled.div`
	width: 30px;
	height: 30px;
	margin: 0 20px;
	background: transparent url(${download}) no-repeat center center/cover;
	&:hover {
		cursor: pointer;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const ResearchSectionFooter = styled.div`
	height: 5px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const ButtonContainer = styled.div`
	width: 70%;
	margin: 20px auto;
	display: flex;
	align-items: center;
	justify-content: end;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		justify-content: center;
		margin: 0 auto;
	}
`;

export const NotesContainer = styled.div`
	width: 70%;
	margin: 30px auto;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		margin: 0 auto;
	}
`;
