import styled from 'styled-components';

export const DetailCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 500px;
	border-top: 4px solid
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};
	border-bottom: 4px solid
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		min-width: 0;
	}
`;

export const DetailCardHeader = styled.div`
	width: 100%;
	height: calc(30%-26px);
	display: flex;
	box-sizing: border-box;
	background: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	padding: 1rem;
	justify-content: space-between;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: center;
	}
`;

export const DetailCardHeaderLeftContent = styled.div`
	width: 75%;
	display: flex;
	flex-direction: column;
	justify-content: left;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		flex-direction: column;
		align-items: start;
	}
`;

export const DetailCardName = styled.span`
	color: ${({
		theme: {
			colors: { softBlack },
		},
	}) => softBlack};
	font-style: normal;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 2rem;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const DetailCardUser = styled.span`
	color: ${({
		theme: {
			colors: { softBlack },
		},
	}) => softBlack};
	font-style: italic;
	font-weight: normal;
	font-size: 1rem;
	line-height: 2rem;
`;

export const DetailCardHeaderRightContent = styled.div`
	display: flex;
	/* width: 25%;
	padding: 4px; */
	justify-content: right;
	align-items: start;
	gap: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		justify-content: left;
		align-items: start;
		padding: 1rem;
	}
`;

export const DetailCardStatusText = styled.span`
	color: ${({
		theme: {
			colors: { softBlack },
		},
	}) => softBlack};
	font-style: normal;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 2rem;
`;

export const DetailCardContentContainer = styled.div`
	display: flex;
	height: 70%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
	}
`;

export const DetailCardLeftContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 75%;
	padding: 24px 32px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 0;
		box-sizing: border-box;
		align-items: center;
		width: 100%;
	}
`;

export const DetailCardInnerContainer = styled.div`
	width: 90%;
	display: flex;
	padding: 0.2rem;
	box-sizing: border-box;
	justify-content: space-evenly;
	gap: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-wrap: wrap;
		padding: 0.5rem 0rem;
	}
`;
export const DetailCardDescription = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 16px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 1rem;
	}
`;

export const DetailCardItemContainer = styled.div<{ column?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: left;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		justify-content: center;
		margin: 0 auto;
		${({ column }) => column && 'flex-direction: column;'}
	}
`;

export const DetailCardItemText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 50px;
	margin-left: 8px;
	display: inline-block;
`;

export const DetailCardRightContent = styled.div`
	width: 25%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	align-items: center;
	box-sizing: border-box;
	background: linear-gradient(
		180deg,
		${({
				theme: {
					colors: { primary },
				},
			}) => primary}88
			0%,
		${({
				theme: {
					colors: { white },
				},
			}) => white}
			100%
	);

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		justify-content: left;
		margin-bottom: 1rem;
	}
`;

export const DetailCardTokenInfoContainer = styled.div`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		justify-content: space-around;
		margin-bottom: 1.5rem;
	}
`;

export const DetailCardAmountContainer = styled.div`
	display: flex;
	align-items: end;
`;

export const DetailCardTokenText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: italic;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	margin-right: 6px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 1.2rem;
	}
`;

export const DetailCardTokenAmount = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: italic;
	font-weight: normal;
	font-size: 32px;
	line-height: 30px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 2.8rem;
	}
`;

export const DetailCardTokenImg = styled.img`
	width: 100px;
	height: 100px;
`;
