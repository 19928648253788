import { ButtonContainerClip, StyledButton } from './Button.styles';

export function Button({
	onClick,
	color,
	children,
	className,
	disabled,
	type,
	width,
}: {
	disabled?: boolean;
	onClick: () => void;
	color?: string;
	children: any;
	className?: string;
	type?: any;
	width?: string;
}) {
	return (
		<ButtonContainerClip
			disabled={disabled}
			className={className}
			color={color || 'primary'}
			width={width}
		>
			<StyledButton
				disabled={disabled}
				onClick={onClick}
				color={color || 'primary'}
				type={type}
			>
				{children}
			</StyledButton>
		</ButtonContainerClip>
	);
}
