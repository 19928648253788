import { ChangeEvent } from 'react';
import { StyledLabel } from '../Form.styles';
import { FormElementProps } from '../types';
import { InputContainer, StyledInput } from './Input.styles';

export function Input({
	value,
	onChange,
	placeholder,
	disabled,
	label,
	type,
	min,
	step,
	max,
	width,
}: FormElementProps) {
	return (
		<InputContainer width={width}>
			{label && <StyledLabel>{label}</StyledLabel>}
			<StyledInput
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					const text = e.target.value;
					onChange(text);
				}}
				min={min}
				max={max}
				step={step}
				disabled={disabled}
			/>
		</InputContainer>
	);
}
