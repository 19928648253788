import { useViewport } from '../../../../../utils/viewPortHook';
import { FourthSectionContainer } from '../../../components/FourthSection/FourthSection.styles';
import { PresentationDisplayCard } from './components/PresentationDisplayCard';
import {
	PresentationContainer,
	TopSectionContainer,
	BottomSectionContainer,
	PresentationCardLeftContentContainer,
	PresentationCardRightContentContainer,
	PresentationCardTextBlock,
	PresentationCardImage,
	PresentationTitleText,
	PresentationCardText,
	VerticalTitleText,
	WorkshopTopContentContainer,
	WorkshopBottomContentContainer,
	WorkshopCardContentContainer,
	FiresideTopContentContainer,
	FiresideBottomContentContainer,
	PresentationCardContentContainer,
	FireSideImageContainer,
	FlexGappedContainer,
	BottomPresentationCardAndTitleContainer,
} from './PresentationSection.styles';

export function PresentationSection() {
	const { width } = useViewport();
	const breakpoint = 475;
	const isMobile = width < breakpoint;
	const medBreakpoint = 1280;
	const isMed = width < medBreakpoint;
	return (
		<FourthSectionContainer>
			<PresentationContainer>
				<TopSectionContainer>
					<PresentationTitleText>PRESENTATIONS</PresentationTitleText>
					<PresentationDisplayCard>
						<PresentationCardContentContainer>
							<PresentationCardLeftContentContainer>
								{isMobile && (
									<>
										<PresentationCardTextBlock>
											educate; share; & inspire
										</PresentationCardTextBlock>
									</>
								)}
								<PresentationCardText>
									<strong>
										Gain valuable insights into the state of DeSci with over 4
										hours of programming such as:
									</strong>{' '}
									<br />
									<strong>
										<br />• Project presentations
									</strong>
									<br />
									Meet the projects building in DeSci; incumbents innovating in
									the fields of research funding, security, data management,
									provenance, AI and more.
									<br />
									<strong>• Product Demo</strong>
									<br />
									Attend live demonstrations of cutting edge products driving
									innovation in science.
									<br />
									<strong>• Call to action</strong>
									<br />
									Help shape DeSci in Sofia through dialogue and partnership
									with the community.
									<br />
									<strong>• Thought leadership</strong>
									<br />
									Get inspired to rethink the way we approach science with
									DeSci; prominent thinkers share their insights on the future
									of scientific discovery.
									<br />
									<strong>• Web3 tutorials</strong>
									<br />
									Learn the ropes of Web3 technology; self custody, security and
									sovereignty.{' '}
								</PresentationCardText>
							</PresentationCardLeftContentContainer>
							<PresentationCardRightContentContainer>
								{!isMobile && (
									<PresentationCardTextBlock>
										educate; share; & inspire
									</PresentationCardTextBlock>
								)}
								<PresentationCardImage
									width="19rem"
									height="16rem"
									src="https://i.imgur.com/Hh4YVSi.png"
								/>
							</PresentationCardRightContentContainer>
						</PresentationCardContentContainer>
					</PresentationDisplayCard>
				</TopSectionContainer>
				<BottomSectionContainer>
					<BottomPresentationCardAndTitleContainer>
						{!isMed && <VerticalTitleText>WORKSHOPS</VerticalTitleText>}
						{isMed && <PresentationTitleText>WORKSHOPS</PresentationTitleText>}

						<PresentationDisplayCard>
							<FlexGappedContainer>
								<WorkshopCardContentContainer>
									<PresentationCardImage
										height="16rem"
										width="10rem"
										src="https://i.imgur.com/FP1Im67.png"
									/>
								</WorkshopCardContentContainer>
								<WorkshopCardContentContainer>
									<WorkshopTopContentContainer>
										<PresentationCardTextBlock>
											learn; ideate & connect
										</PresentationCardTextBlock>
									</WorkshopTopContentContainer>
									<WorkshopBottomContentContainer>
										<PresentationCardText>
											<strong>Interactive and engaging workshops:</strong>
											<br />• Applied DeSci tooling <br />• DAO infrastructure{' '}
											<br />• Ethical DeSci practise
											<br />• Pitching sessions for founders <br />•
											Tokeneconomic modelling
										</PresentationCardText>
									</WorkshopBottomContentContainer>
								</WorkshopCardContentContainer>
							</FlexGappedContainer>
						</PresentationDisplayCard>
					</BottomPresentationCardAndTitleContainer>
					<BottomPresentationCardAndTitleContainer>
						{isMed && <PresentationTitleText>FIRESIDES</PresentationTitleText>}
						<PresentationDisplayCard>
							<WorkshopCardContentContainer>
								<FiresideTopContentContainer>
									{isMobile && (
										<>
											<PresentationCardTextBlock>
												connect; share; & inspire
											</PresentationCardTextBlock>
											<br />
										</>
									)}
									<PresentationCardText>
										<strong>
											Enjoy expert panels on a variety of topics, e.g.:
										</strong>
										<br />• To DAO or not to DAO?
										<br />• Alignment questions (AI, tokens, anonymity)
										<br />• Reimagining IP with the DeSci framework
										<br />• DeSci without Web3
									</PresentationCardText>
								</FiresideTopContentContainer>
								<FiresideBottomContentContainer>
									<PresentationCardImage
										src="https://i.imgur.com/1Oq8mv3.png"
										width="12rem"
									/>
									{!isMobile && (
										<PresentationCardTextBlock>
											connect; share; & inspire
										</PresentationCardTextBlock>
									)}
								</FiresideBottomContentContainer>
							</WorkshopCardContentContainer>
						</PresentationDisplayCard>
						{!isMed && <VerticalTitleText>FIRESIDES</VerticalTitleText>}
					</BottomPresentationCardAndTitleContainer>
				</BottomSectionContainer>
			</PresentationContainer>
		</FourthSectionContainer>
	);
}
