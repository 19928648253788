import styled from 'styled-components';

function handleComplementColor(color: string) {
	switch (color) {
		case 'primary':
		case 'secondary':
			return 'accent';
		case 'accent':
			return 'primary';

		default:
			return 'accent';
	}
}

export const StyledButton = styled.button<{ color: string; disabled: boolean }>`
	background: ${({ theme: { colors }, color }) =>
		color ? colors[color] || color : colors.primary};
	width: 88%;
	height: 80%;
	clip-path: polygon(100% 0%, 100% 80%, 90% 100%, 0% 100%, 0% 20%, 10% 0%);
	color: ${({ theme: { colors }, color, disabled }) =>
		disabled
			? colors.background.default
			: colors[handleComplementColor(color)]};
	border: none;
	position: relative;
	top: ${({ disabled }) => (disabled ? '0px' : '2px')};
	left: ${({ disabled }) => (disabled ? '0px' : '2px')};
	width: calc(100% - ${({ disabled }) => (disabled ? '0px' : '4px')});
	height: calc(100% - ${({ disabled }) => (disabled ? '0px' : '4px')});
	font-size: 16px;
	font-weight: bold;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	text-transform: uppercase;
	&:hover {
		${({ disabled }) =>
			!disabled &&
			`
    top: 2.5px;
    left: 2px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);`}
	}
	&:active {
		${({ disabled }) =>
			!disabled &&
			`
    top: 1px;
    left: 1px;
	width: calc(100% - ${disabled ? '0px' : '4px'});
	height: calc(100% - ${disabled ? '0px' : '4px'});`}
	}
`;

export const ButtonContainerClip = styled.div<{
	color: string;
	width?: string;
	disabled: boolean;
}>`
	clip-path: polygon(100% 0%, 100% 80%, 90% 100%, 0% 100%, 0% 20%, 10% 0%);
	background: ${({ theme: { colors }, color }) =>
		colors[handleComplementColor(color)]};
	max-width: ${({ width }) => width || `300px`};
	max-height: 72px;
	width: ${({ width }) => width || `220px`};
	height: 60px;
	opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};
`;
