
import { Legend } from '../../../../../../components/Legend';
import { useViewport } from '../../../../../../utils/viewPortHook';
import {
	LegendsContainer,
	MainSectionContainer,
} from './MainSection.styles';

export function MainSection({
	totalGrants,
	totalMembers,
	organizationCount,
}: {
	totalGrants: number;
	organizationCount: number;
	totalMembers: number;
}) {
	const { width } = useViewport();
	const mobile = width < 475;
	return (
		<MainSectionContainer>
			<LegendsContainer>
				<Legend
					name="Active Users"
					value={(totalMembers || '') + ''}
					icon="people"
				/>
				<Legend
					name="Project Listed"
					value={+organizationCount + ''}
					icon="file"
				/>
				{!!totalGrants && (
					<Legend
						name="Total grant funding"
						value={+totalGrants + ''}
						icon="eth"
					/>
				)}
			</LegendsContainer>
			
		</MainSectionContainer>
	);
}
