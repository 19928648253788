import styled from 'styled-components';
export const PageTitle = styled.div`
	font-size: 2rem;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.accent};
	margin: 1rem 0rem;
`;
export const AskFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
`;
export const TopFormContainer = styled.div`
	display: flex;
	gap: 1rem;
`;
