import { EntityNames } from '../constants/api';
import {
	APIActionType,
	APIStateType,
	AuthStateType,
	JobStateType,
	LotteryStateType,
	MemberStateType,
	MintContractStateType,
	operationName,
	OrganizationStateType,
	ProposalStateType,
} from '../types';

function updateToggleLoadingInState(
	state: any,
	{ response = null, error = null }: { response?: any; error?: any } = {},
) {
	if (state.loading) {
		return { ...state, loading: false, response, error };
	} else {
		return { ...state, loading: true };
	}
}

export function MemberReducer(
	state: MemberStateType,
	{ operation, payload }: APIActionType,
) {
	const { response = null, error = null } = payload || {};
	if (error) {
		return { ...state, error, loading: false };
	}
	switch (operation) {
		case operationName.toggleLoading:
			return updateToggleLoadingInState(state, payload);
		case operationName.clear:
			return { ...state, member: null, loading: false };
		case operationName.create:
		case operationName.getById:
		case operationName.delete:
			const { member } = payload;
			return { ...state, member, response, error, loading: false };
		case operationName.filter:
			const { members } = payload;
			return { ...state, members, response, error, loading: false };
	}
}

export function JobReducer(
	state: JobStateType,
	{ operation, payload }: APIActionType,
) {
	const { response = null, error = null } = payload || {};
	switch (operation) {
		case operationName.toggleLoading:
			return updateToggleLoadingInState(state, payload);
		case operationName.clear:
			return { ...state, job: null, loading: false };
		case operationName.create:
		case operationName.getById:
		case operationName.delete:
			const { job } = payload;
			return { ...state, job, error, response, loading: false };
		case operationName.filter:
			const { jobs } = payload;
			return { ...state, jobs, error, response, loading: false };
	}
}

export function ProposalReducer(
	state: ProposalStateType,
	{ operation, payload }: APIActionType,
) {
	const { response = null, error = null } = payload || {};
	switch (operation) {
		case operationName.toggleLoading:
			return updateToggleLoadingInState(state, payload);
		case operationName.clear:
			return { ...state, proposal: null, loading: false };
		case operationName.create:
		case operationName.getById:
		case operationName.delete:
			const { proposal } = payload;
			return { ...state, proposal, error, response, loading: false };
		case operationName.filter:
			const { proposals } = payload;
			return { ...state, proposals, error, response, loading: false };
	}
}

export function OrganizationReducer(
	state: OrganizationStateType,
	{ operation, payload }: APIActionType,
) {
	const { response = null, error = null } = payload || {};
	switch (operation) {
		case operationName.toggleLoading:
			return updateToggleLoadingInState(state, payload);
		case operationName.clear:
			return { ...state, organization: null, loading: false };
		case operationName.create:
		case operationName.getById:
		case operationName.delete:
			const { organization } = payload;
			return { ...state, organization, error, response, loading: false };
		case operationName.filter:
			const { organizations } = payload;
			return { ...state, organizations, error, response, loading: false };
	}
}

export function ContractReducer(
	state: MintContractStateType,
	{ operation, payload }: APIActionType,
) {
	const { response = null, error = null } = payload || {};
	switch (operation) {
		case operationName.toggleLoading:
			return updateToggleLoadingInState(state, payload);
		case operationName.clear:
			return { ...state, contract: null, loading: false };
		case operationName.create:
		case operationName.getById:
		case operationName.delete:
			const { contract } = payload;
			return { ...state, contract, error, response, loading: false };
		case operationName.filter:
			const { contracts } = payload;
			return { ...state, contracts, error, response, loading: false };
	}
}

export function AuthReducer(
	state: AuthStateType,
	{ operation, payload }: APIActionType,
) {
	const { response = null, error = null } = payload || {};
	switch (operation) {
		case operationName.toggleLoading:
			return updateToggleLoadingInState(state, payload);
		case operationName.login:
			const { member } = payload;
			return { ...state, user: member, response, error, loading: false };
		case operationName.logout:
			return state;
	}
}

export function LotteryReducer(
	state: LotteryStateType,
	{ operation, payload }: APIActionType,
) {
	const { response = null, error = null } = payload || {};
	switch (operation) {
		case operationName.toggleLoading:
			return updateToggleLoadingInState(state, payload);
		case operationName.clear:
			return { ...state, lottery: null, loading: false };
		case operationName.getById:
		case operationName.getCurrent:
			const { lottery } = payload;
			return { ...state, lottery, error, response, loading: false };
		case operationName.filter:
			const { lotterys } = payload;
			return { ...state, lotterys, error, response, loading: false };
	}
}
