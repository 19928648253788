import { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { ContainerCard } from '../../../../../../components/ContainerCard';
import { Tweet } from '../../../../../../components/Tweet/Tweet';
import { DesciTheme } from '../../../../../../theme';
import type {
	Organization,
	SnapshotProposal,
	Tweet as TweetType,
} from '../../../../../../types';
import { LockIcon } from '../../../../components/Footer/Footer.styles';
import { NewsSidebar } from '../NewsSidebar';
import { RecentDevelopment } from '../RecentDevelopmentSection';
import {
	CardContentContainer,
	ChartContainer,
	LeftContainer,
	MainSectionContainer,
	ProjectLogo,
	StyledHighlightedParagraph,
	StyledVerticalLine,
	TokenDescriptionText,
	TokenInfoContainer,
	TokenLinkText,
	TokenSymbolText,
} from './MainSection.styles';

export function MainSection({
	organization,
	proposals,
	totalMembers,
	tweets,
	chartData,
	chartLoading,
	proposalLoading,
}: {
	organization: Organization;
	proposals: SnapshotProposal[];
	totalMembers: number;
	tweets?: TweetType[];
	chartData?: number[][];
	proposalLoading: boolean;
	chartLoading: boolean;
}) {
	const primaryAxis = useMemo<AxisOptions<{ date: Date; price: number }>>(
		() => ({
			getValue: ({ date }) => date,
			scaleType: 'time',
			styles: {
				color: 'black',
			},
		}),
		[],
	);

	const secondaryAxes = useMemo<AxisOptions<{ date: Date; price: number }>[]>(
		() => [
			{
				getValue: ({ price }) => price,
				formatters: {
					scale(value: number) {
						return `$${value}`;
					},
				},

				stacked: true,
				elementType: 'area',
				scaleType: 'linear',
				tickCount: 0,
				styles: {
					strokeWidth: 4,
				},
			},
		],
		[],
	);
	const data = useMemo(
		() => [
			{
				label: `Price`,

				data: chartData?.map(([date, price]) => ({
					date: new Date(date),
					price: +price,
				})),
				color: DesciTheme.colors.primary,
			},
		],

		[chartData],
	);
	return (
		<MainSectionContainer>
			<LeftContainer>
				<ProjectLogo src={organization?.logo} />
				<StyledHighlightedParagraph
					customBackground="white"
					title={`What is ${organization?.displayName || organization?.name}?`}
					color={organization?.primaryColor}
				>
					{organization?.description}
				</StyledHighlightedParagraph>

				{organization?.tokenSymbol &&
					(organization?.tokenDescription || organization?.tokenTradeLink) && (
						<>
							<LockIcon unclaimed={organization?.unclaimed} />
							<ContainerCard
								width="100%"
								height="380px"
								unclaimed={organization?.unclaimed}
							>
								<CardContentContainer>
									<TokenInfoContainer>
										<TokenSymbolText>
											${organization?.tokenSymbol}
										</TokenSymbolText>
										<TokenDescriptionText>
											{organization?.tokenDescription}
										</TokenDescriptionText>
										{organization?.tokenTradeLink && (
											<TokenLinkText
												href={organization.tokenTradeLink}
												target="_blank"
											>
												get ${organization?.tokenSymbol}
											</TokenLinkText>
										)}
									</TokenInfoContainer>
									<ChartContainer>
										{organization.coingeckoId &&
											!chartLoading &&
											chartData?.length && (
												<Chart
													options={{
														data,
														primaryAxis,
														defaultColors: [DesciTheme.colors.primary],
														getSeriesStyle() {
															return {
																line: {
																	stroke: DesciTheme.colors.accent,
																	strokeWidth: 3,
																},
																area: {
																	opacity: 0.2,
																},
															};
														},
														secondaryAxes,
													}}
												/>
											)}
										{organization.coingeckoId &&
											chartLoading &&
											'Loading chart...'}
									</ChartContainer>
								</CardContentContainer>
							</ContainerCard>
						</>
					)}
				{organization?.twitterHandle && !!tweets?.length && (
					<RecentDevelopment unclaimed={organization?.unclaimed}>
						{tweets?.length &&
							tweets?.map((tweet, index) => (
								<>
									<Tweet tweet={tweet} />
									{index !== tweets.length - 1 && <StyledVerticalLine />}
								</>
							))}
					</RecentDevelopment>
				)}
			</LeftContainer>
			<NewsSidebar
				totalMembers={totalMembers}
				organization={organization}
				proposals={proposals}
			/>
		</MainSectionContainer>
	);
}
