import styled from 'styled-components';
import { Button } from '../../../../../../components/Button';

export const NewsSidebarContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 40%;
	margin: 40px 0px 0px 20px;
	align-items: end;
	justify-content: space-around;
	height: 100%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		align-items: center;
	}
`;

export const NoteContainer = styled.div<{
	color?: string;
	unclaimed?: boolean;
}>`
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	display: flex;
	flex-direction: column;
	width: 270px;
	min-height: 245px;
	background: ${({ theme: { colors }, color }) =>
		color ? colors[color] || color : colors.primary};
	padding: 0 15px;
	margin: 10px 0;
`;

export const NoteTitle = styled.h3`
	font-weight: bold;
	font-size: 30px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const NoteItem = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #585a5e;
	padding: 0 20px;
	margin: 12px 0;
`;

export const NoteItemTitle = styled.a`
	font-weight: bold;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.02em;
	margin: 0;
	text-transform: uppercase;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	text-decoration: none;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const NoteItemEvent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	margin: 12px 0;
`;

export const NoteItemTitleDate = styled.h3`
	font-weight: bold;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.02em;
	margin: 0;
	text-transform: uppercase;
	border-bottom: 1px solid
		${({
			theme: {
				colors: { accent },
			},
		}) => accent};
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const NoteItemDescription = styled.p`
	font-style: italic;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
`;

export const NoteItemDescriptionEvent = styled.div`
	display: flex;
	justify-content: start;
`;

export const NoteItemDate = styled.p`
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.02em;
	padding: 10px 15px;
	font-weight: bold;
	margin: 0;
	border-right: 1px solid
		${({
			theme: {
				colors: { accent },
			},
		}) => accent};
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
`;

export const NoteItemDateDescription = styled.p`
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.02em;
	padding: 10px 15px;
	font-weight: normal;
	margin: 0;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
`;

export const LogoContainer = styled.div<{
	unclaimed: Boolean;
}>`
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	position: relative;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const LogoItem = styled.a<{ src: string }>`
	margin: 0 12px;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: url(${({ src }) => src});
	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 1px black;
	}
	&:active {
		box-shadow: 0px 0px 3px black;
	}
`;

export const ButtonWithMargin = styled(Button)<{
	unclaimed: Boolean;
}>`
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	margin: 12px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
	}
`;
