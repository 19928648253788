import styled from 'styled-components';

export const MainSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: transparent;
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: column;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		margin-bottom: 20px;
	}
`;

export const LegendsContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: end;
	width: 95%;
	@media ${({
			theme: {
				device: { small },
			},
		}) => small} {
		margin: 20px 0px;
	}
`;

export const CardText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	line-height: 24px !important;
	height: 95%;
	display: flex;
	align-items: center;
`;
