import styled from 'styled-components';
import Logo from '../../../../../../assets/projectLogo.png';
import { HighlightedParagraph } from '../../../../../../components/HighlightedParagraph';
import { VerticalLine } from '../../../../../Landing/components/DecoratedParagraph/DecoratedParagraph.styles';

export const MainSectionContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	min-height: 70vh;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: center;
		min-height: auto;
	}
`;

export const LeftContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		align-items: center;
	}
`;
export const ProjectLogo = styled.img`
	max-height: 100px;
	max-width: 100%;
	object-fit: contain;
	object-position: left;
	padding: 2px 12px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 20px 0;
	}
`;

export const CardContentContainer = styled.div`
	width: 100%;
	display: flex;
	height: 80%;
	padding: 12px;
`;

export const TokenInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 45%;
	box-sizing: border-box;
	justify-content: center;
`;

export const TokenSymbolText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
	margin-bottom: 8px;
`;
export const TokenDescriptionText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	overflow: ellipsis;
`;

export const TokenLinkText = styled.a`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	overflow: ellipsis;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;

	/* or 167% */
	letter-spacing: 0.02em;
	text-decoration-line: underline;
	text-transform: uppercase;

	margin-top: auto;
	display: flex;
`;
export const ChartContainer = styled.div`
	width: 50%;
	display: flex;
	height: 100%;
	justify-content: center;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
`;

export const StyledHighlightedParagraph = styled(HighlightedParagraph)`
	margin-bottom: 16px;
	box-sizing: border-box;
`;

export const StyledVerticalLine = styled(VerticalLine)`
	box-sizing: border-box;
	margin-top: 20px;
	border-color: ${({
		theme: {
			colors: { accent },
		},
	}) => `${accent}22`};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;
