import axios from 'axios';
import { createContext, ReactElement, useContext, useState } from 'react';
import { MICROBE_ADDRESS } from '../constants/abi';
import {
	CollectionInfo,
	NFTForDisplay,
	OpenSeaAsset,
	OpenSeaCollection,
	Prize,
	PrizeType,
} from '../types';

const OPENSEA_API_URL = 'https://api.opensea.io/api/v1/';
const OPENSEA_COLLECTION_URL = 'https://opensea.io/collection/';
const OPENSEA_ASSET_URL = 'https://opensea.io/assets/';
// const TEST_OPENSEA_API: string = 'https://testnets-api.opensea.io/api/v1/';
const MICROBE_COLLECTION_SLUG = 'desciworldmicrobes';
const FEATURED_TOKEN_IDS = ['43', '13', '56', '46', '48'];
export function useOpenSeaAPIState() {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);
	const [featuredAssets, setFeaturedAssets] = useState<NFTForDisplay[]>([]);
	const [collectionInfo, setCollectionInfo] = useState<CollectionInfo>(null);
	const [contractAddress, setContractAddress] =
		useState<string>(MICROBE_ADDRESS);

	async function getAssetByTokenId(tokenId: string, address?: string) {
		try {
			setLoading(true);
			const { data } = await axios.get<OpenSeaAsset>(
				`${OPENSEA_API_URL}asset/${
					address || collectionInfo.address || contractAddress
				}/${tokenId}`,
			);
			setResponse(data);
			setLoading(false);
			return data;
		} catch (e) {
			console.log(e);
			setError(e);
		}
	}

	function getNFTFromOpenSeaAsset(asset: OpenSeaAsset): NFTForDisplay {
		const {
			name,
			description,
			token_id,
			animation_original_url,
			image_original_url,
			image_url,
			collection: { name: collectionName },
			asset_contract: { address },
		} = asset;
		return {
			name: name || `${collectionName} : ${token_id}`,
			link: `${OPENSEA_ASSET_URL}${address}/${token_id}`,
			buttonText: 'Show',
			description,
			mediaUrl: animation_original_url || image_url || image_original_url,
		};
	}

	async function getCollectionInfo(
		collectionSlug?: string,
	): Promise<CollectionInfo> {
		try {
			setLoading(true);
			const {
				data,
				data: {
					collection: {
						stats: { count, total_supply },
						name,
						banner_image_url,
						description,
						primary_asset_contracts: [{ address }],
					},
				},
			} = await axios.get<{ collection: OpenSeaCollection }>(
				`${OPENSEA_API_URL}collection/${
					collectionSlug || MICROBE_COLLECTION_SLUG
				}`,
			);
			console.log({ data });
			const info = {
				count,
				totalSupply: total_supply,
				name,
				mediaUrl: banner_image_url,
				description: description.substring(0, 470),
				address,
				slug: collectionSlug,
				assetUrl: `${OPENSEA_ASSET_URL}${address}/`,
				url: `${OPENSEA_COLLECTION_URL}${collectionSlug}`,
			};
			setCollectionInfo(info);
			setContractAddress(address);
			setLoading(false);
			return info;
		} catch (e) {
			console.log(e);
			setError(e);
		}
	}

	async function getFeaturedAssetList(
		tokenIds?: string[],
		address?: string,
	): Promise<NFTForDisplay[]> {
		try {
			setLoading(true);
			let assets: NFTForDisplay[] = [];
			if (!!tokenIds?.length) {
				const featuredTokenIds =
					(!!tokenIds?.length && tokenIds) || FEATURED_TOKEN_IDS;
				for (const tokenId of featuredTokenIds) {
					const asset = await getAssetByTokenId(tokenId, address);
					const nft = getNFTFromOpenSeaAsset(asset);
					assets.push(nft);
				}
			}
			setFeaturedAssets(assets);
			setLoading(false);
			return assets;
		} catch (e) {
			console.log(e);
			setError(e);
		}
	}

	async function getAssetsByPrize(prizes: Prize[]) {
		setLoading(true);
		let assets: NFTForDisplay[] = [];
		for (const prize of prizes) {
			if (prize.type === PrizeType.token) {
				if (prize.openseaCollectionAddress) {
					const asset = await getAssetByTokenId(
						prize.tokenId,
						prize.openseaCollectionAddress,
					);
					const nft = getNFTFromOpenSeaAsset(asset);
					assets.push(nft);
				} else {
					const collInfo = await getCollectionInfo(prize.openseaCollectionSlug);
					const asset = await getAssetByTokenId(
						prize.tokenId,
						collInfo.address,
					);
					const nft = getNFTFromOpenSeaAsset(asset);
					assets.push(nft);
				}
			}
		}
		setFeaturedAssets(assets);
		setLoading(false);
		return assets;
	}

	return {
		getAssetByTokenId,
		loading,
		error,
		response,
		featuredAssets,
		getFeaturedAssetList,
		collectionInfo,
		getCollectionInfo,
		getAssetsByPrize,
	};
}

export const OpenSeaContext: React.Context<{
	getAssetByTokenId: (
		tokenId: string,
		address?: string,
	) => Promise<OpenSeaAsset>;
	error: any;
	loading: boolean;
	response: any;
	featuredAssets: NFTForDisplay[];
	getFeaturedAssetList: (
		tokenIds?: string[],
		address?: string,
	) => Promise<NFTForDisplay[]>;
	getAssetsByPrize: (prizes: Prize[]) => Promise<NFTForDisplay[]>;
	collectionInfo: CollectionInfo;
	getCollectionInfo: (collectionSlug?: string) => Promise<CollectionInfo>;
}> = createContext({} as any);

export function OpenSeaProvider({ children }: { children?: ReactElement }) {
	const openSea = useOpenSeaAPIState();

	return (
		<OpenSeaContext.Provider value={openSea}>
			{children}
		</OpenSeaContext.Provider>
	);
}

export function useOpenSeaAPI() {
	return useContext(OpenSeaContext);
}
