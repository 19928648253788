import { useEffect, useState } from 'react';
import { Button } from '../../../../../components/Button';
import { TextArea } from '../../../../../components/Form';
import {
	MosaicContainer,
	QuestionDescription,
	QuestionTagsText,
	QuestionTitle,
} from '../Mosaic.styles';
import {
	AnswerInputContainer,
	AnswerItemContainer,
	AnswerListContainer,
	AnswerText,
	AnswersTitleText,
	BackButtonContainer,
	BackButtonText,
	FlagButtonContainer,
	QuestionContainer,
} from './QuestionDetail.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMosaic } from '../../../../../hooks/useMosaic';
import { Icon } from '../../../../../components/Icon';
import { Paginator } from '../../../../../components/Paginator';

export function MosaicQuestionDetail() {
	const [answer, setAnswer] = useState('');
	const { id } = useParams();
	const navigate = useNavigate();
	const {
		currentQuestion: question,
		getQuestionById,
		getAnswersByQuestionId,
		answerPagination: pagination,
		postMosaicAnswer,
		loading,
		answers,
	} = useMosaic();
	useEffect(() => {
		console.log({ question, id });
		if (!question?._id || question?._id !== id) {
			getQuestionById(id);
		}
		if (!answers?.length || answers[0]?.['Associated question'] !== id) {
			getAnswersByQuestionId(id);
		}
	}, [id]);
	return (
		<MosaicContainer>
			<BackButtonContainer
				onClick={() => {
					navigate('/mosaic');
				}}
			>
				<Icon name="arrowLeft" width="1.2rem" />
				<BackButtonText>back</BackButtonText>
			</BackButtonContainer>
			{!!question?._id && (
				<QuestionContainer>
					<QuestionTagsText>{question.Tags?.join(', ')}</QuestionTagsText>
					<QuestionTitle>{question.Title}</QuestionTitle>
					<QuestionDescription>{question.Content}</QuestionDescription>
					<FlagButtonContainer></FlagButtonContainer>
				</QuestionContainer>
			)}
			<AnswerInputContainer>
				<TextArea
					placeholder="Write an answer"
					onChange={setAnswer}
					value={answer}
				/>
				<Button
					onClick={() => {
						postMosaicAnswer(id, answer);
					}}
				>
					Answer
				</Button>
			</AnswerInputContainer>
			{!!answers.length && (
				<AnswerListContainer>
					<AnswersTitleText>
						{answers?.length} Answer{answers?.length > 1 ? 's' : ''}
					</AnswersTitleText>
					{answers?.map((answer) => (
						<AnswerItemContainer>
							<AnswerText>{answer.Text}</AnswerText>
						</AnswerItemContainer>
					))}
					{(pagination.cursor !== 0 || pagination.remaining !== 0) && (
						<Paginator
							currentPage={pagination.cursor / 10 + 1}
							totalPages={Math.ceil(
								(pagination.cursor + pagination.remaining + pagination.count) /
									10,
							)}
							onPageChange={(page) => {
								const newCursor = (page - 1) * 10;

								getAnswersByQuestionId(id, newCursor);
								console.log({ page });
							}}
						/>
					)}
				</AnswerListContainer>
			)}
		</MosaicContainer>
	);
}
