import styled from 'styled-components';
import { Icon } from '../Icon';

export const AccordionStylingContainer = styled.div<{
	backgroundColor?: string;
}>`
	display: flex;
	margin: 20px 0;
	.Collapsible {
		width: 100%;
	}

	.Collapsible__contentOuter {
		width: 100%;
	}

	.Collapsible__contentInner {
		border: 8px solid
			${({
				theme: {
					colors: {
						background: { gray },
						background,
					},
				},
				backgroundColor,
			}) =>
				backgroundColor
					? background[backgroundColor] || backgroundColor
					: gray};
		justify-content: center;
		display: flex;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
	}
`;

export const AccordionTriggerContainer = styled.div<{
	backgroundColor?: string;
	padding?: string;
}>`
	display: flex;
	justify-content: space-between;
	background: ${({
		theme: {
			colors: {
				background: { gray },
				background,
			},
		},
		backgroundColor,
	}) =>
		backgroundColor ? background[backgroundColor] || backgroundColor : gray};
	width: 100%;
	padding: 24px 6px;
	box-sizing: border-box;
	align-items: center;
`;

export const AccordionImage = styled.div<{ src: string }>`
	border-radius: 100%;
	background: black url(${({ src }) => src}) no-repeat center center/cover;
	width: 52px;
	height: 52px;
	min-width: 52px;
	min-height: 52px;

	margin: 0;
	border: 4px solid black;
	box-sizing: border-box;
`;
export const AccordionTriggerTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 8px;
	flex-grow: 1;
`;

export const AccordionTriggerText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 30px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 20px;
		box-sizing: border-box;
		word-wrap: break-word;
		text-align: left;
		flex-grow: 1;
	}
`;

export const AccordionImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 20px;
	max-width: calc(100% - 45px);
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		justify-content: space-between;
		margin: 0 5px;
		flex-grow: 1;
	}
`;

export const AccordionIcon = styled(Icon)`
	&:hover {
		cursor: pointer;
	}
`;

export const AccordionContentContainer = styled.div`
	width: 100%;
	height: auto;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	background: ${({
		theme: {
			colors: {
				background: { white },
			},
		},
	}) => white};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	text-align: justify;
	padding: 5px;
`;

export const AccordionTitle = styled.span``;

export const AccordionList = styled.ul`
	padding: 6px 16px;
`;

export const AccordionListItem = styled.li``;

export const AccordionDescription = styled.p`
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	font-size: 16px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		text-align: center;
	}
`;
