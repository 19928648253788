import styled from 'styled-components';

export const LotteryContainer = styled.div`
	width: 80%;
	margin: 0 auto;
	height: auto;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 2%;
	}
`;

export const Title = styled.span`
	color: transparent;
	background: linear-gradient(
		180deg,
		${({
				theme: {
					colors: { accent },
				},
			}) => accent}
			50%,
		${({
				theme: {
					colors: { primary },
				},
			}) => primary}
			50%
	);
	background-clip: text;
	margin-bottom: 2%;
	-webkit-background-clip: text;
	font-weight: 400;
	font-size: 76px;
	text-transform: uppercase;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 400;
		font-size: 25px;
		line-height: 38px;
		letter-spacing: -0.03em;
	}
`;

export const HorizontalSeparator = styled.div`
	width: 100%;
	height: 4px;
	background: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	box-shadow: 0px 0px 20px
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};
`;

export const WinnersContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 2%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
	}
`;

export const WinnersText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-weight: bold;
	font-size: 30px;
	line-height: 30px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 400;
		font-size: 25px;
		line-height: 38px;
	}
`;

export const WinnerIdsContainer = styled.div`
	height: 100%;
	flex-grow: 2;
	padding: 8px 16px;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	max-width: 60%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		max-width: 100%;
	}
`;

export const WinnerIdItem = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	background: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	font-weight: 700;
	font-size: 16px;
	align-self: center;
	padding: 0.5rem 1rem;
`;

export const RuleDescription = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 30px;
	white-space: pre-line;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;
export const RulesContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1rem;
	padding: 1rem;
`;
