import { StylesConfig } from 'react-select';
import { DesciTheme } from '../../theme';
import { StyledSelect, DropdownContainer } from './Dropdown.styles';
import { useState } from 'react';
export type DropdownOption = { value: any; key?: string; label: string };
export function Dropdown({
	options,
	onChange,
	placeholder,
	value,
	isMulti,
	limit = 3,
}: {
	options: DropdownOption[];
	onChange: (value: any) => void;
	placeholder?: string;
	value?: DropdownOption;
	isMulti?: boolean;
	limit?: number;
}) {
	const [selected, setSelected] = useState<any[]>([]);
	const customStyles: StylesConfig = {
		option: (provided, state) =>
			({
				...provided,
				color: DesciTheme.colors.accent,
				cursor: state.isFocused ? 'pointer' : provided.cursor,
				background:
					state.isFocused || state.isSelected
						? DesciTheme.colors.primary
						: provided.background,
			} as any),
		control: (provided, state) =>
			({
				...provided,
				color: DesciTheme.colors.accent,
				cursor: state.isFocused ? 'pointer' : provided.cursor,
				borderColor: state.isFocused
					? DesciTheme.colors.primary
					: provided.borderColor,

				height: '100%',
				width: '100%',
				boxShadow: state.isFocused
					? `0px 0px 1px ${DesciTheme.colors.primary}`
					: provided.boxShadow,
				'&:hover': {
					borderColor: DesciTheme.colors.primary,
				},
			} as any),
		container: (provided, state) =>
			({
				width: '100%',
				...provided,
			} as any),
	};
	console.log({ placeholder });
	return (
		<DropdownContainer>
			<StyledSelect
				isMulti={!!isMulti}
				defaultValue={value}
				styles={customStyles}
				onChange={(selected) => {
					setSelected(selected as any[]);
					onChange(selected);
				}}
				options={options}
				isOptionDisabled={() => selected.length >= limit}
				placeholder={placeholder || 'Select...'}
			/>
		</DropdownContainer>
	);
}
