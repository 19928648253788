import { SeparatorContainer } from './GreenSeparator.styles';

export function GreenSeparator({
	verticalMargin,
	width,
}: {
	verticalMargin?: string;
	width?: string;
}) {
	return <SeparatorContainer verticalMargin={verticalMargin} width={width} />;
}
