import { Button } from '../../../../../components/Button';
import { useViewport } from '../../../../../utils/viewPortHook';

import {
	TextContainer,
	MainSectionContainer,
	BottomContainer,
	MainText,
	SecondaryText,
	DateText,
	ScrollBottomButton,
	ScrollBottomButtonContainer,
} from './MainSection.styles';
import scrollsymbol from '../../../../../assets/scrollsymbolpink.svg';

export function MainSection({ scrollToRef }: { scrollToRef: any }) {
	const { width } = useViewport();
	const breakpoint = 475;
	const isMobile = width < breakpoint;
	return (
		<MainSectionContainer>
			<TextContainer>
				<SecondaryText
					onClick={() => {
						window.open('', '_blank');
					}}
				>
					World of DeSci: Sofia
				</SecondaryText>
				<MainText>
					Revolutionise your research by harnessing the power of decentralised
					technology.
				</MainText>
				<DateText>
					June 3 - 6, 2023
					<br />
					<a
						style={{ color: '#2775ca' }}
						href="https://goo.gl/maps/pa1LWvPU6UXMFvPX7"
						target="_blank"
						rel="noreferrer"
					>
						Sofia, Bulgaria
					</a>
				</DateText>
			</TextContainer>
			<BottomContainer>
				<Button
					onClick={() => {
						window.open(
							'https://notionforms.io/forms/ticket-applications',
							'_blank',
						);
					}}
					color="accent"
				>
					Apply for tickets
				</Button>
				<Button
					onClick={() => {
						window.open(
							'https://notionforms.io/forms/speakers-applications-1',
							'_blank',
						);
					}}
					color="accent"
				>
					Apply to speak
				</Button>
				<Button
					onClick={() => {
						window.open(
							'https://firebasestorage.googleapis.com/v0/b/desci-5b3ec.appspot.com/o/public%2FSchedule%20Poster%20Sofia.pdf?alt=media',
							'_blank',
						);
					}}
					color="accent"
				>
					Schedule
				</Button>
			</BottomContainer>
			{!isMobile && (
				<ScrollBottomButtonContainer>
					<ScrollBottomButton
						src={scrollsymbol}
						onClick={() => {
							if (scrollToRef.current) {
								scrollToRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
								});
							}
						}}
					/>
				</ScrollBottomButtonContainer>
			)}
		</MainSectionContainer>
	);
}
