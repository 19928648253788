import { useEffect, useState } from 'react';
import {
	MosaicContainer,
	QuestionSearchContainer,
	ResultsContainer,
	QuestionContainer,
	QuestionTagsText,
	QuestionTitle,
	QuestionDescription,
	IconLinkContainer,
	QuestionBottomActionsContainer,
	HalfWidthContainer,
} from './Mosaic.styles';
import { Input } from '../../../../components/Form';
import { Button } from '../../../../components/Button';
import { SmallCardText } from '../../components/NftDisplayCard/NftDisplayCard.styles';

import { Link, useNavigate } from 'react-router-dom';
import { DetailCardContainer } from '../../../../components/DetailCard/DetailCard.styles';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useMosaic } from '../../../../hooks/useMosaic';
import { LoaderContainer } from '../../../../components/Table/Table.styles';
import { Paginator } from '../../../../components/Paginator';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';

export function Mosaic() {
	const [questionSearchInput, setQuestionSearchInput] = useState('');
	const [tag, setTag] = useState(null);
	const [sortOrder, setSortOrder] = useState<string>('desc');
	const {
		searchQuestionsByKeyword,
		loading,
		questions,
		pagination,
		tags,
		getMosaicTags,
	} = useMosaic();
	const navigate = useNavigate();
	const debouncedQuestionSearchInput = useDebounce(questionSearchInput, 500);

	useEffect(() => {
		if (debouncedQuestionSearchInput && !loading) {
			console.log('HEY ', debouncedQuestionSearchInput);
			searchQuestionsByKeyword({
				keyword: debouncedQuestionSearchInput,
				tag,
				sortOrder,
			});
		}
	}, [debouncedQuestionSearchInput]);

	useEffect(() => {
		if (tag !== undefined || sortOrder) {
			searchQuestionsByKeyword({
				keyword: debouncedQuestionSearchInput,
				tag,
				sortOrder,
			});
		}
	}, [tag, sortOrder]);

	useEffect(() => {
		searchQuestionsByKeyword({ keyword: '', sortOrder });
		if (!tags?.length) {
			getMosaicTags();
		}
	}, []);
	console.log({ pagination });
	return (
		<MosaicContainer>
			<QuestionSearchContainer>
				<Button
					onClick={() => {
						navigate('/mosaic/ask');
					}}
				>
					Ask question
				</Button>
			</QuestionSearchContainer>
			<QuestionSearchContainer>
				<Input
					value={questionSearchInput}
					onChange={setQuestionSearchInput}
					placeholder="Search questions by keyword"
				/>
				<Button
					onClick={() => {
						searchQuestionsByKeyword({
							keyword: debouncedQuestionSearchInput,
							tag,
						});
					}}
				>
					Search
				</Button>
			</QuestionSearchContainer>
			<QuestionSearchContainer>
				<HalfWidthContainer>
					<Dropdown
						placeholder="Sort by"
						options={[
							{ label: 'Newest', value: 'desc' },
							{ label: 'Oldest', value: 'asc' },
						]}
						onChange={(opt) => {
							setSortOrder(opt.value);
						}}
						value={{ label: 'Newest', value: 'desc' }}
					/>
					<Dropdown
						placeholder="Select topics"
						options={[
							{ label: 'All', value: null },
							...tags?.map((tag) => ({
								label: tag.Name,
								value: tag.Name,
							})),
						]}
						onChange={(opt) => {
							setTag(opt.value);
						}}
					/>
				</HalfWidthContainer>
			</QuestionSearchContainer>
			<ResultsContainer>
				{loading && <LoaderContainer>loading...</LoaderContainer>}
				{!loading &&
					questions?.map((question) => (
						<DetailCardContainer>
							<QuestionContainer>
								<QuestionTagsText>{question.Tags?.join(', ')}</QuestionTagsText>
								<QuestionTitle
									onClick={() => {
										navigate(`/mosaic/question/${question._id}`);
									}}
								>
									{question.Title}
								</QuestionTitle>
								<QuestionDescription>{question.Content}</QuestionDescription>
								<QuestionBottomActionsContainer>
									<IconLinkContainer>
										<Link to={`/mosaic/question/${question._id}`}>
											<SmallCardText>View answers</SmallCardText>
										</Link>
									</IconLinkContainer>
									<IconLinkContainer>
										<SmallCardText>Flag</SmallCardText>
									</IconLinkContainer>
								</QuestionBottomActionsContainer>
							</QuestionContainer>
						</DetailCardContainer>
					))}
				{!loading && !questions.length && (
					<LoaderContainer>No Results for that search</LoaderContainer>
				)}
				{(pagination.cursor !== 0 || pagination.remaining !== 0) && (
					<Paginator
						currentPage={pagination.cursor / 10 + 1}
						totalPages={Math.ceil(
							(pagination.cursor + pagination.remaining + pagination.count) /
								10,
						)}
						onPageChange={(page) => {
							const newCursor = (page - 1) * 10;

							searchQuestionsByKeyword({
								keyword: debouncedQuestionSearchInput,
								tag,
								cursor: newCursor,
								sortOrder,
							});
							console.log({ page });
						}}
					/>
				)}
			</ResultsContainer>
		</MosaicContainer>
	);
}
