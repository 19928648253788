import { useNavigate } from 'react-router-dom';
import { NFTForDisplay } from '../../types';
import { Button } from '../Button';
import {
	BottomContainer,
	CardContainer,
	CardTextContainer,
	CardTextContainerOffsetBorder,
	DescriptionText,
	ImageOffsetBorder,
	LargeCardText,
	SmallCardText,
	StyledCardImage,
	TextContainer,
	TopTextContainer,
} from './ImageDisplayCard.styles';

export function ImageDisplayCard({
	network,
	tokenSymbol,
	value,
	description,
	link,
	mediaUrl,
	remainingSupply,
	totalSupply,
	buttonText,
	name,
}: NFTForDisplay) {
	function renderSupply(remainingSupply: number, totalSupply: number) {
		if (remainingSupply && totalSupply) {
			return `Minted: ${remainingSupply}/${totalSupply}`;
		} else {
			return `Minted: ${totalSupply || remainingSupply}`;
		}
	}
	const navigate = useNavigate();
	console.log({ description });
	return (
		<CardContainer>
			<ImageOffsetBorder></ImageOffsetBorder>
			<StyledCardImage src={mediaUrl} />
			<CardTextContainerOffsetBorder>
				<CardTextContainer>
					<TextContainer>
						<TopTextContainer>
							<SmallCardText>
								{network ? `Network: ${network}` : ''}
							</SmallCardText>
							<SmallCardText>{tokenSymbol || ''}</SmallCardText>
						</TopTextContainer>
						<TopTextContainer>
							<LargeCardText>{name || ''}</LargeCardText>
							<LargeCardText> {value || ''} </LargeCardText>
						</TopTextContainer>
						<DescriptionText>{description}</DescriptionText>
						<BottomContainer>
							<SmallCardText>
								{renderSupply(remainingSupply as number, totalSupply as number)}
							</SmallCardText>
							<Button
								color="accent"
								onClick={() => {
									if (link.includes('http')) {
										window.open(link, '_blank');
									} else {
										navigate(link);
									}
								}}
							>
								{buttonText}
							</Button>
						</BottomContainer>
					</TextContainer>
				</CardTextContainer>
			</CardTextContainerOffsetBorder>
		</CardContainer>
	);
}
