import styled from 'styled-components';
import { Button } from '../../../../../../components/Button';
import { LogoItem } from '../../../Project/components/NewsSidebar/NewsSidebar.styles';

export const MainSectionContainer = styled.div`
	width: 80%;
	height: 100%;
	box-sizing: border-box;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		margin-top: 12px;
	}
`;

export const LeftContentContainer = styled.div`
	width: 60%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	box-sizing: border-box;
	filter: drop-shadow(8px 8px 20px rgba(0, 48, 63, 0.2));
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const ImageContainer = styled.div<{ url: string }>`
	width: 464px;
	height: 464px;
	background: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
		url,
	}) => `url(${url}) 100% 100%/cover` || softBlack};
	border: 2px solid #ffffff;

	box-sizing: border-box;
	filter: drop-shadow(8px 8px 20px rgba(0, 48, 63, 0.2));
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 330px;
		height: 330px;
	}
`;

export const RightContentContainer = styled.div`
	width: 70%;
	height: 100%;
	display: flex;
	align-items: center;
	background: transparent;
	justify-content: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const NftDescriptionContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	background: transparent;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
`;

export const NetworkName = styled.h5`
	margin: 0;
	box-sizing: border-box;
	font-size: 16px;
	line-height: 18px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
	}
`;

export const NftInfoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0px;
`;

export const NftName = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 6.2vh;
	line-height: 6.3vh;
	letter-spacing: -0.02em;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: bold;
		font-size: 24px;
	}
`;

export const NftPriceContainer = styled.div`
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
`;

export const NftCurrencyText = styled.span`
	font-size: 16px;
	line-height: 30px;
	text-align: right;
	margin: 0;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	opacity: 0.7;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
	}
`;

export const NftPriceText = styled.p`
	font-size: 30px;
	line-height: 30px;
	text-align: right;
	margin: 0;
	letter-spacing: 0.02em;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 20px;
	}
`;

export const NftDescription = styled.span`
	font-size: 16px;
	line-height: 18px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
		line-height: 20px;
	}
`;

export const NftDescriptionFooterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding-top: 30px;
	justify-self: flex-end;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin: 20px 0;
	}
`;

export const NftSuply = styled.div`
	margin: 10px 0;
	flex: 2;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};

	&:span {
		opacity: 0, 7;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
	}
`;

export const MintButton = styled(Button)`
	width: 200px;
	height: 50px;
	align-self: center;
	button {
		padding: 6px;
	}
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin-right: 10px;
	}
`;

export const StyledLogoItem = styled(LogoItem)`
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const MintMultipleContainer = styled.div<{ customHeight?: string }>`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 40px;
	height: ${({ customHeight }) => customHeight || '100%'};
	padding: 1rem;
`;

export const TokenCategoryContainer = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	width: 100%;
	justify-content: center;
	box-sizing: border-box;
	flex-direction: column;
`;

export const TokenNameAndInputContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;
export const TokenCategoryName = styled.span`
	font-size: 1rem;
	display: inline-block;
	width: 90%;
	color: ${({ theme }) => theme.colors.background.softBlack};
`;

export const TokenCategoryDescription = styled.span`
	font-size: 0.8rem;
	display: block;
	width: 100%;
	color: ${({ theme }) => theme.colors.background.softBlack};
`;
