import styled from 'styled-components';
import Bacteria from '../../../../../../assets/third-section-image.png';

export const Container = styled.div`
	width: 75%;
	margin: 0 5%;
	display: flex;
	justify-content: space-around;
	padding: 0 20px;
	margin: 8% auto;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 0;
		flex-direction: row-reverse;
		padding: 0;
	}
`;

export const Image = styled.div`
	width: 399px;
	height: 446px;
	background: url(${Bacteria});
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 199px;
		height: 246px;
	}
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 40%;
`;

export const Title = styled.span`
	font-style: normal;
	margin: 12px 0px;
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
	letter-spacing: -0.02em;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 700;
		font-size: 25px;
		line-height: 38px;
	}
`;

export const Parragraph = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const MobileParragraph = styled.p`
	display: none;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: inline-block;
		margin: 2%;
		font-weight: 400;
		font-size: 15px;
		line-height: 30px;
		color: ${({
			theme: {
				colors: { accent },
			},
		}) => accent};
	}
`;
