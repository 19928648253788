import { useMemo } from 'react';
import { ProposalStatus } from '../../types';
import { Button } from '../Button';
import { Icon } from '../Icon';
import {
	DetailCardDescription,
	DetailCardInnerContainer,
	DetailCardContainer,
	DetailCardContentContainer,
	DetailCardHeader,
	DetailCardHeaderLeftContent,
	DetailCardHeaderRightContent,
	DetailCardLeftContent,
	DetailCardName,
	DetailCardStatusText,
	DetailCardUser,
	DetailCardItemContainer,
	DetailCardRightContent,
	DetailCardTokenInfoContainer,
	DetailCardTokenText,
	DetailCardTokenAmount,
	DetailCardTokenImg,
	DetailCardItemText,
	DetailCardAmountContainer,
} from './DetailCard.styles';
import { DetailCardProps } from './types';

export function DetailCard({
	id,
	title,
	subTitle,
	logoUrl,
	token,
	status,
	amount,
	onClick,
	description,
	icons,
	extraText,
}: Partial<DetailCardProps>) {
	const iconNameByStatus = useMemo(() => {
		switch (status) {
			case ProposalStatus.active:
				return 'roundYellow';
			case ProposalStatus.complete:
				return 'roundGreen';
			case ProposalStatus.proposed:
				return 'roundRed';
		}
	}, [status]);

	return (
		<DetailCardContainer>
			<DetailCardHeader>
				<DetailCardHeaderLeftContent>
					<DetailCardName>{title}</DetailCardName>
					<DetailCardUser>{subTitle}</DetailCardUser>
				</DetailCardHeaderLeftContent>
				<DetailCardHeaderRightContent>
					<DetailCardStatusText>{status}</DetailCardStatusText>
					<Icon width="40px" name={iconNameByStatus} />
				</DetailCardHeaderRightContent>
			</DetailCardHeader>
			<DetailCardContentContainer>
				<DetailCardLeftContent>
					<DetailCardInnerContainer>
						<DetailCardDescription>{description}</DetailCardDescription>
					</DetailCardInnerContainer>
					<DetailCardInnerContainer>
						{icons.map(({ text, iconName }, index) => (
							<DetailCardItemContainer column key={index}>
								<Icon width="50px" name={iconName} />
								<DetailCardItemText>{text}</DetailCardItemText>
							</DetailCardItemContainer>
						))}
					</DetailCardInnerContainer>
				</DetailCardLeftContent>
				<DetailCardRightContent>
					<DetailCardTokenInfoContainer>
						<DetailCardAmountContainer>
							<DetailCardTokenText>{token}</DetailCardTokenText>
							<DetailCardTokenAmount>{amount}</DetailCardTokenAmount>
						</DetailCardAmountContainer>
						<DetailCardTokenImg src={logoUrl} />
					</DetailCardTokenInfoContainer>
					<Button
						onClick={() => {
							// console.log('go to link');
						}}
					>
						Get involved
					</Button>
				</DetailCardRightContent>
			</DetailCardContentContainer>
		</DetailCardContainer>
	);
}
