import {
	HighlightedParagraphContainer,
	HighlightedParagraphTextContainer,
	HighlightedParagraphTitle,
	TitleContainer,
	TitleShape,
} from './HighlightedParagraph.styles';

export function HighlightedParagraph({
	title,
	children,
	color,
	className,
	customBackground,
}: {
	title?: string;
	children: any;
	color?: string;
	className?: string;
	customBackground?: string;
}) {
	return (
		<HighlightedParagraphContainer className={className}>
			<TitleContainer>
				<TitleShape color={color} />
				<HighlightedParagraphTitle>{title}</HighlightedParagraphTitle>
			</TitleContainer>
			<HighlightedParagraphTextContainer
				customBackground={customBackground}
				color={color}
			>
				{children}
			</HighlightedParagraphTextContainer>
		</HighlightedParagraphContainer>
	);
}
