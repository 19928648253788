import styled from 'styled-components';

export const TabContainer = styled.div<{ color?: string; active?: boolean }>`
	background: linear-gradient(
		180deg,
		${({
				theme: {
					colors,
					colors: { primary },
				},
				color,
				active,
			}) =>
				color
					? `${colors[color]}${active ? 'CC' : '50'}`
					: `${primary}${active ? 'CC' : '50'}`}
			0%,
		rgba(206, 253, 92, 0) 100%
	);
	height: 60px;
	min-width: 170px;
	width: 170px;
	display: flex;
	flex-direction: column;
	border-bottom: 4px solid
		${({
			theme: {
				colors,
				colors: { primary },
			},
			color,
		}) => (color ? colors[color] : primary)};
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	justify-content: end;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	${({ active }) =>
		active &&
		`
    box-shadow: 0px 2px 14px -3px #00000050;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
`}
	&:hover {
		cursor: pointer;
	}
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		min-width: 120px;
		width: 100%;
		border-bottom: none;
		font-size: 16px;
		margin-bottom: 40px;
		padding: 20px 0px 0px 0px;
	}
`;

export const ActiveTabTriangle = styled.div<{
	active?: boolean;
	color: string;
}>`
	border-bottom: 16px solid
		${({
			theme: {
				colors,
				colors: { primary },
			},
			color,
			active,
		}) => (!active ? 'transparent' : color ? colors[color] : primary)};
	border-left: 16px solid transparent;
	align-self: end;
	margin-bottom: 0px;
`;
