import { useEffect, useState } from 'react';
import { Title } from '../Token/Token.styles';
import {
	DescriptionText,
	HeaderContainer,
	InputContainer,
	JobsContainer,
	JobsList,
} from './Jobs.styles';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { JobCard } from '../../../../components/DetailCard/JobDetailCard';
import { GreenSeparator } from '../../../../components/GreenSeparator';
import { Input } from '../../../../components/Form';
import { useDebounce } from '../../../../hooks/useDebounce';
import { orderTypes } from '../../../../types';

export function Jobs() {
	const [currentPage, setCurrentPage] = useState(1);
	const {
		job: { jobs, filterJobs },
	} = useAPI();
	const [nameSearch, setNameSearch] = useState(null);
	const debouncedSearch = useDebounce(nameSearch, 500);
	useEffect(() => {
		filterJobs({
			filter: [],

			order: {
				field: 'createdAt',
				type: orderTypes.descending,
			},
			pagination: { page: currentPage, pageSize: 1000 },
		});
	}, [currentPage]);

	useEffect(() => {
		if (!!debouncedSearch) {
			const filter = {
				...(debouncedSearch
					? {
							search: {
								fields: ['title', 'company', 'location'],
								value: debouncedSearch,
							},
					  }
					: {}),
			};
			filterJobs &&
				filterJobs({
					filter: [],
					...filter,
					order: {
						field: 'createdAt',
						type: orderTypes.descending,
					},
					pagination: { page: 1, pageSize: 100 },
				});
		}
	}, [debouncedSearch]);
	return (
		<JobsContainer>
			<HeaderContainer>
				<Title>JOB BOARD</Title>
				<DescriptionText>This is DesciWorld Job board</DescriptionText>
			</HeaderContainer>
			<InputContainer>
				<Input
					value={nameSearch}
					onChange={(value) => {
						setNameSearch(value);
					}}
					placeholder="Search job by title, location or company"
				/>
			</InputContainer>
			<JobsList>
				{jobs?.map((job) => {
					return (
						<>
							<JobCard key={job._id} job={job} />
							<GreenSeparator />
						</>
					);
				})}
			</JobsList>
		</JobsContainer>
	);
}
