import { MainSection } from "./components/MainSection";
import { RequestSection } from "./components/RequestSection";
import { P2PContainer, SectionContainer } from "./P2P.styles";


export function P2P() {
	

	return (
		<P2PContainer>
			<SectionContainer>
				<MainSection />
			</SectionContainer>
			<SectionContainer>
				<RequestSection />
			</SectionContainer>
		</P2PContainer>
	);
}
