import { Accordion } from '../../../../../../components/Accordion';
import { Button } from '../../../../../../components/Button';
import {
	ResearchSectionContainer,
	ResearchCard,
	ResearchCardHeader,
	ResearchCardTitle,
	ResearchListContainer,
	ResearchList,
	ResearchListItem,
	ResearchListItemTitle,
	ResearchListItemDescription,
	DownloadIcon,
	ResearchSectionFooter,
	ButtonContainer,
	NotesContainer,
} from './ResearchSection.styles';

export function ResearchSection() {
	return (
		<ResearchSectionContainer>
			<ResearchCard>
				<ResearchCardHeader>
					<ResearchCardTitle>
						PhD Research of Funghi’s Communication Network
					</ResearchCardTitle>
					<DownloadIcon />
				</ResearchCardHeader>
				<ResearchListContainer>
					<ResearchList>
						{[1, 2, 3].map((item) => (
							<ResearchListItem>
								<Accordion
									title='Hypothesis'
									backgroundColor='white'
									
								>
								<ResearchListItemDescription>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Integer porttitor euismod ligula a consequat. Mauris non porta
									ex. Phasellus pretium tincidunt ornare. Nulla neque nisl,
									auctor vel orci efficitur, facilisis condimentum nunc. Integer
									interdum ex quis ante ullamcorper convallis. Duis dictum
									libero facilisis nulla maximus tempus. Maecenas at tempor
									magna. Donec ex dui, convallis ac varius et, scelerisque a
									magna. Nunc eu convallis leo.
								</ResearchListItemDescription>
								</Accordion>
								</ResearchListItem>
						))}
					</ResearchList>
				</ResearchListContainer>
				<ResearchSectionFooter />
			</ResearchCard>
			<ButtonContainer>
				<Button
					onClick={() => {
						// console.log('go to link');
					}}
				>
					Get involved
				</Button>
			</ButtonContainer>
			<NotesContainer>
				<Accordion title="Notes"><p>Hola</p></Accordion>
			</NotesContainer>
		</ResearchSectionContainer>
	);
}
