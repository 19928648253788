import { useNavigate } from 'react-router-dom';
import {
	DiscordIcon,
	FooterContainer,
	GithubIcon,
	LinkContainer,
	LinkTitle,
	SocialContainer,
	TwitterIcon,
} from './Footer.styles';

export function Footer() {
	const navigate = useNavigate();
	return (
		<FooterContainer>
			<LinkContainer>
				<LinkTitle
					onClick={() => {
						navigate('/contact');
					}}
				>
					Contact
				</LinkTitle>
				<LinkTitle
					onClick={() => {
						navigate('/faqs');
					}}
				>
					FAQs
				</LinkTitle>
				<LinkTitle
					onClick={() => {
						window.open(
							'https://firebasestorage.googleapis.com/v0/b/desci-5b3ec.appspot.com/o/DeSciWorld%20Whitepaper%20(3).pdf?alt=media&token=70573b9d-6236-4b05-83b9-11d1cb97a10a',
							'_blank',
						);
					}}
				>
					Documentation
				</LinkTitle>
			</LinkContainer>
			<SocialContainer>
				<TwitterIcon
					onClick={() => {
						window.open('https://twitter.com/DeSciWorld', '_blank');
					}}
				/>
				<GithubIcon
					onClick={() => {
						window.open('https://github.com/DeSciWorldDAO', '_blank');
					}}
				/>

				<DiscordIcon
					onClick={() => {
						window.open('https://discord.gg/jnEUqVH8xv', '_blank');
					}}
				/>
			</SocialContainer>
		</FooterContainer>
	);
}
