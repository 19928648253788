import Calendar from '@ericz1803/react-google-calendar';
import { EventCalendarContainer, EventSourceText } from './Calendar.styles';
import { DesciTheme } from '../../../../theme';

//put your google calendar api key here
const API_KEY = 'AIzaSyAH_3byRV4MOAz5nbLQJXqxF-f8lJZPCWE';
let calendars = [
	{
		calendarId: '3lql6qf1smr21uaf4kb7hdfg8btdq6v2@import.calendar.google.com',
		color: DesciTheme.colors.secondary,
	},
	{
		calendarId: 'descitwitterspaces@gmail.com',
		color: DesciTheme.colors.primary,
	},
];

let styles = {
	//you can use object styles
	calendar: {
		borderWidth: '3px', //make outer edge of calendar thicker
		background: DesciTheme.colors.background.white,
	},
	multiEvent: {
		background: DesciTheme.colors.primary,
		color: DesciTheme.colors.accent,
		'& div span,& .event-text': {
			color: DesciTheme.colors.accent,
		},
	},
	tooltip: {
		overflowWrap: 'break-word',
		wordBreak: 'break-all',
	},
	eventText: {
		color: DesciTheme.colors.accent,
	},

	today: {
		color: DesciTheme.colors.tertiary,
		border: `1px solid ${DesciTheme.colors.tertiary}`,
	},
};
export function EventCalendar() {
	return (
		<EventCalendarContainer>
			<Calendar apiKey={API_KEY} calendars={calendars} styles={styles} />
			<EventSourceText>
				Information about DeSci events around the world is taken from{' '}
				<a href="https://desci.global" target="_blank" rel="noreferrer">
					desci.global
				</a>
			</EventSourceText>
		</EventCalendarContainer>
	);
}
