import styled from "styled-components";

export const NavigationBarContainer = styled.div`

top: 0;
left: 0;
right: 0;
box-shadow: 0px 3px 15px 5px rgba(0,0,0,0.53);
background:rgba(0,0,0,0.8);
height:40px;
display:flex;
justify-content:center
`