import {
	SortButtonContainer,
	SortButtonContainerClip,
	SortButtonText,
} from './SortButton.styles';
import { SortButtonType } from './types';

export function SortButton({
	text,
	active,
	onClick,
	buttonKey,
	variant,
}: SortButtonType) {
	return (
		<SortButtonContainerClip>
			<SortButtonContainer
				active={active}
				variant={variant}
				onClick={() => {
					onClick && onClick(buttonKey);
				}}
			>
				<SortButtonText>{text} ↓</SortButtonText>
			</SortButtonContainer>
		</SortButtonContainerClip>
	);
}
