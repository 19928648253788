import styled from 'styled-components';
import twitter from '../../../../assets/twitter.svg';
import discord from '../../../../assets/discord.svg';
import lock from '../../../../assets/lock.svg';
import github from '../../../../assets/github.svg';

export const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin-left: 90px; */
	padding: 0 100px;
	background-color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 0;
		padding: 50px;
	}
`;

export const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 40px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const LinkTitle = styled.h5`
	font-style: normal;
	font-weight: normal;
	margin: 5px 0;
	font-size: 16px;
	color: #efefef;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

export const SocialContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		justify-content: center;
	}
`;

export const SocialsIcon = styled.div`
	width: 48px;
	height: 48px;
	margin: 0 20px;
	&:hover {
		cursor: pointer;
	}
`;

export const TwitterIcon = styled(SocialsIcon)`
	background: transparent url(${twitter}) no-repeat center center/cover;
`;


export const GithubIcon = styled(SocialsIcon)`
	background: transparent url(${github}) no-repeat center center/cover;
`;

export const DiscordIcon = styled(SocialsIcon)`
	background: transparent url(${discord}) no-repeat center center/cover;
`;

export const LockIcon = styled.div<{
	unclaimed: boolean;
}>`
	display: ${(props) => (props.unclaimed ? 'block' : 'none')};
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	background: url(${lock});
	width: 24px;
	height: 24px;
	left: 50%;
	margin-left: -50px;
	top: 50%;
`;
