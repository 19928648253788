import styled from 'styled-components';
import { DesciTheme } from '../../theme';

export const CardContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	flex-direction: column;
	align-items: center;
	min-width: 470px;
`;

export const ImageOffsetBorder = styled.div`
	width: 67%;
	min-width: 220px;
	min-height: 120px;
	height: 40%;
	background: transparent;
	box-shadow: 4px 0px 0px
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};
	display: block;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		box-shadow: none;
	}
`;
export const StyledCardImage = styled.div<{ src?: string }>`
	min-width: 286px;
	width: 60%;
	height: auto;
	box-shadow: 8px 8px 20px
		${({
			theme: {
				colors: { accent },
			},
		}) => accent}32;
	position: absolute;
	background: ${({
		theme: {
			colors: {
				background: { lightGray },
			},
		},
		src,
	}) => `url(${src}) 100% 100%/cover` || lightGray};
	top: 8px;
	z-index: 2;
	border: 2px solid white;
	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		min-width: 100px;
		width: 50%;
	}
`;
export const CardTextContainer = styled.div`
	top: 4%;
	left: 4%;
	width: 94%;
	height: 94%;
	background: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	position: relative;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		left: 5%;
		height: 70%;
		width: 90%;
	}
`;

export const TextContainer = styled.div`
	padding: 42% 42px 0px 42px;
	color: black;
	height: 55%;
	display: flex;
	flex-direction: column;

	@media ${({
			theme: {
				device: { large },
			},
		}) => large} {
		padding: 36% 42px 0px 42px;
	}
`;

export const CardTextContainerOffsetBorder = styled.div`
	box-shadow: 4px 4px 0px
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};
	min-width: 476px;
	min-height: 476px;
	width: 35vw;
	height: 35vw;
	max-width: 500px;
	max-height: 500px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		box-shadow: none;
		min-width: 250px;
		width: 350px;
		padding: 20px 0px;
		height: auto;
	}
`;

export const TopTextContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 2px;
`;
export const SmallCardText = styled.span`
	font-size: 16px;
	line-height: 30px;
`;
export const LargeCardText = styled.span`
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 30px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 24px;
	}
`;

export const DescriptionText = styled.span`
	font-family: ${DesciTheme.fonts.standard};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	margin-top: 10px;
	text-align: left;
	display: -webkit-box;
	max-height:92px;
	min-height: 35%;
	@media ${({
			theme: {
				device: { large },
			},
		}) => large} {
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`;

export const BottomContainer = styled.div`
	margin-top: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 0px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: center;
	}
`;
