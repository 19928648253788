import { CommentSectionContainer, CommentsList, CommentsListHeaderContainer, DatePosted, CommentsListHeaderLikes, CommentsListHeaderTitle, CommentsListItem, UserName, CommentsListUserContainer, CommentsListUsernameContainer, WriteAComment } from "./CommentsSection.styles";


export function CommentsSection() {
	return (
		<CommentSectionContainer>
			<CommentsList>
                {[1,2].map((item) => (
                    <CommentsListItem>
                    <CommentsListHeaderContainer>
                        <CommentsListHeaderTitle>Funghi are the best guys!</CommentsListHeaderTitle>
                        <CommentsListHeaderLikes>3 </CommentsListHeaderLikes>
                    </CommentsListHeaderContainer>
                    <CommentsListUserContainer>
                        Icon
                        <CommentsListUsernameContainer>
                            <UserName>Wonton1</UserName>
                            <DatePosted>2 days ago</DatePosted>
                        </CommentsListUsernameContainer>
                    </CommentsListUserContainer>
                </CommentsListItem>
                ))}
                <WriteAComment>write your comment!</WriteAComment>
            </CommentsList>
            
		</CommentSectionContainer>
	);
}
