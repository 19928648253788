import styled from 'styled-components';
import scrollIcon from '../../../../assets/scrollsymbolblack.png';
import BG from '../../../../assets/second-sectionBG.jpg';
import SectionImage from '../../../../assets/second-section-image.png';
export const SecondSectionContainer = styled.div`
width:100%;
height:100%;
background:${({
	theme: {
		colors: { secondary },
	},
}) => secondary} url(${BG}) no-repeat 85% 0%;
display:flex;
/* justify-content:center;*/
align-items:center; 
flex-direction: row;
position:relative;
@media ${({
	theme: {
		device: { mobile },
	},
}) => mobile} {
  flex-direction: column-reverse;
	align-items:flex-start; 
}
}
`;

export const TopScrollIcon = styled.div`
	background: transparent url(${scrollIcon}) no-repeat center center/cover;
	width: 60px;
	height: 100px;
	padding: 2px;

	&:hover {
		cursor: pointer;
	}
`;

export const SecondSectionTextContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 50%;
	flex-direction: column;
	justify-content: center;

	padding-left: 80px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		height: 80%;
		padding: 0px 12px;
		justify-content: flex-start;
	}
`;

export const SecondSectionText = styled.span`
	color: black;
	font-size: 2rem;
	text-align: left;
	padding-bottom: 1rem;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 0;
		font-size: 24px;
	}
`;

export const StyledSectionImage = styled.div`
	width: 25%;
	height: 80%;
	background: transparent url(${SectionImage}) no-repeat 30% 70%;

	@media (min-width: 1740px) {
		background: transparent url(${SectionImage}) no-repeat 50% 70%;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 10px 0;
		width: 100%;
		height: 20%;
		background: transparent url(${SectionImage}) no-repeat 10% 70%;
		background-size: 140px;
	}
`;
