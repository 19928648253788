import type { Tweet as TweetType } from '../../types';
import { TweetContainer, TweetText } from './Tweet.styles';

export function Tweet({ tweet }: { tweet: TweetType }) {
	const linkRegex =
		/(http|ftp|https:\/\/[\w\-_]+\.{1}[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/gi;
	const usernameRegex = /@\w*/gi;
	function WrapAnchorTag(text: string) {
		return text
			.replace(linkRegex, '<a href="$1$2" target="_blank">$1$2</a>')
			.replace(usernameRegex, (match: string) => {
				const username = match.replace('@', '');
				return `<a href="https://twitter.com/${username}" target="_blank">@${username}</a>`;
			});
	}
	return (
		<TweetContainer>
			<TweetText
				dangerouslySetInnerHTML={{ __html: WrapAnchorTag(tweet.text) }}
			></TweetText>
		</TweetContainer>
	);
}
