import styled from 'styled-components';
import BG from '../../../../assets/fourth-sectionBG.jpg';
export const FourthSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({
			theme: {
				colors: {
					background: { gray },
				},
			},
		}) => gray}
		url(${BG}) no-repeat center center/cover;
	display: flex;
	/* justify-content:center;*/
	align-items: center;
	flex-direction: row;
	justify-content: center;
`;

export const NFTContainer = styled.div`
	display: flex;
	width: 55%;
	align-items: center;
	position: relative;
	margin: auto;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		justify-content: center;
	}
`;
