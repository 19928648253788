import { useViewport } from '../../../../../utils/viewPortHook';
import { DecoratedParagraph } from '../../../components/DecoratedParagraph';
import { LandingHighlightedText } from '../../../components/LandingHighlightedText';
import { SecondSectionText } from '../../../components/SecondSection/SecondSection.styles';
import circleburst from '../../../../../assets/circleburst.svg';
import {
	BottomContentContainer,
	DownArrow,
	FloatingCircle,
	IntroSectionContainer,
	StyledSectionImage,
	StyledTopCornerImage,
	TopContentContainer,
} from './IntroSection.styles';
import { AccentText } from '../../SofiaLanding.styles';
import downarrow from '../../../../../assets/down-arrow-pink.svg';
import microbe from '../../../../../assets/microbe.png';
export function IntroSection() {
	const { width } = useViewport();
	const breakpoint = 475;
	const isMobile = width < breakpoint;
	return (
		<IntroSectionContainer>
			{!isMobile && <FloatingCircle src={circleburst} />}
			{!isMobile && <StyledTopCornerImage src={microbe} />}
			{!isMobile && <DownArrow src={downarrow} />}
			<TopContentContainer>
				<SecondSectionText>
					A global conference to{' '}
					<LandingHighlightedText noTransform>inspire</LandingHighlightedText>{' '}
					the next generation of DeScientists
				</SecondSectionText>
				{!isMobile && <StyledSectionImage />}
			</TopContentContainer>
			<BottomContentContainer>
				{!isMobile && (
					<DecoratedParagraph lineCount={isMobile ? 2 : null}>
						We’re gathering the best and brightest in Sofia together with a host
						of projects, founders and thought leaders from the Decentralised
						Science (DeSci) space. We will share, learn and connect in a bid to
						bring DeSci to Sofia.
						<br />
						<br />
						DeSci utilises the Web3 technology stack of decentralised
						blockchains, storage protocols, tokenisation and more to solve some
						of science’s biggest problems. Funding, publishing, incentive
						mechanisms, reproducibility, access concerns and more are being
						tackles right now by the builders of Decentralised Science.
					</DecoratedParagraph>
				)}
				{isMobile && (
					<AccentText>
						We’re gathering the best and brightest in Sofia together with a host
						of projects, founders and thought leaders from the Decentralised
						Science (DeSci) space. We will share, learn and connect in a bid to
						bring DeSci to Sofia.
						<br />
						<br />
						DeSci utilises the Web3 technology stack of decentralised
						blockchains, storage protocols, tokenisation and more to solve some
						of science’s biggest problems. Funding, publishing, incentive
						mechanisms, reproducibility, access concerns and more are being
						tackles right now by the builders of Decentralised Science.
					</AccentText>
				)}
			</BottomContentContainer>
		</IntroSectionContainer>
	);
}
