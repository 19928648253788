import { StyledArrowLeft, StyledArrowRight, StyledLine, StyledSeparator } from "./SlimSeparatorHorizontal";

export function SlimSeparatorHorizontal() {
  return (
    <StyledSeparator>
        <StyledArrowLeft/>
      <StyledLine />
      
      <StyledArrowRight/>
    </StyledSeparator>
  );
}
