import styled from 'styled-components';
export const SpeakerSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({ theme: { colors } }) => colors.secondary};
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	padding: 2rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: flex-start;
	}
`;
export const Title = styled.span`
	font-weight: bolder;
	font-size: 3rem;
	line-height: 3rem;
	color: ${({ theme: { colors } }) => colors.softBlack};
	letter-spacing: 0.02em;
`;

export const SpeakersListContainer = styled.div`
	display: flex;
	padding: 2.5rem;
	flex-direction: row;
	justify-content: left;
	align-items: left;
	flex-wrap: wrap;
	row-gap: 2rem;
	width: 100%;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: center;
	}
`;

export const SpeakerItem = styled.div`
	display: flex;
	flex: 1 0 21%; /* explanation below */
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
`;

export const SpeakerImage = styled.img<{ url: string }>`
	border-radius: 100%;
	background: black url(${({ url }) => url}) no-repeat center center/cover;
	width: 160px;
	height: 160px;
	margin: auto;
	border: 4px solid black;
	justify-content: center;
	&:hover {
		cursor: pointer;
	}
`;
export const SpeakerName = styled.span<{ color?: string }>`
	font-weight: bolder;
	font-size: 1.5rem;
	line-height: 1.5rem;
	text-align: center;
	color: ${({ theme: { colors }, color }) =>
		color ? colors[color] : '#454A75'};
`;
