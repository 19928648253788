import styled from 'styled-components';

import globe from '../../../../../assets/globe.png';
export const TextContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	align-content: space-around;
	align-items: center;
	height: 70%;
	width: 70%;
	background: transparent url(${globe}) no-repeat 50% 50% / contain;
	margin-top: 1rem;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		height: 75%;
		width: 80%;
	}
`;

export const MainText = styled.span`
	font-weight: bold;
	font-size: 2.5rem;
	line-height: 4rem;
	letter-spacing: 0.02em;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 2rem;
	}
	text-align: center;
`;

export const SecondaryText = styled.span`
	font-weight: 700;
	font-size: 2rem;
	line-height: 30px;
	text-align: center;
	color: #7b61ff;
	&:hover {
		text-decoration: underline;
	}
	cursor: pointer;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 1.8rem;
	}
`;

export const DateText = styled.span`
	font-weight: bolder;
	font-size: 1.8rem;
	line-height: 30px;
	text-align: center;
	color: #2775ca;
	a {
		&:hover {
			text-decoration: underline;
		}
	}
`;

export const MainSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({ theme: { colors } }) => colors.secondary};
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: column;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		background-size: 520px;
	}
`;

export const BottomContainer = styled.div`
	display: flex;
	align-items: center;
	height: 20%;
	width: 100%;
	justify-content: center;
	gap: 1rem;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		padding: 1rem;
		height: 25%;
		box-sizing: border-box;
	}
`;

export const ScrollBottomButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;
	width: 100%;
	height: 10%;
	max-height: 10rem;
	margin-top: auto;
`;

export const DiscoverText = styled.span`
	font-size: 16px;
	font-size: 16px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	margin-bottom: 12px;
`;

export const ScrollBottomButton = styled.img`
	width: 51px;
	height: 65px;
	@media (max-height: 1150px) {
		width: 92px;
	}

	&:hover {
		cursor: pointer;
	}
`;
