import { useEffect } from 'react';
import { ImageDisplayCard } from '../../../../components/ImageDisplayCard';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { operators } from '../../../../types';
import { useViewport } from '../../../../utils/viewPortHook';
import { FeaturedContracts } from '../../components/Featured';
import { Features } from '../../components/Features';
import {
	NftContainer,
	NftPageContainer,
	NftSpotlight,
	SectionContainer,
} from './NftMain.styles';

export function NftMain() {
	const {
		contract: {
			filterContracts,
			contracts,
			contract: spotlightContract,
			getSpotlightContract,
			loading,
		},
		loading: overallLoading,
	} = useAPI();
	const { width } = useViewport();
	const breakpoint = 475;
	useEffect(() => {
		getSpotlightContract();
		filterContracts({
			filter: [{ field: 'spotlight', value: true, operator: operators.not }],
		});
	}, []);
	console.log({ overallLoading, loading, spotlightContract });
	return (
		<NftPageContainer loading={overallLoading || loading}>
			{loading || (overallLoading && 'Loading...')}
			{!loading && !overallLoading && (
				<>
					{/* <NftSubtitle>
				Full NFT market on{' '}
				<NftSubtitleHighlighted>NFTKEY.app ↗</NftSubtitleHighlighted>{' '}
			</NftSubtitle> */}
					<SectionContainer customHeight="  calc(97vh - 40px)">
						<NftContainer>
							{width > breakpoint && <NftSpotlight>NFT Spotlight</NftSpotlight>}

							{spotlightContract?._id && (
								<ImageDisplayCard
									name={spotlightContract.name}
									mediaUrl={spotlightContract.mediaUrl}
									link={`/nft/${
										spotlightContract.name ||
										spotlightContract.openseaCollectionSlug ||
										spotlightContract._id
									}`}
									network="Ethereum"
									tokenSymbol={spotlightContract.dynamicPrice ? '' : 'eth'}
									value={spotlightContract.value}
									totalSupply={spotlightContract.totalSupply}
									buttonText="Mint"
									description={spotlightContract.description}
								/>
							)}
						</NftContainer>
					</SectionContainer>
					<SectionContainer
						customHeight="calc(100vh - 40px)"
						mobileHeight="auto"
						background="linear-gradient(180deg, rgba(206, 253, 92, 0) 0%, rgba(117, 251, 76, 0.4) 0.01%, rgba(206, 253, 92, 0) 100%);"
					>
						<Features
							title="NFTs: What and why?"
							features={[
								{
									title: 'Verifiable Ownership',
									description: `NFTs represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. NFTs can only have one official owner at a time and they're secured by the blockchain – no one can modify the record of ownership or copy/paste a new NFT into existence.`,
								},
								{
									title: 'Creative Representation',
									description: `Research produces unexpected beauty that many dont appreciate. NFTs allow creativity to flourish in the research space as visual data can be presented alongside raw research data in a single encapsulated package. `,
								},
								{
									title: 'Strategic Utilities',
									description: `The growth of DeFi has presented creators with a plethora of utilities to apply to NFTs. Protocol governance, retroactive airdrops, NFT-gated VIP communities and even yield farming are all possible using the power of NFTs and blockchain.`,
								},
							]}
						/>
					</SectionContainer>
					{!!contracts?.length && (
						<SectionContainer
							customHeight="calc(100vh - 40px)"
							mobileHeight="auto"
						>
							<FeaturedContracts
								title="More collections"
								contracts={contracts}
								fallbackText={'coming soon, stay tuned...'}
								loading={loading}
							/>
						</SectionContainer>
					)}
				</>
			)}
		</NftPageContainer>
	);
}
