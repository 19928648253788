const colors = {
	primary: '#75FB4C',
	secondary: '#CEFD5C',
	tertiary: '#F761CC',
	accent: '#00303F',
	softBlack: '#152022',
	white: '#FAFDFD',
	background: {
		default: '#fbfdfc',
		dark: '#00303f',
		gray: '#A8C0C3',
		lightGray: '#C4C4C4',
		softBlack: '#152022',
		darkBlack: '#051208',
		white: '#FAFDFD',
	},
	dark: {
		primary: '#207f02',
		secondary: '#608701',
		tertiary: '#82065f',
		accent: '#001319',
	},
	light: {
		primary: '#c8fdb8',
		secondary: '#ebfebf',
		tertiary: '#fbc1eb',
		accent: '#87c3ff',
	},
	// background:"#fbfdfc",
};

const borderRadius = {
	standard: '6px',
	rounded: '50%',
};

const fonts = {
	standard: 'Space Mono',
	paragraph: 'Space Grotesk',
};
const breakpoints = {
	mobile: 475,
	tablet: 768,
	small: 1280,
	large: 1920,
	desktop: 2560,
};
const device = {
	mobile: '(max-width: 475px)',
	tablet: '(max-width: 768px)',
	small: '(max-width: 1280px)',
	large: '(max-width: 1920px)',
	desktop: '(max-width: 2560px)',
};

export const DesciTheme = {
	name: 'DeSciWorld',
	fonts,
	colors,
	borderRadius,
	breakpoints,
	device,
};
