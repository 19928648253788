import styled from 'styled-components';

export const SixthSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({
		theme: {
			colors: { secondary },
		},
	}) => secondary};
	display: flex;
	/* justify-content:center;*/
	align-items: center;
	flex-direction: column;
`;

export const SixthSectionTextContainer = styled.div`
	height: 20%;
	align-self: baseline;
	width: 50%;
	text-align: left;
	margin-left: 100px;
	margin-bottom: 38px;
	flex-direction: column;
	display: flex;
	align-items: baseline;
	justify-content: flex-end;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 0px;
		padding: 8px;
		width: 100%;
		box-sizing: border-box;
	}
`;

export const SixthSectionTitle = styled.span`
	color: black;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 30px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 20px;
	}
`;

export const SixthSectionSubtitle = styled.span`
	color: black;
	display: block;
	font-style: italic;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 12px;
	}
`;

export const CarouselContainer = styled.div`
	width: 100%;
`;
