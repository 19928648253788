import styled from 'styled-components';

export const MosaicContainer = styled.div`
	width: 80%;
	margin: 0 auto;
	height: max-content;
	padding: 5%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 2%;
	}
`;

export const QuestionSearchContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 1rem;
`;

export const HalfWidthContainer = styled.div`
	width: 50%;
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 1rem;
`;
export const ResultsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	color: ${({ theme }) => theme.colors.accent};
	margin-top: 2rem;
`;

export const QuestionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	background-color: ${({ theme }) => theme.colors.background.default};
	border: 1px solid ${({ theme }) => theme.colors.primary};
`;
export const QuestionTagsText = styled.span`
	font-weight: bold;
	font-size: 0.8rem;
	color: ${({ theme }) => theme.colors.tertiary};
`;
export const QuestionTitle = styled.span`
	font-weight: bolder;
	font-size: 1.05rem;
	cursor: pointer;
`;
export const QuestionDescription = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
`;
export const QuestionLink = styled.a``;
export const QuestionBottomActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;
export const IconLinkContainer = styled.div``;
