import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)<{active:boolean}>`
display:flex;
margin:8px 14px;
color:${({theme:{colors:{primary}}})=>primary};
text-decoration:none;
border-bottom:${({theme:{colors:{primary}},active})=> active ? `2px solid ${primary}`:'none'};
&:hover{
    opacity:0.6;
   
}
`