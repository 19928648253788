import {
	NoteCardDecoration,
	NoteCardLink,
	NoteCardText,
	RightArrow,
	ShadowContainer,
} from '../NoteCard.styles';
import {
	BottomContainer,
	TopContainer,
	VerticalNoteCardContainer,
	VerticalNoteCardContentContainer,
	VerticalNoteCardImage,
	VerticalNoteCardTitle,
	VerticalNoteCardTitleContainer,
} from './VerticalNoteCard.styles';

export function VerticalNoteCard({
	image,
	title,
	subtitle,
	text,
	link,
}: {
	subtitle?: string;
	link?: string;
	image?: string;
	title?: string;
	text: string;
}) {
	return (
		<VerticalNoteCardContainer>
			<NoteCardDecoration />
			<ShadowContainer>
				<VerticalNoteCardContentContainer>
					<TopContainer>
						{image && <VerticalNoteCardImage src={image} />}
						<RightArrow />
						<RightArrow />
						<RightArrow />
					</TopContainer>
					<BottomContainer>
						{title && (
							<VerticalNoteCardTitleContainer>
								<VerticalNoteCardTitle>{title}</VerticalNoteCardTitle>
							</VerticalNoteCardTitleContainer>
						)}
						<NoteCardText>
							{text}
							{link ? (
								<NoteCardLink href={link} target="_blank" rel="noreferrer">
									read more ↗
								</NoteCardLink>
							) : (
								''
							)}
						</NoteCardText>
					</BottomContainer>
				</VerticalNoteCardContentContainer>
			</ShadowContainer>
		</VerticalNoteCardContainer>
	);
}
