import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import wallet from '../../../../assets/icons/wallet.png';
import { Icon } from '../../../../components/Icon';

export const NavBarContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
`;
export const TabContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;
export const ButtonContainer = styled.div`
	display: flex;
	justify-content: end;
	width: 100%;
	padding: 0px 42px;
	box-sizing: border-box;
	padding: 12px 32px;
	gap: 16px;
`;

export const InlineIcon = styled(Icon)`
	display: inline-block;
`;

export const IconTextContainer = styled.div`
	justify-content: center;
	align-content: center;
	align-items: center;
	display: flex;
	gap: 0.5rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column-reverse;
	}
`;

export const ConnectWalletButton = styled(Button)`
	width: 200px;
	height: 50px;
	align-self: center;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 10rem;
		height: 4rem;
		margin-right: 10px;
	}
`;

export const ConnectWalletIcon = styled.div`
	width: 80px;
	height: 38px;
	background: transparent url(${wallet}) no-repeat center center/cover;
	align-self: center;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 40px;
		height: 40px;
		margin-right: 20px;
	}
`;
