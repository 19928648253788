import { PaginatorContainer, PaginatorNumber } from './Paginator.styles';

export function Paginator({
	currentPage,
	totalPages,
	onPageChange,
}: {
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
}) {
	const paginatorStart = Math.max(1, currentPage - 2);
	const paginatorEnd = Math.min(totalPages, currentPage + 2);
	const pages = Array.from(
		{
			length: paginatorEnd - paginatorStart + 1,
		},
		(_, i) => i + paginatorStart,
	);
	return (
		<PaginatorContainer>
			{pages.map((page) => (
				<PaginatorNumber
					key={page}
					onClick={() => {
						onPageChange(page);
					}}
					$isCurrent={currentPage === page}
				>
					{page}
				</PaginatorNumber>
			))}
			{paginatorEnd < totalPages && <span>...</span>}
			{currentPage < totalPages - 2 && (
				<PaginatorNumber
					onClick={() => {
						onPageChange(totalPages);
					}}
					$isCurrent={currentPage === totalPages}
				>
					{totalPages}
				</PaginatorNumber>
			)}
		</PaginatorContainer>
	);
}
