import styled from "styled-components";
import { Button } from "../../../../components/Button";
import { DesciTheme } from "../../../../theme";

export const TokenContainer = styled.div`
	width: 80%;
	margin: 2rem 3rem auto auto;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		margin: auto;
	}
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
`

const BANNER_COLORS:{
    bg:{[key:string]:string },
    border:{[key:string]:string},
    font:{[key:string]:string}
} = {
    bg:{
        success:DesciTheme.colors.light.primary,
        error:DesciTheme.colors.light.tertiary,
        warning:DesciTheme.colors.secondary,
    },
    border:{
        success:DesciTheme.colors.primary,
        error:DesciTheme.colors.tertiary,
        warning:DesciTheme.colors.background.lightGray,

    },
    font:{
    success:DesciTheme.colors.dark.primary,
    error:DesciTheme.colors.dark.tertiary,
    warning:DesciTheme.colors.accent,

    }
}

export const QualificationBanner = styled.div<{type:string}>`
background: ${({ type }) => BANNER_COLORS.bg[type] };
color: ${({type}) => BANNER_COLORS.font[type]};
border: solid 2px ${({ type }) => BANNER_COLORS.border[type] };
display:flex;
box-sizing:border-box;
justify-content:center;
padding:1rem;
border-radius:1rem;
width:80%;
justify-self:center;
@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
        margin-top:1rem;
		font-size: 1rem;
		line-height: 2.5rem;
		text-align: center;
		letter-spacing: -0.03em;
        padding:0rem;
        border-radius:0.8rem;
        width:100%;
	}

`

export const Title = styled.h1`
font-style: normal;
	font-size: 4rem;
	line-height: 5rem;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 400;
		font-size: 2rem;
		line-height: 2.5rem;
		text-align: center;
		letter-spacing: -0.03em;
	}
`

export const Subtitle = styled.span``
    
export const Description = styled.span`
	font-style: normal;
	font-size: 1.2rem;
	line-height: 2.5rem;
    font-weight: 400;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
    @media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 1rem;
		line-height: 1.8rem;
		text-align: center;
	}

`

export const ClaimButtonContainer = styled.div`
display:flex;
width:100%;
justify-content:center;
box-sizing:border-box;
padding:1rem;

`
    
export const ClaimButton = styled(Button)``
    