import styled from 'styled-components';
import abstractBG from '../../../../assets/abstractBG.png';
import scrollsymbol from '../../../../assets/scrollsymbol.svg';
export const MainSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: black url(${abstractBG}) no-repeat center center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		background-size: 520px;
	}
`;

export const TextContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	align-content: space-around;
	align-items: center;
	height: 75%;
	width: 60%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 80%;
	}
`;

export const HeaderText = styled.span`
	font-size: 36px;
	color: ${({
		theme: {
			colors: { secondary },
		},
	}) => secondary};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const MainText = styled.span`
	font-size: 42px;
	line-height: 48px;
	width: 95%;
	justify-content: center;
	text-align: center;
	color: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	/* background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 1) 0%,
		${({
		theme: {
			colors: { primary },
		},
	}) => primary}
			25%,
		${({
		theme: {
			colors: { primary },
		},
	}) => primary}
			75%,
		rgba(0, 0, 0, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 30px;
	}
`;

export const DescriptionText = styled.span`
	text-align: center;
	font-size: 16px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		text-align: center;
	}
`;

export const LineBreak = styled.br`
	@media ${({
			theme: {
				device: { desktop },
			},
		}) => desktop} {
		display: none;
	}
`;

export const BottomContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 25%;
`;

export const DiscoverText = styled.span`
	font-size: 16px;
	font-size: 16px;
	color: ${({
		theme: {
			colors: { secondary },
		},
	}) => secondary};

	margin-bottom: 12px;
`;

export const ScrollBottomButton = styled.div`
	background: transparent url(${scrollsymbol}) no-repeat center center/cover;
	width: 10rem;
	height: 100%;
	padding: 2px;

	@media (max-height: 1150px) {
		width: 92px;
	}

	&:hover {
		cursor: pointer;
	}
`;
