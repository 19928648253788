import styled from 'styled-components';

export const ContactContainer = styled.div`
	width: 80%;
	margin: 0 auto;
	height: max-content;
	padding: 5%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 2%;
	}
`;

export const Title = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 76px;
	line-height: 76px;
	width: 100%;
	margin: 40px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 400;
		font-size: 25px;
		line-height: 38px;
		margin: 4%;

		letter-spacing: -0.03em;
	}
`;

export const MainSectionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		flex-direction: column;
	}
`;

export const Description = styled.span`
	width: 40%;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	align-self: flex-start;
	justify-self: center;
	line-height: 20px 30px;
	padding: 10px 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		align-self: center;
		justify-self: center;
		padding: 30px 10px;
		width: 90%;
	}
`;

export const FormContainer = styled.form`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	.submit-btn {
		align-self: flex-end !important;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		margin: 0 10px;

		.submit-btn {
			align-self: center !important;
		}
	}
`;

export const FormLabel = styled.label`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	margin: 0 10px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const FormInput = styled.input`
	width: 100%;
	height: 44px;
	padding: 4px;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	font-family: ${({
		theme: {
			fonts: { standard },
		},
	}) => standard};
	margin-bottom: 10px;
	&:focus {
		border: 1px solid
			${({
				theme: {
					colors: { accent },
				},
			}) => accent} !important;
	}
`;

export const FormTextArea = styled.textarea`
	width: 100%;
	height: 260px;
	padding: 6px;
	font-weight: 400;
	font-family: ${({
		theme: {
			fonts: { standard },
		},
	}) => standard};
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 20px;
	&:focus {
		border: 1px solid
			${({
				theme: {
					colors: { accent },
				},
			}) => accent} !important;
	}
`;

export const DropdownContainer = styled.div`
	height: 56px;
	width: 100%;
`;
