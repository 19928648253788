import styled from 'styled-components';

export const FeaturedCollectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	margin: 0 14%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		background: #cefd5c;
		margin: 0px;
		padding: 2px 12px;
		box-sizing: border-box;
	}
`;

export const FeaturedCollectionTitle = styled.span`
	font-weight: normal;
	font-size: 48px;
	line-height: 48px;
	align-self: flex-start;
	letter-spacing: -0.02em;
	margin: 12px 0px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 26px;
		text-align: center;
		line-height: 30px;
		color: #585a5e;
	}
`;

export const CarouselContainer = styled.div`
	width: 100%;
	align-self: center;
	display: flex;
	justify-content: flex-end;
	margin-left: 24px;
	box-sizing: border-box;
	flex-direction: column;
`;

export const MobileSwipeText = styled.span`
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	padding: 16px;
	line-height: 16px;
	align-self: center;
	text-decoration: underline;
`;

export const NftDisplayCardContainer = styled.div`
	width: 80%;
`;
