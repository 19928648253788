import styled from 'styled-components';
import { NavigationBar } from '../../components/NavigationBar';
export const ScrollableToSection = styled.section<{
	fullHeight?: boolean;
	customHeight?: string;
	mobileHeight?: string;
}>`
	height: ${({ fullHeight, customHeight }) =>
		customHeight ? customHeight : fullHeight ? '100vh' : 'auto'};
	scroll-margin-top: 3rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		height: ${({ fullHeight, mobileHeight }) =>
			mobileHeight ? mobileHeight : fullHeight ? '100vh' : 'auto'};
	}
	/* background:teal; */
`;

export const NavBar = styled(NavigationBar)``;

export const NavLink = styled.div``;
