import styled from 'styled-components';
import ad from '../../../../../../assets/ad-banner.jpeg';

export const SeparatorContainer = styled.div`
	height: 120px;
	background: url(${ad}) no-repeat 100% / cover;
	margin: 0 auto;
	box-sizing: border-box;
	width: 100%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		background-size: 125%;
	}
`;
