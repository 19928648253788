import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Burger } from '../../../../components/BurgerButton';
import { Menu } from '../../../../components/Menu';
import { useOnClickOutside } from '../../../../utils/closeMenuHook';
import { useViewport } from '../../../../utils/viewPortHook';
import {
	BurgerMenuContainer,
	LaunchAppButton,
	NavBarContainer,
	NavLink,
	NavLinkContainer,
	NavLogo,
} from './NavBar.styles';

export function NavBar({
	routes,
}: {
	routes?: { title: string; onClick: () => void }[];
}) {
	const [open, setOpen] = useState(false);
	let navigate = useNavigate();
	const node = useRef();

	const { width } = useViewport();
	const breakpoint = 475;
	useOnClickOutside(node, () => setOpen(false));

	return (
		<NavBarContainer>
			{width < breakpoint ? (
				<>
					<BurgerMenuContainer ref={node}>
						<Burger open={open} setOpen={setOpen} />
						<Menu open={open}>
							{routes?.map((route, index) => (
								<NavLink key={index} onClick={route.onClick}>
									{route.title}
								</NavLink>
							))}
						</Menu>
					</BurgerMenuContainer>
					<NavLogo />
					<LaunchAppButton onClick={() => navigate('nft')}>App</LaunchAppButton>
				</>
			) : (
				<>
					<NavLogo
						onClick={() => {
							navigate('/');
						}}
					/>
					<NavLinkContainer>
						{routes?.map((route, index) => (
							<NavLink key={index} onClick={route.onClick}>
								{route.title}
							</NavLink>
						))}
					</NavLinkContainer>
					<LaunchAppButton
						onClick={() => {
							window.scrollTo({ top: 0 });
							navigate('/dashboard', { replace: true });
						}}
					>
						Launch App
					</LaunchAppButton>
				</>
			)}
		</NavBarContainer>
	);
}
