import styled from 'styled-components';

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 40px auto;
`;
export const ListSectionContainer = styled.div`
	padding: 50px;
	flex-direction: column;
	display: flex;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		padding: 10px 0px;
	}
`;
export const ListTopSectionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem 0px;
	gap: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column-reverse;
		gap: 2rem;
	}
`;
export const InputContainer = styled.div`
	display: flex;
	gap: 8px;
	width: 100%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column-reverse;
	}
`;
export const DropdownContainer = styled.div`
	width: 25%;
	display: flex;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const ListContainer = styled.div`
	width: 100%;
	flex-direction: column;
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;
`;
