import styled from 'styled-components';

export const TweetContainer = styled.div`
	display: flex;
	padding: 20px;
	flex-wrap: wrap;
`;
export const TweetText = styled.span`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: ${({
		theme: {
			colors: { softBlack },
		},
	}) => softBlack};
`;
