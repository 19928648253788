import {
	SpeakerImage,
	SpeakerItem,
	SpeakerName,
	SpeakerSectionContainer,
	SpeakersListContainer,
	Title,
} from './SpeakersSection.styles';

const speakers = [
	{
		name: 'Cristina Castro',
		organization: 'ChimiaDAO, Oxford',
		image: 'https://i.imgur.com/numMLY0.jpg',
		link: 'https://www.linkedin.com/in/cristina-castro-3425751a5/',
	},
	{
		name: 'Oksana Stanevich',
		organization: 'GOSH',
		image: 'https://i.imgur.com/Anuo9QI.jpg',
		link: 'https://www.linkedin.com/in/oksana-stanevich-39557b22b',
	},
	{
		name: 'Joshua Bate',
		organization: 'DeSciWorld',
		image: 'https://i.imgur.com/UtatSc1.jpg',
		link: 'twitter.com/jb87ua',
	},
	{
		name: 'Chris Byrnes',
		image: 'https://i.imgur.com/lZZPiPG.jpg',
		organization: 'Molecule',
		link: 'https://twitter.com/reimagineIP/',
	},
	{
		name: 'Alexey Migitko',
		image: 'https://i.imgur.com/HKK9guy.jpg',
		organization: 'Unique VCs',
		link: 'https://twitter.com/AlexMigitko',
	},
	{
		name: 'Antonio Peric-Mazar',
		image: 'https://i.imgur.com/ACACPzQ.jpg',
		organization: 'Locastic',
		link: 'https://www.linkedin.com/in/antonioperic/',
	},
	{
		name: 'Vlaho Hrdalo',
		image: 'https://i.imgur.com/sWvxxxp.jpg',
		organization: 'Legal',
		link: 'https://www.linkedin.com/in/vlaho-hrdalo',
	},
	{
		name: 'Kevin Jones',
		image: 'https://i.imgur.com/DNk8fmv.jpg',
		organization: 'Buidl Guidl',
		link: 'https://twitter.com/cryptomastery_',
	},
	{
		name: 'Filip Milenkovic',
		image: 'https://i.imgur.com/cwguwrl.jpg',
		organization: 'DeSci Belgrade',
		link: 'https://filipneverstops.com/',
	},
	{
		name: 'Cathie So',
		image: 'https://i.imgur.com/TScrYcG.jpg',
		organization: 'Ethereum Foundation',
		link: 'https://twitter.com/drCathieSo_eth',
	},
];
export function SpeakerSection() {
	return (
		<SpeakerSectionContainer>
			<Title>Confirmed Speakers</Title>
			<SpeakersListContainer>
				{speakers.map((speaker) => (
					<SpeakerItem>
						<SpeakerImage
							onClick={() => {
								window.open(speaker.link, '_blank');
							}}
							url={speaker.image}
						/>
						<SpeakerName color="softBlack">{speaker.name}</SpeakerName>
						<SpeakerName>{speaker.organization}</SpeakerName>
					</SpeakerItem>
				))}
			</SpeakersListContainer>
		</SpeakerSectionContainer>
	);
}
