import { EntityNames } from '../constants/api';

export type PrizeMediaType = {
	name: string;
	mediaUrl: string;
	url?: string;
};
export type RouteType = {
	title: string;
	path: string;
};

/**Web3 types */

/** api types */
export enum operators {
	greaterThan = 'gt',
	greaterThanEquals = 'gte',
	lesserThan = 'lt',
	lesserThanEquals = 'lte',
	not = 'not',
	regex = 'regex',
	exists = 'exists',
}

export enum orderTypes {
	ascending = 'asc',
	descending = 'desc',
}

export enum conjunctions {
	or = 'OR',
	and = 'AND',
}

export type filterObject = {
	field: string;
	value: any; // type this pending
	operator?: operators;
	not?: boolean;
};

export type searchObject = {
	value: string;
	fields: string[];
};

export type orderObject = {
	field: string;
	type: orderTypes;
};

export type paginationObject = {
	page: number;
	pageSize: number;
};

export type populateObject = {
	field: string;
	select?: string[];
	populate?: populateObject;
	path?: string;
};
export type FilterOptions = {
	filter: filterObject[];
	search?: searchObject;
	order?: orderObject;
	pagination?: paginationObject;
	populate?: populateObject[];
	select?: string[];
};

export type EntityNameObject = {
	plural: string;
	singular: string;
};

export type OrganizationStateType = {
	loading: boolean;
	error: any;
	response: {
		status: number;
		message: string;
		data: any;
		organizations: Organization[];
		organization: Organization;
	};
	organizations: Organization[];
	organization: Organization;
};
export type LotteryStateType = {
	loading: boolean;
	error: any;
	response: {
		status: number;
		message: string;
		data: any;
		lotteries: Lottery[];
		lottery: Lottery;
	};
	lotteries: Lottery[];
	lottery: Lottery;
};

export type MemberStateType = {
	loading: boolean;
	error: any;
	response: {
		status: number;
		message: string;
		data: any;
		members?: Member[];
		member?: Member;
	};
	members?: Member[];
	member?: Member;
};
export type ProposalStateType = {
	loading: boolean;
	error: any;
	response: {
		status: number;
		message: string;
		data: any;
		proposal?: Proposal;
		proposals?: Proposal[];
	};
	proposal: Proposal;
	proposals: Proposal[];
};

export type JobStateType = {
	loading: boolean;
	error: any;
	response: {
		status: number;
		message: string;
		data: any;
		job?: Job;
		jobs?: Job[];
	};
	job: Job;
	jobs: Job[];
};

export type MintContractStateType = {
	loading: boolean;
	error: any;
	response: {
		status: number;
		message: string;
		data: any;
		contract?: MintContract;
		contracts?: MintContract[];
	};
	contract: MintContract;
	contracts: MintContract[];
};

export type AuthStateType = {
	loading: boolean;
	error: any;
	response: {
		status: number;
		message: string;
		data: any;
		user?: Proposal;
	};
	user: Member;
};

export type APIStateType = {
	organization: OrganizationStateType;
	proposal: ProposalStateType;
	job: JobStateType;
	member: MemberStateType;
	auth: AuthStateType;
	contract: MintContractStateType;
	lottery: LotteryStateType;
};

export enum operationName {
	filter = 'filter',
	create = 'create',
	delete = 'delete',
	getById = 'getById',
	login = 'login',
	logout = 'logout',
	clear = 'clear',
	toggleLoading = 'toggleLoading',
	getCurrent = 'getCurrent',
}

export type APIActionType = {
	entity?: EntityNames;
	operation: operationName;
	payload?: { [key: string]: any };
};

export type Tweet = {
	id: string;
	text: string;
};

/**entity types  */

export enum ProposalStatus {
	proposed = 'Proposed',
	active = 'Active',
	complete = 'Complete',
}

export enum ProposalGroupType {
	individual = 'Individual',
	sponsor = 'Sponsor',
	consultation = 'Consultation',
}
export enum galleryItemSource {
	youtube = 'youtube',
	image = 'image',
}

export type galleryItem = {
	title: string;
	mediaUrl: string;
	description?: string;
	name: string;
	source: galleryItemSource;
	link: string;
};

export type Proposal = {
	_id: string;
	name: string;
	address: string;
	reputation: number;
	category: string;
	token: string;
	tokenIconUrl: string;
	amount: number;
	tbd: boolean;
	inEscrow: number;
	rangeMin?: number;
	rangeMax?: number;
	status: ProposalStatus;
	description: string;
	createdAt: string | Date;
	timescale: string;
	groupType: ProposalGroupType;
	gallery: galleryItem[]; // or mediaType[]
	notes: string[]; // markdown syntax
	results: string; // default "To be published"
	deleted: boolean;
};

export enum DAOOperatingSystem {
	aragon = 'Aragon',
	daohaus = 'DAOHAUS',
}

export type Organization = {
	_id: string;
	name: string;
	tokenSymbol: string;
	DaoOS: DAOOperatingSystem;
	address?: string;
	description: string;
	whitePaperLink: string;
	activeDAOMembers?: number; // if through third party remove
	gallery: galleryItem[];
	deleted: boolean;
	logo?: string;
	primaryColor: string;
	twitterHandle?: string;
	coingeckoId: string;
	snapshotId: string;
	website?: string;
	discordLink?: string;
	telegramLink: string;
	callToActionLink?: string;
	tokenDescription?: string;
	tokenTradeLink?: string;
	tags?: OrganizationCategory[];
	unclaimed?: boolean;
	squareLogo?: string;
	displayName?: string;
};

export type ContactEmail = {
	name: string;
	email: string;
	message: string;
};

export type Member = {
	_id: string;
	name?: string;
	address: string;
	description?: string;
	deleted?: boolean;
	nonce: string;
};

export type UniqueFeature = {
	title: string;
	description: string;
	mediaUrl?: string;
	subtitle?: string;
};

export type Token = {
	name: string;
	id: number;
	price: string;
};
export enum MintContractType {
	unique = 'unique',
	batchMintUnique = 'batchMintUnique',
	allocation = 'allocation',
	mintByCategory = 'mintByCategory',
	multiToken = 'multiToken',
}
export type TokenCategory = {
	id: number;
	name: string;
	description: string;
	price: number;
};

export type MintContract = {
	_id?: string;
	name: string;
	address: string;
	description?: string;
	spotlight?: boolean;
	openseaCollectionSlug?: string;
	openSeaCollectionLink?: string;
	mintMethodName: string;
	uniqueFeatures: UniqueFeature[];
	abi: any;
	value: string;
	totalSupply?: number;
	mediaUrl?: string;
	featuredTokenIds?: string[];
	multiToken?: boolean;
	tokens?: Token[];
	dynamicPrice?: boolean;
	getPriceMethodName?: string;
	batchMintUnique?: boolean;
	maxMintPerTx?: number;
	type?: MintContractType;
	whitelistedAddresses?: string[];
	categories?: TokenCategory[];
	chainId: number;
};
export type Job = {
	_id: string;
	title: string;
	logoUrl: string;
	contractType: string;
	status: ProposalStatus;
	description: string;
	createdAt: Date;
	location: string;
	compensation: string;
	deleted: boolean;
	applyLink: string;
	company: string;
};

export enum LotteryStatus {
	open = 'open',
	closed = 'closed',
	canceled = 'canceled',
}

export enum OrganizationCategory {
	ecosystems = 'Ecosystems',
	Publishing = 'Publishing',
	Biopharma = 'Biopharma',
	focusedResearch = 'Focused Research',
	NFT = 'NFTs',
	philanthropy = 'Philantropy',
	defi = 'Science DeFi',
	dataStorage = 'Data Storage',
	blockchainProtocols = 'Blockchain protocols',
	decentralizedBiotech = 'Decentralized biotech',
	funding = 'Decentralized funding',
	decentralizedComputing = 'Decentralized computing',
}

export enum PrizeType {
	token = 'token',
	external = 'external',
	coin = 'coin',
}

export type Prize = {
	type: PrizeType;
	tokenId?: string;
	openseaCollectionSlug?: string;
	mediaUrl?: string;
	coingeckoId?: string;
	amount?: number;
	description?: string;
	name?: string;
	openseaCollectionAddress?: string;
	link?: string;
	symbol?: string;
};

export type Lottery = {
	_id?: string;
	drawDate: string;
	name: string;
	description: string;
	winnerIds: string[];
	deleted: boolean;
	external: boolean;
	rules: string;
	eligibility: string;
	status: LotteryStatus;
	prizes: Prize[];

	// lottery contract fields
	contractAddress: string;
	contractAbi: any;
	contractMethodName: string;
	isMultipleWinners: boolean;
	numberOfWinners: number;
	numberOfPlayers: number;
	players?: string[];
	customPlayers?: boolean;
};

export type Whitelist = {
	name: string;
	addresses: string[];
	description: string;
	deleted: boolean;
	contract: string;
	method: string;
	allocation?: { [key: string]: number };
	magicNumber: number;
	abi: any;
};

/**component types */

export type NFTForDisplay = {
	network?: string;
	tokenSymbol?: string;
	value?: string;
	description?: string;
	link?: string;
	remainingSupply?: number;
	totalSupply?: number;
	buttonText?: string;
	name?: string;
	mediaUrl?: string;
};

/**opensea types  */

export type OpenSeaAsset = {
	id: number;
	num_sales: number;
	background_color?: null;
	image_url: string;
	image_preview_url: string;
	image_thumbnail_url: string;
	image_original_url: string;
	animation_url: string;
	animation_original_url: string;
	name: string;
	description: string;
	external_link?: null;
	asset_contract: OpenSeaPrimaryAssetContractsEntityOrAssetContract;
	permalink: string;
	collection: OpenSeaCollection;
	decimals: number;
	token_metadata: string;
	owner: OpenSeaOwner;
	sell_orders?: null;
	creator: OpenSeaCreator;
	traits?: OpenSeaTraitsEntity[] | null;
	last_sale?: {
		total_price: string;
		payment_token: {
			symbol: string;
		};
	};
	top_bid?: null;
	listing_date?: null;
	is_presale: boolean;
	transfer_fee_payment_token?: null;
	transfer_fee?: null;
	related_assets?: null[] | null;
	orders?: null[] | null;
	auctions?: null[] | null;
	supports_wyvern: boolean;
	top_ownerships?: OpenSeaTopOwnershipsEntity[] | null;
	ownership?: null;
	highest_buyer_commitment?: null;
	token_id: string;
};
export type OpenSeaPrimaryAssetContractsEntityOrAssetContract = {
	address: string;
	asset_contract_type: string;
	created_date: string;
	name: string;
	nft_version: string;
	opensea_version?: null;
	owner: number;
	schema_name: string;
	symbol: string;
	total_supply: string;
	description: string;
	external_link: string;
	image_url: string;
	default_to_fiat: boolean;
	dev_buyer_fee_basis_points: number;
	dev_seller_fee_basis_points: number;
	only_proxied_transfers: boolean;
	opensea_buyer_fee_basis_points: number;
	opensea_seller_fee_basis_points: number;
	buyer_fee_basis_points: number;
	seller_fee_basis_points: number;
	payout_address: string;
};

export type OpenSeaPaymentTokensEntity = {
	id: number;
	symbol: string;
	address: string;
	image_url: string;
	name: string;
	decimals: number;
	eth_price: number;
	usd_price: number;
};
export type OpenSeaTraits = {
	[key: string]: any;
};
export type OpenSeaStats = {
	one_day_volume: number;
	one_day_change: number;
	one_day_sales: number;
	one_day_average_price: number;
	seven_day_volume: number;
	seven_day_change: number;
	seven_day_sales: number;
	seven_day_average_price: number;
	thirty_day_volume: number;
	thirty_day_change: number;
	thirty_day_sales: number;
	thirty_day_average_price: number;
	total_volume: number;
	total_sales: number;
	total_supply: number;
	count: number;
	num_owners: number;
	average_price: number;
	num_reports: number;
	market_cap: number;
	floor_price: number;
};
export type OpenSeaDisplayData = {
	card_display_style: string;
};
export type OpenSeaOwner = {
	user: OpenSeaUser;
	profile_img_url: string;
	address: string;
	config: string;
};
export type OpenSeaUser = {
	username: string;
};
export type OpenSeaCreator = {
	user: OpenSeaUser;
	profile_img_url: string;
	address: string;
	config: string;
};
export type OpenSeaTraitsEntity = {
	trait_type: string;
	value: string;
	display_type?: null;
	max_value?: null;
	trait_count: number;
	order?: null;
};
export type OpenSeaTopOwnershipsEntity = {
	owner: OpenSeaOwner;
	quantity: string;
};

export type OpenSeaCollection = {
	editors?: string[] | null;
	payment_tokens?: PaymentTokensEntity[] | null;
	primary_asset_contracts?: PrimaryAssetContractsEntity[] | null;
	stats: OpenSeaStats;
	banner_image_url: string;
	chat_url?: null;
	created_date: string;
	default_to_fiat: boolean;
	description: string;
	dev_buyer_fee_basis_points: string;
	dev_seller_fee_basis_points: string;
	discord_url: string;
	external_url: string;
	featured: boolean;
	featured_image_url: string;
	hidden: boolean;
	safelist_request_status: string;
	image_url: string;
	is_subject_to_whitelist: boolean;
	large_image_url: string;
	medium_username: string;
	name: string;
	only_proxied_transfers: boolean;
	opensea_buyer_fee_basis_points: string;
	opensea_seller_fee_basis_points: string;
	payout_address: string;
	require_email: boolean;
	short_description?: null;
	slug: string;
	telegram_url?: null;
	twitter_username: string;
	instagram_username?: null;
	wiki_url?: null;
};
export type PaymentTokensEntity = {
	id: number;
	symbol: string;
	address: string;
	image_url: string;
	name: string;
	decimals: number;
	eth_price: number;
	usd_price: number;
};
export type PrimaryAssetContractsEntity = {
	address: string;
	asset_contract_type: string;
	created_date: string;
	name: string;
	nft_version: string;
	opensea_version?: null;
	owner: number;
	schema_name: string;
	symbol: string;
	total_supply: string;
	description: string;
	external_link: string;
	image_url: string;
	default_to_fiat: boolean;
	dev_buyer_fee_basis_points: number;
	dev_seller_fee_basis_points: number;
	only_proxied_transfers: boolean;
	opensea_buyer_fee_basis_points: number;
	opensea_seller_fee_basis_points: number;
	buyer_fee_basis_points: number;
	seller_fee_basis_points: number;
	payout_address: string;
};

export type CollectionInfo = {
	count: number;
	totalSupply: number;
	name: string;
	mediaUrl: string;
	description: string;
	address: string;
	slug: string;
	assetUrl: string;
	url: string;
};

/** SNAPSHOT TYPES */

export type SnapshotSpace = {
	id: string;
	followersCount: number;
	proposalsCount: number;
};

export type SnapshotProposal = {
	id: string;
	snapshot: string;
	title: string;
	state: string;
	body: string;
	link?: string;
};

/**GITCOIN TYPES */

/**
 * Mosaic types
 */

export type MosaicQuestion = {
	'Modified Date': string;
	'Created Date': string;
	Content: string;
	Title: string;
	Tags: string[];
	Solved: string;
	Flag: number;
	Solutions: string[];
	'Latest activity': string;
	Status: string;
	_id: string;
};

export type MosaicAnswer = {
	'Modified Date': string;
	'Created Date': string;
	'Created By': string;
	'Associated question': string;
	Text: string;
	'Best answer': string;
	Flag: number;
	'Vote count': number;
	Status: string;
	_id: string;
};

export type MosaicTag = {
	'Modified Date': string;
	'Created Date': string;
	'Created By': string;
	Name: string;
	Client: string[];
	_id: string;
};

export type MosaicResponse = {
	cursor: 0;
	results: any[];
	count: 100;
	remaining: 62;
};
