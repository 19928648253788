import styled from 'styled-components';
import { Button } from '../../../../components/Button';

export const AboutContainer = styled.div`
	width: 100%;
	
`;

export const SectionContainer = styled.section<{
	fullHeight?: boolean;
	customHeight?: string;
	mobileHeight?: string;
	background?: string;
	margin?: string;
}>`
	height: ${({ fullHeight, customHeight }) =>
		customHeight ? customHeight : fullHeight ? '100vh' : 'auto'};
	background: ${({ background }) => background};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-bottom: ${({ margin }) => margin};
		height: ${({ fullHeight, mobileHeight }) =>
			mobileHeight ? mobileHeight : fullHeight ? '100vh' : 'auto'};
	}
`;

export const MobileButton = styled(Button)`
	display: none;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: block;
		align-self: center;
		margin: 6% auto;
		width: 90%;
		text-transform: none !important;
	}
`;
