import axios from 'axios';
import { useReducer } from 'react';
import { createContext, ReactElement, useContext, useState } from 'react';

import {
	APIStateType,
	AuthStateType,
	FilterOptions,
	Member,
	MemberStateType,
	MintContract,
	MintContractStateType,
	operationName,
	Organization,
	OrganizationStateType,
	Proposal,
	ProposalStateType,
	ContactEmail,
	Whitelist,
	Lottery,
	LotteryStateType,
	Job,
	JobStateType,
} from '../types';
import {
	AuthReducer,
	ContractReducer,
	MemberReducer,
	OrganizationReducer,
	ProposalReducer,
	LotteryReducer,
	JobReducer,
} from '../utils/entityReducers';

const testing = false;

const TEST_API_URL = 'http://localhost:3000/';

const API_URL = 'https://desciworldapi.herokuapp.com/';

const apiUrl = testing ? TEST_API_URL : API_URL;

const initialState: APIStateType = {
	organization: {
		loading: false,
		error: null,
		response: null,
		organizations: [],
		organization: null,
	},
	lottery: {
		loading: false,
		error: null,
		response: null,
		lotteries: [],
		lottery: null,
	},
	proposal: {
		loading: false,
		error: null,
		response: null,
		proposals: [],
		proposal: null,
	},

	job: {
		loading: false,
		error: null,
		response: null,
		jobs: [],
		job: null,
	},

	member: {
		loading: false,
		error: null,
		response: null,
		members: [],
		member: null,
	},
	contract: {
		loading: false,
		error: null,
		response: null,
		contracts: [],
		contract: null,
	},
	auth: {
		loading: false,
		error: null,
		response: null,
		user: null,
	},
};

export function useAPIState() {
	const [member, updateMemberState] = useReducer(
		MemberReducer,
		initialState.member,
	);

	const [proposal, updateProposalState] = useReducer(
		ProposalReducer,
		initialState.proposal,
	);

	const [job, updateJobState] = useReducer(JobReducer, initialState.job);

	const [organization, updateOrganizationState] = useReducer(
		OrganizationReducer,
		initialState.organization,
	);
	const [lottery, updateLotteryState] = useReducer(
		LotteryReducer,
		initialState.lottery,
	);
	const [contract, updateContractState] = useReducer(
		ContractReducer,
		initialState.contract,
	);

	const [auth, updateAuthState] = useReducer(AuthReducer, initialState.auth);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);

	/** Member */
	async function getMemberById(id: string) {
		try {
			setLoading(true);
			updateMemberState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { member },
			} = await axios.get(`${apiUrl}member/${id}`);
			updateMemberState({
				operation: operationName.getById,
				payload: { member, response: data },
			});
			setResponse(data);
			setLoading(false);
			return member;
		} catch (error) {
			updateMemberState({
				operation: operationName.getById,
				payload: { error },
			});
			setError(error);
		}
	}
	async function createMember(memberToCreate: Member) {
		try {
			setLoading(true);
			updateMemberState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { member },
			} = await axios.post(`${apiUrl}member`, { member: memberToCreate });
			updateMemberState({
				operation: operationName.create,
				payload: { member, response: data },
			});
			setResponse(data);
			setLoading(false);
			return member;
		} catch (error) {
			updateMemberState({
				operation: operationName.create,
				payload: { error },
			});
			setError(error);
		}
	}
	async function filterMembers(filterObject: FilterOptions) {
		try {
			setLoading(true);
			updateMemberState({ operation: operationName.toggleLoading });
			const filterString = JSON.stringify(filterObject);
			const {
				data,
				data: { members },
			} = await axios.get(`${apiUrl}member?filter=${filterString}`);
			updateMemberState({
				operation: operationName.filter,
				payload: { members, response: data },
			});
			setResponse(data);
			setLoading(false);
			return members;
		} catch (error) {
			updateMemberState({
				operation: operationName.filter,
				payload: { error },
			});
			setError(error);
		}
	}
	async function deleteMember(id: string) {
		try {
			setLoading(true);
			updateMemberState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { member },
			} = await axios.get(`${apiUrl}member/${id}`);
			updateMemberState({
				operation: operationName.delete,
				payload: { member, response: data },
			});
			setResponse(data);
			setLoading(false);
			return member;
		} catch (error) {
			updateMemberState({
				operation: operationName.delete,
				payload: { error },
			});
			setError(error);
		}
	}

	/** Email */

	async function sendContactDetail(emailData: ContactEmail) {
		try {
			setLoading(true);
			const { data } = await axios.post(`${apiUrl}emailsender`, emailData);

			setLoading(false);
			console.log(data);
			return data;
		} catch (error) {
			setError(error);
		}
	}

	/** Organization */
	async function getOrganizationById(id: string) {
		try {
			setLoading(true);
			updateOrganizationState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { organization },
			} = await axios.get(`${apiUrl}organization/${id}`);
			updateOrganizationState({
				operation: operationName.getById,
				payload: { organization, response: data },
			});
			setResponse(data);
			setLoading(false);
			return organization;
		} catch (error) {
			updateOrganizationState({
				operation: operationName.getById,
				payload: { error },
			});
			setError(error);
		}
	}

	function clearOrganization() {
		try {
			setLoading(true);
			updateOrganizationState({ operation: operationName.toggleLoading });
			updateOrganizationState({ operation: operationName.clear });
			setLoading(false);
		} catch (e) {
			setError(e);
		}
	}
	async function createOrganization(organizationToCreate: Organization) {
		try {
			setLoading(true);
			updateOrganizationState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { organization },
			} = await axios.post(`${apiUrl}organization`, {
				organization: organizationToCreate,
			});
			updateOrganizationState({
				operation: operationName.create,
				payload: { organization, response: data },
			});
			setResponse(data);
			setLoading(false);
			return organization;
		} catch (error) {
			updateOrganizationState({
				operation: operationName.create,
				payload: { error },
			});
			setError(error);
		}
	}
	async function filterOrganizations(filterObject: FilterOptions) {
		try {
			setLoading(true);
			updateOrganizationState({ operation: operationName.toggleLoading });
			const filterString = JSON.stringify(filterObject);
			const {
				data,
				data: { organizations },
			} = await axios.get(`${apiUrl}organization?filter=${filterString}`);
			updateOrganizationState({
				operation: operationName.filter,
				payload: { organizations, response: data },
			});
			setResponse(data);
			setLoading(false);
			return organizations;
		} catch (error) {
			updateOrganizationState({
				operation: operationName.filter,
				payload: { error },
			});
			setError(error);
		}
	}
	async function deleteOrganization(id: string) {
		try {
			setLoading(true);
			updateOrganizationState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { organization },
			} = await axios.get(`${apiUrl}organization/${id}`);
			updateOrganizationState({
				operation: operationName.delete,
				payload: { organization, response: data },
			});
			setResponse(data);
			setLoading(false);
			return organization;
		} catch (error) {
			updateOrganizationState({
				operation: operationName.delete,
				payload: { error },
			});
			setError(error);
		}
	}

	/** Proposal */
	async function getProposalById(id: string) {
		try {
			setLoading(true);
			updateProposalState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { proposal },
			} = await axios.get(`${apiUrl}proposal/${id}`);
			updateProposalState({
				operation: operationName.getById,
				payload: { proposal, response: data },
			});
			setResponse(data);
			setLoading(false);
			return proposal;
		} catch (error) {
			updateProposalState({
				operation: operationName.getById,
				payload: { error },
			});
			setError(error);
		}
	}
	async function createProposal(proposalToCreate: Proposal) {
		try {
			setLoading(true);
			updateProposalState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { proposal },
			} = await axios.post(`${apiUrl}proposal`, {
				proposal: proposalToCreate,
			});
			updateProposalState({
				operation: operationName.create,
				payload: { proposal, response: data },
			});
			setResponse(data);
			setLoading(false);
			return proposal;
		} catch (error) {
			updateProposalState({
				operation: operationName.create,
				payload: { error },
			});
			setError(error);
		}
	}
	async function filterProposals(filterObject: FilterOptions) {
		try {
			setLoading(true);
			updateProposalState({ operation: operationName.toggleLoading });
			const filterString = JSON.stringify(filterObject);
			const {
				data,
				data: { proposals },
			} = await axios.get(`${apiUrl}proposal?filter=${filterString}`);
			updateProposalState({
				operation: operationName.filter,
				payload: { proposals, response: data },
			});
			setResponse(data);
			setLoading(false);
			return proposals;
		} catch (error) {
			updateProposalState({
				operation: operationName.filter,
				payload: { error },
			});
			setError(error);
		}
	}
	async function deleteProposal(id: string) {
		try {
			setLoading(true);
			updateProposalState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { proposal },
			} = await axios.get(`${apiUrl}proposal/${id}`);
			updateProposalState({
				operation: operationName.delete,
				payload: { proposal, response: data },
			});
			setResponse(data);
			setLoading(false);
			return proposal;
		} catch (error) {
			updateProposalState({
				operation: operationName.delete,
				payload: { error },
			});
			setError(error);
		}
	}

	/** Contract */
	async function getContractById(id: string) {
		try {
			setLoading(true);
			updateContractState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { contract },
			} = await axios.get(`${apiUrl}contract/${id}`);
			updateContractState({
				operation: operationName.getById,
				payload: { contract, response: data },
			});
			setResponse(data);
			setLoading(false);
			return contract;
		} catch (error) {
			updateContractState({
				operation: operationName.getById,
				payload: { error },
			});
			setError(error);
		}
	}

	async function getSpotlightContract() {
		try {
			setLoading(true);
			updateContractState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { contract },
			} = await axios.get(`${apiUrl}contract/spotlight`);
			updateContractState({
				operation: operationName.getById,
				payload: { contract, response: data },
			});
			setResponse(data);
			setLoading(false);
			return contract;
		} catch (error) {
			updateContractState({
				operation: operationName.getById,
				payload: { error },
			});
			setError(error);
		}
	}
	async function filterContracts(filterObject: FilterOptions) {
		try {
			setLoading(true);
			updateContractState({ operation: operationName.toggleLoading });
			const filterString = JSON.stringify(filterObject);
			const {
				data,
				data: { contracts },
			} = await axios.get(`${apiUrl}contract?filter=${filterString}`);
			updateContractState({
				operation: operationName.filter,
				payload: { contracts, response: data },
			});
			setResponse(data);
			setLoading(false);
			return contracts;
		} catch (error) {
			updateContractState({
				operation: operationName.filter,
				payload: { error },
			});
			setError(error);
		}
	}
	async function deleteContract(id: string) {
		try {
			setLoading(true);
			updateContractState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { contract },
			} = await axios.get(`${apiUrl}contract/${id}`);
			updateContractState({
				operation: operationName.delete,
				payload: { contract, response: data },
			});
			setResponse(data);
			setLoading(false);
			return contract;
		} catch (error) {
			updateContractState({
				operation: operationName.delete,
				payload: { error },
			});
			setError(error);
		}
	}
	/** WHITELIST */
	async function checkWhitelistForToken(
		address: string,
		id: string = '63af41dea7b9bc40ae788318',
	) {
		try {
			setLoading(true);
			const {
				data,
				data: { whitelisted },
			} = await axios.get(`${apiUrl}whitelist/${id}/${address}`);
			setResponse(data);
			setLoading(false);
			return whitelisted;
		} catch (e) {
			setError(error);
		}
	}

	async function getTokenWhitelistById(
		id: string = '63af41dea7b9bc40ae788318',
	) {
		try {
			setLoading(true);
			const {
				data,
				data: { whitelist },
			} = await axios.get(`${apiUrl}whitelist/${id}`);
			setResponse(data);
			setLoading(false);
			return whitelist;
		} catch (e) {
			setError(error);
		}
	}

	/**AUTH */
	async function login(login: string, address: string) {
		try {
			setLoading(true);
			updateAuthState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { member },
			} = await axios.post(`${apiUrl}auth/login`, { login, address });
			updateAuthState({
				operation: operationName.login,
				payload: { member, response: data },
			});
			setResponse(data);
			setLoading(false);
			return member;
		} catch (error) {
			updateOrganizationState({
				operation: operationName.login,
				payload: { error },
			});
			setError(error);
		}
	}

	async function logout() {
		updateAuthState({ operation: operationName.toggleLoading });
	}

	/** Lottery */

	function clearLottery() {
		try {
			setLoading(true);
			updateLotteryState({ operation: operationName.toggleLoading });
			updateLotteryState({ operation: operationName.clear });
			setLoading(false);
		} catch (e) {
			setError(e);
		}
	}
	async function getCurrentLottery() {
		try {
			setLoading(true);
			updateLotteryState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { lottery },
			} = await axios.get(`${apiUrl}lottery/current`);
			updateLotteryState({
				operation: operationName.getCurrent,
				payload: { lottery, response: data },
			});
			setResponse(data);
			setLoading(false);
			return lottery;
		} catch (error) {
			updateLotteryState({
				operation: operationName.getCurrent,
				payload: { error },
			});
			setError(error);
		}
	}

	/** Job */
	async function getJobById(id: string) {
		try {
			setLoading(true);
			updateJobState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { job },
			} = await axios.get(`${apiUrl}job/${id}`);
			updateJobState({
				operation: operationName.getById,
				payload: { job, response: data },
			});
			setResponse(data);
			setLoading(false);
			return job;
		} catch (error) {
			updateJobState({
				operation: operationName.getById,
				payload: { error },
			});
			setError(error);
		}
	}
	async function createJob(jobToCreate: Job) {
		try {
			setLoading(true);
			updateJobState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { job },
			} = await axios.post(`${apiUrl}job`, {
				job: jobToCreate,
			});
			updateJobState({
				operation: operationName.create,
				payload: { job, response: data },
			});
			setResponse(data);
			setLoading(false);
			return job;
		} catch (error) {
			updateJobState({
				operation: operationName.create,
				payload: { error },
			});
			setError(error);
		}
	}
	async function filterJobs(filterObject: FilterOptions) {
		try {
			setLoading(true);
			updateJobState({ operation: operationName.toggleLoading });
			const filterString = JSON.stringify(filterObject);
			const {
				data,
				data: { jobs },
			} = await axios.get(`${apiUrl}job?filter=${filterString}`);
			console.log({ data });
			updateJobState({
				operation: operationName.filter,
				payload: { jobs, response: data },
			});
			setResponse(data);
			setLoading(false);
			return jobs;
		} catch (error) {
			updateJobState({
				operation: operationName.filter,
				payload: { error },
			});
			setError(error);
		}
	}
	async function deleteJob(id: string) {
		try {
			setLoading(true);
			updateJobState({ operation: operationName.toggleLoading });
			const {
				data,
				data: { job },
			} = await axios.get(`${apiUrl}job/${id}`);
			updateJobState({
				operation: operationName.delete,
				payload: { job, response: data },
			});
			setResponse(data);
			setLoading(false);
			return job;
		} catch (error) {
			updateJobState({
				operation: operationName.delete,
				payload: { error },
			});
			setError(error);
		}
	}

	return {
		member: {
			...member,
			getMemberById,
			createMember,
			filterMembers,
			deleteMember,
		},
		proposal: {
			...proposal,
			getProposalById,
			createProposal,
			filterProposals,
			deleteProposal,
		},
		job: {
			...job,
			getJobById,
			createJob,
			filterJobs,
			deleteJob,
		},
		contactForm: {
			sendContactDetail,
		},
		organization: {
			...organization,
			clearOrganization,
			getOrganizationById,
			createOrganization,
			filterOrganizations,
			deleteOrganization,
		},
		contract: {
			...contract,
			getContractById,
			getSpotlightContract,
			filterContracts,
			deleteContract,
		},
		auth: {
			...auth,
			login,
			logout,
		},
		lottery: {
			...lottery,
			clearLottery,
			getCurrentLottery,
		},
		loading,
		error,
		response,
		checkWhitelistForToken,
		getTokenWhitelistById,
	};
}

export const DesciWorldAPIContext: React.Context<{
	error: any;
	loading: boolean;
	response: any;
	member: MemberStateType & {
		getMemberById: (id: string) => Promise<Member>;
		createMember: (member: Member) => Promise<Member>;
		filterMembers: (filterObject: FilterOptions) => Promise<Member[]>;
		deleteMember: (id: string) => Promise<Member>;
	};
	contactForm: any;
	proposal: ProposalStateType & {
		getProposalById: (id: string) => Promise<Proposal>;
		createProposal: (proposal: Proposal) => Promise<Proposal>;
		filterProposals: (filterObject: FilterOptions) => Promise<Proposal[]>;
		deleteProposal: (id: string) => Promise<Proposal>;
	};
	job: JobStateType & {
		getJobById: (id: string) => Promise<Job>;
		createJob: (job: Job) => Promise<Job>;
		filterJobs: (filterObject: FilterOptions) => Promise<Job[]>;
		deleteJob: (id: string) => Promise<Job>;
	};
	organization: OrganizationStateType & {
		clearOrganization: () => void;
		getOrganizationById: (id: string) => Promise<Organization>;
		createOrganization: (organization: Organization) => Promise<Organization>;
		filterOrganizations: (
			filterObject: FilterOptions,
		) => Promise<Organization[]>;
		deleteOrganization: (id: string) => Promise<Organization>;
	};
	contract: MintContractStateType & {
		getSpotlightContract: () => Promise<MintContract>;
		getContractById: (id: string) => Promise<MintContract>;
		createContract: (contract: MintContract) => Promise<MintContract>;
		filterContracts: (filterObject: FilterOptions) => Promise<MintContract[]>;
		deleteContract: (id: string) => Promise<MintContract>;
	};
	auth: AuthStateType & {
		login: (login: string, address: string) => Promise<Member>;
		logout: () => void;
	};
	lottery: LotteryStateType & {
		clearLottery: () => void;
		getCurrentLottery: () => Promise<Lottery>;
	};
	checkWhitelistForToken: (address: string, id?: string) => Promise<boolean>;
	getTokenWhitelistById: (id?: string) => Promise<Whitelist>;
}> = createContext({} as any);

export function APIProvider({ children }: { children?: ReactElement<any> }) {
	const openSea = useAPIState();

	return (
		<DesciWorldAPIContext.Provider value={openSea}>
			{children}
		</DesciWorldAPIContext.Provider>
	);
}

export function useAPI() {
	return useContext(DesciWorldAPIContext);
}
