import { useNavigate } from 'react-router';
import {
	ResourceSection,
	ResourcesContainer,
	ListItemText,
} from './Resources.styles';
import { Title } from '../About/components/MainSection/MainSection.styles';
import { Link } from 'react-router-dom';

export function Resources() {
	const navigate = useNavigate();

	return (
		<ResourcesContainer>
			<ResourceSection>
				<Title>World Of Desci</Title>

				<ListItemText>
					<Link to="/belgrade">DeSciWorld - Belgrade</Link>
				</ListItemText>
			</ResourceSection>{' '}
			<ResourceSection>
				<Title>Media</Title>
				<ListItemText>Spotify</ListItemText>
				<ListItemText>Twitter</ListItemText>
				<ListItemText>Tiktok</ListItemText>
			</ResourceSection>
		</ResourcesContainer>
	);
}
