import styled from 'styled-components';

export const Relative = styled.div`
	position: relative;
`;

export const Flex = styled.div`
	display: flex;
`;

export const HorizontalCenter = styled(Flex)`
	justify-content: center;
	margin-left: auto;
	margin-right: auto;

	max-width: 25rem;
`;

export const CarouserContainer = styled(Relative)`
	overflow: hidden;
	width: 100%;
	height: auto;
`;

export const CarouselItem = styled.div`
	flex: 0 0 auto;

	margin: 0rem 2rem;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
	}
`;

export const CarouselButton = styled.button`
	position: absolute;

	cursor: pointer;

	top: 50%;
	z-index: 1;

	transition: transform 0.1s ease-in-out;

	background: white;
	border-radius: 15px;
	border: none;
	box-shadow: -2px -1px 8px 8px #00000022;
	padding: 0.5rem;
`;
export const LeftCarouselButton = styled(CarouselButton)<{
	hasItemsOnLeft: boolean;
}>`
	left: 0;
	transform: translate(-100%, -50%);
	margin-left: 12px;
	${CarouserContainer}:hover & {
		transform: translate(0%, -50%);
	}

	visibility: ${({ hasItemsOnLeft }) => (hasItemsOnLeft ? `all` : `hidden`)};
`;

export const RightCarouselButton = styled(CarouselButton)<{
	hasItemsOnRight: boolean;
}>`
	right: 0;
	transform: translate(100%, -50%);
	margin-right: 12px;
	${CarouserContainer}:hover & {
		transform: translate(0%, -50%);
	}

	visibility: ${({ hasItemsOnRight }) => (hasItemsOnRight ? `all` : `hidden`)};
`;

export const CarouserContainerInner = styled(Flex)<{ ref: any }>`
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	-ms-overflow-style: none;
	scrollbar-width: none;

	// offset for children spacing

	&::-webkit-scrollbar {
		display: none;
	}

	${CarouselItem} & {
		scroll-snap-align: center;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: row;
	}
`;

export const ArrowLeft = ({ size = 30, color = '#000000' }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		stroke={color}
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M19 12H6M12 5l-7 7 7 7" />
	</svg>
);

export const ArrowRight = ({ size = 30, color = '#000000' }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		stroke={color}
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M5 12h13M12 5l7 7-7 7" />
	</svg>
);
