import styled from 'styled-components';

export const MainContainer = styled.div`
	width: 100%;
`;

export const VideoSectionContainer = styled.div<{ color?: string }>`
	display: flex;
	justify-content: center;
	margin: 2vw auto;
	height: 40%;
	width: 1300px;
	max-width: 80%;
	background: linear-gradient(
		180deg,
		transparent 50%,
		${({ theme: { colors }, color }) =>
				color ? colors[color] || color : colors.primary}a1
			50%
	);
`;

export const Video = styled.iframe`
	position: relative;
	width: 420px;
	height: 243px;
	margin: 0 4%;
`;
export const GalleryContainer = styled.div`
	width: 240px;
	height: 240px;
	margin: 0 4%;
	background: #ffff;
	cursor: pointer;
`;

export const Gallery = styled.img`
	width: 100%;
	height: 100%;

	object-fit: cover;
`;

export const NoteCard = styled.div`
	display: flex;
	align-items: center;
	padding: 0 20px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	width: 428.2px;
	font-weight: normal;
	font-size: 16px;
	clip-path: polygon(100% 0%, 100% 80%, 90% 100%, 0% 100%, 0% 20%, 10% 0%);
	max-height: 243px;
	background-color: #f4f4f4;
	box-shadow: 16px 16px 24px rgba(0, 48, 63, 0.08);
`;

export const NoteCardList = styled.ul``;

export const NoteCardListItem = styled.li``;
