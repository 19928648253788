import {
	AddressText,
	NavIcon,
	NavItem,
	NavTitle,
	SideBarButton,
	SideBarContainer,
	StyledMenu,
} from './SideBar.styles';
import logo from '../../../../assets/DeSci.svg';
import dashboardLogo from '../../../../assets/icons/dashboard.svg';
import calendarLogo from '../../../../assets/icons/event.svg';
import nftLogo from '../../../../assets/icons/nft-gallery.png';
import faqLogo from '../../../../assets/icons/faq.svg';
import languageLogo from '../../../../assets/icons/language.svg';
import aboutUsLogo from '../../../../assets/icons/fingerprint.svg';
import contactLogo from '../../../../assets/icons/contact.svg';
import briefcaseLogo from '../../../../assets/icons/job.svg';
import groupLogo from '../../../../assets/icons/group.svg';
import newspaperLogo from '../../../../assets/icons/newspaper.svg';
import questionLogo from '../../../../assets/icons/question.svg';
import { useViewport } from '../../../../utils/viewPortHook';
import { Burger } from '../../../../components/BurgerButton';
import { useRef, useState } from 'react';
import {
	BurgerMenuContainer,
	NavLogo,
} from '../../../Landing/components/NavBar/NavBar.styles';
import { useOnClickOutside } from '../../../../utils/closeMenuHook';
import { ConnectWalletIcon } from '../NavBar/NavBar.styles';
import { useWeb3 } from '../../../../hooks/useWeb3';
import { shortenAddress } from '../../../../utils/shortenAddress';
import { TwitterIcon } from '../../../Landing/components/Footer/Footer.styles';
import { DiscordIcon } from '../Footer/Footer.styles';
import { Navigate, useNavigate } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/react';

export function SideBar() {
	const { loading, ready, address } = useWeb3();
	const { open: openWeb3Modal } = useWeb3Modal();
	const [open, setOpen] = useState(false);
	const node = useRef();
	const navigate = useNavigate();
	const { width } = useViewport();
	const breakpoint = 475;
	useOnClickOutside(node, () => setOpen(false));
	return (
		<>
			{width < breakpoint ? (
				<SideBarContainer>
					<BurgerMenuContainer ref={node}>
						<Burger open={open} setOpen={setOpen} />
						<>
							<SideBarButton src={logo} alt="logo" title="Back to home" />
							<StyledMenu open={open}>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/dashboard');
										setOpen(false);
									}}
									title="Dashboard"
									target="_blank"
								>
									<NavIcon src={dashboardLogo} />
									<NavTitle>Dashboard</NavTitle>
								</NavItem>
								{/* <NavItem
									onClick={() => {
									(window as any).scrollTo({top:0})
									navigate('/Funding');
									setOpen(false)	
									}}
									title="Funding"
									target="_blank"
								>
									<NavIcon src={fundingLogo} />
									<NavTitle>Funding</NavTitle>
								</NavItem> */}
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/calendar');
										setOpen(false);
									}}
									title="Calendar"
									target="_blank"
								>
									<NavIcon src={calendarLogo} />
									<NavTitle>Event Calendar</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/map');
										setOpen(false);
									}}
									title="Map"
									target="_blank"
								>
									<NavIcon src={languageLogo} />
									<NavTitle>Map</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/nft');
										setOpen(false);
									}}
									title="NFT"
									target="_blank"
								>
									<NavIcon src={nftLogo} />
									<NavTitle>NFT</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/resources');
										setOpen(false);
									}}
									title="Resources"
									target="_blank"
								>
									<NavIcon src={newspaperLogo} />
									<NavTitle>Resources</NavTitle>
								</NavItem>
								{/* <NavItem
									onClick={() => {
									(window as any).scrollTo({top:0})
									navigate('/Events');
									setOpen(false)	
									}}
									title="Events"
									target="_blank"
								>
									<NavIcon src={eventLogo} />
									<NavTitle>Events</NavTitle>
								</NavItem> */}
								{/* <NavItem
									onClick={() => {
									(window as any).scrollTo({top:0})
									navigate('/Blog');
									setOpen(false)	
									}}
									title="Blog"
									target="_blank"
								>
									<NavIcon src={blogLogo} />
									<NavTitle>Blog TBA</NavTitle>
								</NavItem> */}
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/FAQs');
										setOpen(false);
									}}
									title="FAQs"
									target="_blank"
								>
									<NavIcon src={faqLogo} />
									<NavTitle>FAQs</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/jobs');
										setOpen(false);
									}}
									title="What"
									target="_blank"
								>
									<NavIcon src={briefcaseLogo} />
									<NavTitle>Job Board</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/whitelist/dnaverse');
										setOpen(false);
									}}
									title="What"
									target="_blank"
								>
									<NavIcon src={groupLogo} />
									<NavTitle>Partnerships</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/About');
										setOpen(false);
									}}
									title="About"
									target="_blank"
								>
									<NavIcon src={aboutUsLogo} />
									<NavTitle>About us</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/Contact');
										setOpen(false);
									}}
									title="Contact"
									target="_blank"
								>
									<NavIcon src={contactLogo} />
									<NavTitle>Contact</NavTitle>
								</NavItem>
								<NavItem
									onClick={() => {
										(window as any).scrollTo({ top: 0 });
										navigate('/Mosaic');
										setOpen(false);
									}}
									title="Mosaic"
									target="_blank"
								>
									<NavIcon src={questionLogo} />
									<NavTitle>Mosaic</NavTitle>
								</NavItem>
								<NavItem>
									<TwitterIcon />
									<DiscordIcon />
								</NavItem>
							</StyledMenu>
						</>
					</BurgerMenuContainer>
					<NavLogo />
					{!loading && !ready ? (
						<ConnectWalletIcon
							onClick={() => {
								openWeb3Modal();
							}}
						/>
					) : loading && !ready ? (
						<AddressText>Connecting...</AddressText>
					) : (
						<AddressText>{shortenAddress(address)}</AddressText>
					)}
				</SideBarContainer>
			) : (
				<>
					<StyledMenu open={open} onMouseOver={() => setOpen(!open)}>
						<SideBarButton
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/');
							}}
							src={logo}
							alt="logo"
						/>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/dashboard');
							}}
							title="Dashboard"
							target="_blank"
						>
							<NavIcon src={dashboardLogo} />
							<NavTitle>Dashboard</NavTitle>
						</NavItem>
						{/* <NavItem
							onClick={() => {
							(window as any).scrollTo({top:0})
								navigate('/Funding');
							}}
							title="Funding"
							target="_blank"
						>
							<NavIcon src={fundingLogo} />
							<NavTitle>Funding</NavTitle>
						</NavItem> */}
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/calendar');
							}}
							title="Event Calendar"
							target="_blank"
						>
							<NavIcon src={calendarLogo} />
							<NavTitle>Event Calendar</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/map');
							}}
							title="Map"
							target="_blank"
						>
							<NavIcon src={languageLogo} />
							<NavTitle>Map</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/nft');
							}}
							title="NFT"
							target="_blank"
						>
							<NavIcon src={nftLogo} />
							<NavTitle>NFT</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/resources');
							}}
							title="Resources"
							target="_blank"
						>
							<NavIcon src={newspaperLogo} />
							<NavTitle>Resources</NavTitle>
						</NavItem>
						{/* <NavItem
							onClick={() => {
							(window as any).scrollTo({top:0})
								navigate('/Events');
							}}
							title="Events"
							target="_blank"
						>
							<NavIcon src={eventLogo} />
							<NavTitle>Events</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
							(window as any).scrollTo({top:0})
								navigate('/Blog');
							}}
							title="Blog"
							target="_blank"
						>
							<NavIcon src={blogLogo} />
							<NavTitle>Blog TBA</NavTitle>
						</NavItem> */}
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/FAQs');
							}}
							title="FAQs"
							target="_blank"
						>
							<NavIcon src={faqLogo} />
							<NavTitle>FAQs</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/jobs');
							}}
							title="What"
							target="_blank"
						>
							<NavIcon src={briefcaseLogo} />
							<NavTitle>Job Board</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/whitelist/dnaverse');
							}}
							title="What"
							target="_blank"
						>
							<NavIcon src={groupLogo} />
							<NavTitle>Partnerships</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/About');
							}}
							title="About"
							target="_blank"
						>
							<NavIcon src={aboutUsLogo} />
							<NavTitle>About us</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/Contact');
							}}
							title="Contact"
							target="_blank"
						>
							<NavIcon src={contactLogo} />
							<NavTitle>Contact</NavTitle>
						</NavItem>
						<NavItem
							onClick={() => {
								(window as any).scrollTo({ top: 0 });
								navigate('/Mosaic');
							}}
							title="Mosaic"
							target="_blank"
						>
							<NavIcon src={questionLogo} />
							<NavTitle>Mosaic</NavTitle>
						</NavItem>
					</StyledMenu>
				</>
			)}
		</>
	);
}
