import { useEffect } from 'react';
import { useThirdPatryAPIs } from '../../../../hooks/useAPI';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { useSnapshotAPI } from '../../../../hooks/useSnapshotAPI';
import { SectionContainer } from '../NftMint/NftMint.styles';
import { Banner } from './components/Banner';
import { ListSection } from './components/ListSection';
import { MainSection } from './components/MainSection';
import { DashboardContainer } from './Dashboard.styles';

export function Dashboard() {
	const { grantCount, clearState } = useThirdPatryAPIs();
	const { getTotalFollowerCountByIds, totalMembers, clearProposals } =
		useSnapshotAPI();
	const {
		organization: { filterOrganizations, organizations, loading },
	} = useAPI();
	useEffect(() => {
		filterOrganizations({
			filter: [],
			pagination: { page: 1, pageSize: 1000 },
		});
		clearState();
		clearProposals();
	}, []);

	useEffect(() => {
		if (organizations?.length) {
			// getTotalGitcoinGrants();
			getTotalFollowerCountByIds(
				organizations.map(({ snapshotId }) => snapshotId),
			);
		}
	}, [organizations]);
	return (
		<DashboardContainer>
			<SectionContainer customHeight="calc(20vh)">
				<MainSection
					totalGrants={grantCount}
					totalMembers={totalMembers || 0}
					organizationCount={organizations?.length}
				/>
			</SectionContainer>

			<SectionContainer customHeight="calc(20vh - 40px)">
				<Banner />
			</SectionContainer>

			<SectionContainer>
				<ListSection
					loading={loading}
					filterOrganizations={(filter) => {
						filterOrganizations({
							filter: filter.filter,
							...filter,
							pagination: { page: 1, pageSize: 100 },
						});
					}}
					organizations={organizations}
				/>
			</SectionContainer>
		</DashboardContainer>
	);
}
