import {
	CardContainer,
	CardContentContainer,
	CardContentContainerOffsetBorder,
} from './PresentationDisplayCard.styles';

export function PresentationDisplayCard({ children }: { children: any }) {
	return (
		<CardContainer>
			<CardContentContainerOffsetBorder>
				<CardContentContainer>{children}</CardContentContainer>
			</CardContentContainerOffsetBorder>
		</CardContainer>
	);
}
