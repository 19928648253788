import styled from 'styled-components';

interface SideBarProps {
	open?: boolean;
	src: any;
	alt?: any;
}

export const SideBarButton = styled.img<SideBarProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 53px;
	height: 66px;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;
	margin-bottom: 2rem;
	img {
		object-fit: contain;
	}
	&:focus {
		outline: none;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;

export const StyledMenu = styled.nav<{ open?: any }>`
	display: flex;
	flex-direction: column;
	background: ${({
		theme: {
			colors: {
				background: { dark },
			},
		},
	}) => dark};

	width: 4rem;
	z-index: 1;
	padding-top: 2rem;
	align-items: center;
	position: fixed;
	top: 0;
	bottom: 0;
	height: 100v%;
	left: 0;
	overflow: hidden;
	-webkit-transition: width 0.1s linear;
	transition: width 0.1s linear;
	-webkit-transform: translateZ(0) scale(1, 1);
	&:hover {
		width: max-content;
		overflow: visible;
		align-items: baseline;
		padding: 1rem;
		a {
			justify-content: flex-start;
			align-items: center;
			p {
				display: inline-block;
			}
		}
	}

	a {
		justify-content: flex-start;
		align-items: center;
		p {
			display: none;
		}
	}
	border-right: 2px solid
		${({
			theme: {
				colors: { primary },
			},
		}) => primary};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		border: none;
		align-items: flex-start;
		padding: 3rem 2rem;
		transition: transform 0.3s ease-in-out;
		transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
		width: 60%;
		height: 100%;
		a {
			p {
				display: inline-block;
			}
		}
	}
`;

export const NavItem = styled.a`
  
    display:flex;
    justify-content: space-around;
    
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.8rem 0;
    font-weight: bold;
    letter-spacing: 0.1rem;
    color: ${({
			theme: {
				colors: { secondary },
			},
		}) => secondary};
    text-decoration: none;
    transition: color 0.3s linear;
    &:hover{
			background: ${({
				theme: {
					colors: {
						background: { dark },
					},
				},
			}) => `${dark}10`};
		}
    @media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
      font-size: 0.9rem;
      letter-spacing: 0.3rem;
      text-align: left;
      padding: 1.2rem 0;
    }

    
  }
`;

export const NavIcon = styled.img`
	width: 25px;
	height: 25px;
	vertical-align: middle;
`;

export const NavTitle = styled.p`
	font-size: 14px;
	margin-left: 20px;
	line-height: 5px;
	color: ${({
		theme: {
			colors: { secondary },
		},
	}) => secondary};
	text-decoration: none;
	transition: color 0.3s linear;
`;

export const SideBarContainer = styled.div`
top: 0;
left: 0;
background:${({
	theme: {
		colors: { accent },
	},
}) => accent};
height:70px;
display:flex;
position:sticky;
justify-content:center;
z-index: 5;

box-shadow: 0px 0.5px 0px ${({
	theme: {
		colors: { primary },
	},
}) =>
	primary};, 0px 4px 4px rgba(0, 0, 0, 0.5), 0px 0px 40px rgba(117, 251, 76, 0.2);

@media ${({
	theme: {
		device: { mobile },
	},
}) => mobile} {
	border:none;
    justify-content:space-between;
  }

  
`;

export const AddressText = styled.h5`
	font-size: 16px;
	font-weight: bold;
	color: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
`;
