import styled from 'styled-components';
import logo from '../../../../assets/logo.png';
import { Button } from '../../../../components/Button';

export const NavBarContainer = styled.div`

top: 0;
left: 0;
background:black;
height:70px;
display:flex;
position:sticky;
justify-content:center;
z-index: 15;
box-shadow: 0px 0.5px 0px ${({
	theme: {
		colors: { primary },
	},
}) =>
	primary};, 0px 4px 4px rgba(0, 0, 0, 0.5), 0px 0px 40px rgba(117, 251, 76, 0.2);

@media ${({
	theme: {
		device: { mobile },
	},
}) => mobile} {
    justify-content:space-between;
  }
`;

export const NavLogo = styled.div`
	width: 104px;
	height: 46px;
	background: transparent url(${logo}) no-repeat center center/cover;
	align-self: center;
	cursor: pointer;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100px;
		height: 44px;
	}
`;

export const BurgerMenuContainer = styled.div<{ ref?: any }>`
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-right: 80px;
	}
`;

export const NavLinkContainer = styled.div`
	display: flex;
	width: 50%;
	justify-content: space-evenly;
	align-items: center;
`;
export const NavLink = styled.a`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { secondary },
		},
	}) => secondary};
	&:hover {
		cursor: pointer;
	}
`;

export const LaunchAppButton = styled(Button)`
	width: 200px;
	height: 50px;
	align-self: center;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100px;
		margin-right: 10px;
	}
`;
