import { VerticalNoteCard } from '../../../../components/NoteCard/Vertical';
import {
	CardsContainer,
	FeaturesContainer,
	FeaturesTitle,
} from './Features.styles';
import cardImage1 from '../../../../assets/note-card-image1.png';
import cardImage2 from '../../../../assets/note-card-image2.png';
import cardImage3 from '../../../../assets/note-card-image3.png';
import { useViewport } from '../../../../utils/viewPortHook';
import { Accordion } from '../../../../components/Accordion';
import { AccordionDescription } from '../../../../components/Accordion/Accordion.styles';
import { UniqueFeature } from '../../../../types';

export function Features({
	title,
	features,
}: {
	title: string;
	features: UniqueFeature[];
}) {
	const { width } = useViewport();
	const breakpoint = 475;
	const imagesArray = [cardImage1, cardImage2, cardImage3];
	return (
		<FeaturesContainer>
			<FeaturesTitle>{title}</FeaturesTitle>
			<CardsContainer>
				{features.map(({ title, description, subtitle, mediaUrl }, index) => {
					return width < breakpoint ? (
						<Accordion
							key={index}
							title={title}
							image={mediaUrl || imagesArray[index]}
							subtitle={subtitle}
							children={
								<>
									<AccordionDescription>{description}</AccordionDescription>
								</>
							}
						/>
					) : (
						<VerticalNoteCard
							key={index}
							title={title}
							image={mediaUrl || imagesArray[index]}
							subtitle={subtitle}
							text={description}
						/>
					);
				})}
			</CardsContainer>
		</FeaturesContainer>
	);
}
