import styled from 'styled-components';

export const SeparatorContainer = styled.div<{
	verticalMargin: string;
	width: string;
}>`
	height: 0.2rem;
	background: ${({ theme: { colors } }) => colors.primary};
	width: ${({ width }) => width || '100%'}; // 100% is default
	margin: ${({ verticalMargin }) => verticalMargin || '0'} auto;
`;
