import { LockIcon } from '../../containers/App/components/Footer/Footer.styles';
import {
	LegendBottomLine,
	LegendContainer,
	LegendElementcontainer,
	LegendIcon,
	LegendName,
	LegendTitleContainer,
	LegendTriangle,
	LegendValue,
	LegendValueContainer,
} from './Legend.styles';

export function Legend({
	name,
	value,
	icon,
	color,
	fullWidth,
	unclaimed,
}: {
	name?: string;
	value?: string;
	icon?: string;
	color?: string;
	fullWidth?: boolean;
	unclaimed?: boolean;
}) {
	return (
		<>
			<LockIcon unclaimed={unclaimed} />
			<LegendContainer fullWidth={fullWidth} unclaimed={unclaimed}>
				<LegendTriangle color={color} />
				<LegendElementcontainer color={color}>
					<LegendTitleContainer>
						<LegendName>{name}:</LegendName>
					</LegendTitleContainer>
					<LegendValueContainer>
						<LegendIcon width="40px" name={icon} />
						<LegendValue>{value}</LegendValue>
					</LegendValueContainer>
				</LegendElementcontainer>
				<LegendBottomLine color={color} />
			</LegendContainer>
		</>
	);
}
