import { useTheme } from 'styled-components';
import { Job, ProposalStatus } from '../../../types';
import { formatDate } from '../../../utils/formatDate';
import { useViewport } from '../../../utils/viewPortHook';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import {
	DetailCardHeader,
	DetailCardHeaderLeftContent,
	DetailCardName,
	DetailCardUser,
	DetailCardHeaderRightContent,
	DetailCardStatusText,
	DetailCardContentContainer,
	DetailCardLeftContent,
	DetailCardInnerContainer,
	DetailCardDescription,
	DetailCardItemContainer,
	DetailCardItemText,
	DetailCardRightContent,
} from '../DetailCard.styles';
import { JobCardContainer, JobLogo } from './JobDetailCard.styles';
import { useMemo } from 'react';
import { DesciTheme } from '../../../theme';

export function JobCard({ job }: { job: Job }) {
	const iconNameByStatus = useMemo(() => {
		console.log({ status: job.status });
		switch (job.status) {
			case ProposalStatus.active:
				return 'roundGreen';
			case ProposalStatus.complete:
				return 'roundRed';
			case ProposalStatus.proposed:
				return 'roundYellow';
		}
	}, [job.status]);
	const viewport = useViewport();
	const isMobile = viewport.width < DesciTheme.breakpoints.mobile;
	return (
		<JobCardContainer>
			<DetailCardHeader>
				<DetailCardHeaderLeftContent>
					<DetailCardUser>Posted: {formatDate(job.createdAt)}</DetailCardUser>
					<DetailCardName>{job.title}</DetailCardName>
					<DetailCardUser>{job.company}</DetailCardUser>
				</DetailCardHeaderLeftContent>
				<DetailCardHeaderRightContent>
					<DetailCardStatusText>{job.status}</DetailCardStatusText>
					<Icon width="40px" name={iconNameByStatus} />
				</DetailCardHeaderRightContent>
			</DetailCardHeader>
			<DetailCardContentContainer>
				<DetailCardLeftContent>
					<DetailCardInnerContainer>
						<DetailCardDescription>{job.description}</DetailCardDescription>
					</DetailCardInnerContainer>
					<DetailCardInnerContainer>
						{[
							{
								iconName: 'globe',
								text: job.location,
							},
							{
								iconName: 'clock',
								text: job.contractType,
							},
							{ iconName: 'moneybag', text: job.compensation },
						].map(({ text, iconName }, index) => (
							<DetailCardItemContainer key={index}>
								<Icon width={isMobile ? '42px' : '50px'} name={iconName} />
								<DetailCardItemText>{text}</DetailCardItemText>
							</DetailCardItemContainer>
						))}
					</DetailCardInnerContainer>
					{!!isMobile && (
						<Button
							width="100%"
							onClick={() => {
								window.open(job.applyLink, '_blank');
							}}
						>
							Apply
						</Button>
					)}
				</DetailCardLeftContent>
				{!isMobile && (
					<DetailCardRightContent>
						<JobLogo
							src={
								job.logoUrl ||
								`https://cataas.com/cat/says/${job.title.replace(' ', '%20')}`
							}
						/>
						<Button
							width="12rem"
							onClick={() => {
								window.open(job.applyLink, '_blank');
							}}
						>
							Apply
						</Button>
					</DetailCardRightContent>
				)}
			</DetailCardContentContainer>
		</JobCardContainer>
	);
}
