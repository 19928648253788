import styled from 'styled-components';
import bg from '../../../../assets/BKG_White-nft-mint.png';

export const SectionContainer = styled.section<{
	fullHeight?: boolean;
	customHeight?: string;
	mobileHeight?: string;
	background?: string;
	margin?: string;
}>`
	height: ${({ fullHeight, customHeight }) =>
		customHeight ? customHeight : fullHeight ? '100vh' : 'auto'};
	background: ${({ background }) => background};
	align-items: center;
	display: flex;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-bottom: ${({ margin }) => margin};
		height: ${({ fullHeight, mobileHeight }) =>
			mobileHeight ? mobileHeight : fullHeight ? '100vh' : 'auto'};
	}
`;

export const NftPageContainer = styled.div<{ loading?: boolean }>`
	background: url(${bg});
	background-size: cover;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	${({ loading }) => loading && 'height:100vh;'};
	text-align: center;
`;

export const NftContainer = styled.div`
	width: 50%;
	margin: 20%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin: 4px 0px;
	}
`;

export const NftSpotlight = styled.span`
	font-style: italic;
	word-spacing: 500px;
	font-size: 60px;
	line-height: 62px;
	font-weight: 700;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	width: max-content;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin: 8px 0px;
	height: min-content;
	text-align: left;
	align-self: flex-end;
`;

export const NftSubtitle = styled.div`
	font-weight: 400;
	font-size: 5vh;
	line-height: 5.1vh;
	text-align: center;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	background: transparent;
	box-sizing: border-box;
	padding-top: 8px;
`;

export const NftSubtitleHighlighted = styled.span`
	font-weight: bold;
`;
