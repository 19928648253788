import styled from 'styled-components';

export const ContainerCardContainer = styled.div`
	background: ${({
		theme: {
			colors: {
				background: { default: def },
			},
		},
	}) => def};
	clip-path: polygon(100% 0%, 100% 82%, 89% 100%, 0% 100%, 0% 20%, 10% 0%);
	display: flex;
	justify-content: space-around;
	padding: 35px;
	width: calc(100% - 12px);
	height: calc(100% - 12px);
	position: relative;
	top: 0%;
	left: 0%;
	position: relative;
`;

export const ShadowContainer = styled.div`
	clip-path: polygon(100% 0%, 100% 82%, 89% 100%, 0% 100%, 0% 20%, 10% 0%);
`;

export const WhiteContainer = styled.div`
	position: relative;
	top: 0%;
	left: 0%;
	width: calc(100% - 12px);
	height: calc(100% - 12px);
	clip-path: polygon(100% 0%, 100% 82%, 89% 100%, 0% 100%, 0% 20%, 10% 0%);
`;
export const OuterMostContainer = styled.div<{
	color?: string;
	width?: string;
	unclaimed: Boolean;
}>`
	display: inline-block;
	min-width: 280px;
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	filter: drop-shadow(
		6px 6px 0px
			${({
				theme: {
					colors: { primary },
					colors,
				},
				color,
			}) => (color ? colors[color] || color : primary)}
	);
	width: ${({ width }) => (width ? width : '100%')};
`;

export const OuterContainer = styled.div<{ width?: string; height?: string }>`
	min-width: 280px;
	height: ${({ height }) => (height ? height : '200px')};
	background: ${({
		theme: {
			colors: {
				background: { default: def },
			},
		},
	}) => def};
	clip-path: polygon(100% 0%, 100% 82%, 89% 100%, 0% 100%, 0% 20%, 10% 0%);
`;

export const InnerContainer = styled.div`
	clip-path: polygon(100% 0%, 100% 82%, 89% 100%, 0% 100%, 0% 20%, 10% 0%);
	width: calc(100% - 6px);
	background: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	height: calc(100% - 6px);
	position: relative;
	top: 0;
	z-index: 4;
	left: 0;
`;

export const InnerMostContainer = styled.div`
	clip-path: polygon(100% 0%, 100% 82%, 89% 100%, 0% 100%, 0% 20%, 10% 0%);
	background: ${({
		theme: {
			colors: {
				background: { default: def },
			},
		},
	}) => def};
	width: calc(100% - 24px);
	height: calc(100% - 24px);
	position: relative;
	top: 12px;
	left: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ContainerCardContentContainer = styled.div`
	width: 90%;
	height: 90%;
	margin-top: 4%;
	margin-left: 3%;
`;
