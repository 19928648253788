import styled from 'styled-components';

export const TableContainer = styled.table`
	color: black;
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	text-align: center;
`;
export const StyledHeader = styled.thead`
	/* width: 100%; */
	background: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	height: 60px;
	box-shadow: 6px 6px 0px
			${({
				theme: {
					colors: {
						background: { softBlack },
					},
				},
			}) => softBlack},
		8px 8px 0px
			${({
				theme: {
					colors: { primary },
				},
			}) => primary}; ;
`;
export const StyledHeaderCell = styled.td`
	/* padding: 8px; */
`;

export const StyledHeaderCellContent = styled.span<{ noBorder?: boolean }>`
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 30px;
	${({ noBorder }) => !noBorder && 'border-right: 1px solid;'}
	${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	width: 100%;
	${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	display: flex;
	justify-content: center;
`;
export const StyledTableBody = styled.tbody`
	tr:nth-child(2n) {
		background: #efefef;
	}
`;
export const StyledRow = styled.tr<{ hoverStyle?: boolean }>`
	border-top: 2px solid
		${({
			theme: {
				colors: {
					background: { gray },
				},
			},
		}) => gray};
	border-bottom: 2px solid
		${({
			theme: {
				colors: {
					background: { gray },
				},
			},
		}) => gray};
	height: 60px;
	${({
		hoverStyle,
		theme: {
			colors: { primary },
		},
	}) =>
		hoverStyle &&
		`&:hover {
		cursor: pointer;
		background: ${primary}18 !important;
	}`}
`;

export const StyledCell = styled.td`
	justify-content: center;
`;

export const StyledCellContent = styled.span<{ noBorder: boolean }>`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	${({
		noBorder,
		theme: {
			colors: { accent },
		},
	}) => !noBorder && `border-right: 1px solid ${accent}20 ;`}
	${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	width: 100%;
	${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	display: flex;
	justify-content: center;
`;

export const LoaderContainer = styled.div`
	width: 100%;
	display: flex;
	margin: 0 auto;
	align-items: center;
	padding: 20px;
	justify-content: center;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;
