import { MapContainer, StyledIframe } from "./Map.styles";

export function MapPage(){
    return <MapContainer>
        <StyledIframe
				title="DeSci Map"
				width="100%"
				frameBorder="0"
				scrolling="no"
				src="https://desci.maps.arcgis.com/apps/Shortlist/index.html?appid=1ee9c494fa624740b0b0ccfd64b1c724"
			></StyledIframe>
    </MapContainer>
}