import styled from 'styled-components';

export const EventCalendarContainer = styled.div`
	width: 90%;

	margin: 0px 0px 0px 6rem;
	min-height: 80vh;
	height: max-content;
	display: block;
	justify-content: center;
	padding: 2rem 0rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 90%;
		margin: auto;
	}

	.calendar-footer > div > a {
		display: none;
	}
`;

export const EventSourceText = styled.span`
	color: ${({ theme }) => theme.colors.accent};
`;
