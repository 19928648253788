import styled from 'styled-components';

export const MainTextContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 30%;
	flex-direction: column;
	justify-content: center;
	padding-left: 4rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		box-sizing: border-box;
		height: 80%;
		padding: 2rem;
		justify-content: center;
	}
`;

export const InfoSectionTextContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 70%;
	flex-direction: column;
	justify-content: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		box-sizing: border-box;
		height: 80%;
		padding: 2rem;
		justify-content: flex-start;
	}
`;

export const ColoredText = styled.span`
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 2rem;
	white-space: pre-line;
	width: 100%;
	text-align: left;
	color: ${({ theme: { colors } }) => colors.accent};
`;
