import styled from 'styled-components';

export const PresentationContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 2rem;
	gap: 2rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
	}
`;

export const TopSectionContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
`;

export const BottomSectionContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 1rem;
	align-items: stretch;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
	}
`;

export const PresentationTitleText = styled.span`
	font-style: italic;
	word-spacing: 500px;
	font-size: 5rem;
	line-height: 5rem;
	font-weight: 500;
	width: max-content;
	display: flex;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	height: min-content;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 2.5rem;
	}
`;
export const PresentationCardContentContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-left: 1rem;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 1rem;
		padding: 0rem;
		justify-content: center;
	}
`;

export const PresentationCardLeftContentContainer = styled.div`
	display: flex;
	width: 75%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		width: 100%;
		gap: 1rem;
	}
`;

export const PresentationCardText = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};

	font-size: 1rem;
	line-height: 1.8rem;
	padding-top: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: none;
	}
`;
export const PresentationCardRightContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: end;
	gap: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		align-items: center;
	}
`;

export const PresentationCardTextBlock = styled.div`
	padding: 2rem;
	background: ${({
		theme: {
			colors: { tertiary },
		},
	}) => tertiary};
	max-height: 4rem;
	max-width: 16rem;
	/* height: 4rem;
	width: 100%;  */
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 1.5rem;
	align-items: center;
`;

export const PresentationCardImage = styled.img<{
	width?: string;
	height?: string;
}>`
	max-width: 30rem;
	height: ${({ height }) => height || 'auto'};
	width: ${({ width }) => width || '16rem'};
	display: flex;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		align-self: unset;
	}
`;

export const VerticalTitleText = styled.span`
	font-style: italic;
	word-spacing: 500px;
	font-size: 5rem;
	font-weight: 500;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	width: max-content;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	height: min-content;
	text-align: left;
	align-self: flex-end;

	@media only screen and (max-width: 480px) {
		display: none;
		align-self: unset;
	}
`;

export const FlexGappedContainer = styled.div`
	display: flex;
	gap: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column-reverse;
		align-items: center;
	}
`;

export const BottomPresentationCardAndTitleContainer = styled.div`
	display: flex;
	@media ${({
			theme: {
				device: { small },
			},
		}) => small} {
		flex-direction: column;
		align-items: center;
	}
`;

export const WorkshopCardContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: space-between;
`;

export const FireSideImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const WorkshopTopContentContainer = styled.div`
	display: flex;
	align-items: baseline;
	gap: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column-reverse;
		align-items: center;
	}
`;

export const WorkshopBottomContentContainer = styled.div`
	width: 100%;
`;

export const FiresideTopContentContainer = styled.div`
	width: 100%;
`;

export const FiresideBottomContentContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: end;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: center;
	}
`;
