import styled from 'styled-components';
import bg from '../../../../assets/BKG_White-nft-mint.png';

export const SectionContainer = styled.section<{
	fullHeight?: boolean;
	customHeight?: string;
	mobileHeight?: string;
	background?: string;
	margin?: string;
}>`
	height: ${({ fullHeight, customHeight }) =>
		customHeight ? customHeight : fullHeight ? '100vh' : 'auto'};
	background: ${({ background }) => background};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-bottom: ${({ margin }) => margin};
		height: ${({ fullHeight, mobileHeight }) =>
			mobileHeight ? mobileHeight : fullHeight ? '100vh' : 'auto'};
		width: 100%;
	}
`;

export const MintPageContainer = styled.div`
	background: url(${bg});
	background-size: cover;
`;
