import styled from 'styled-components';

export const UnderlineStyledContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	padding: 24px 12px;
	background: linear-gradient(
		180deg,
		${({
				theme: {
					colors,
					colors: { primary },
				},
				color,
			}) => (color ? colors[color] + '50' : primary + '50')}
			0%,
		rgba(206, 253, 92, 0) 100%
	);
	border-bottom: solid 4px ${({ theme: { colors } }) => colors.primary};
`;
