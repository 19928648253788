import { RouteType } from '../../types';
import { NavigationLink } from '../NavigationLink';
import { NavigationBarContainer } from './NavigationBar.styles';

export function NavigationBar({ routes }: { routes: RouteType[] }) {
	return (
		<NavigationBarContainer>
			{routes.map((route) => (
				<NavigationLink key={route.title} route={route} />
			))}
		</NavigationBarContainer>
	);
}
