import styled from 'styled-components';

export const VerticalLine = styled.div`
	border-left: 1px solid black;
	height: 80%;
	min-height: 120px;
	width: 40px;
`;

export const DecoratedParagraphContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
`;

export const DecoratedParagraphText = styled.span`
	color: black;
	font-size: 1rem;
	height: 100%;
	max-width: calc(100% - 140px);
`;
