import { Carousel } from '../../../../components/Carousel';
import { NoteCard } from '../../../../components/NoteCard';
import {
	CarouselContainer,
	SixthSectionContainer,
	SixthSectionSubtitle,
	SixthSectionTextContainer,
	SixthSectionTitle,
} from './SixthSection.styles';
import cardImage1 from '../../../../assets/note-card-image1.png';
import cardImage2 from '../../../../assets/note-card-image2.png';
import cardImage3 from '../../../../assets/note-card-image3.png';
import cardImage4 from '../../../../assets/note-card-image4.png';
import { Accordion } from '../../../../components/Accordion';
import { useViewport } from '../../../../utils/viewPortHook';
import {
	AccordionList,
	AccordionListItem,
	AccordionTitle,
} from '../../../../components/Accordion/Accordion.styles';

export function SixthSection() {
	const { width } = useViewport();
	const breakpoint = 475;

	return (
		<SixthSectionContainer>
			<SixthSectionTextContainer>
				<SixthSectionTitle>ROADMAP</SixthSectionTitle>
				<SixthSectionSubtitle>
					internal operations, not regarding partnerships
				</SixthSectionSubtitle>
			</SixthSectionTextContainer>

			{width < breakpoint ? (
				<>
					<Accordion
						backgroundColor="white"
						title="Q2"
						image={cardImage1}
						subtitle="2022"
						children={
							<>
								<AccordionTitle>
									Q1 is the beginning. We will launch:
								</AccordionTitle>
								<AccordionList>
									<AccordionListItem>DeSciWorld Dashboard</AccordionListItem>
									<AccordionListItem>
										Research NFT Marketplace
									</AccordionListItem>
									<AccordionListItem>
										Research NFT Launchpad Service
									</AccordionListItem>
									<AccordionListItem>
										P2P Funding Marketplace (GUARDED)
									</AccordionListItem>
									<AccordionListItem>DAO Governance</AccordionListItem>
									<AccordionListItem>Lottery Prizes</AccordionListItem>
								</AccordionList>
							</>
						}
					/>
					<Accordion
						backgroundColor="white"
						title="Q3"
						image={cardImage2}
						subtitle="2022"
						children={
							<>
								<AccordionTitle>
									Q1 is the beginning. We will launch:
								</AccordionTitle>
								<AccordionList>
									<AccordionListItem>DeSciWorld Dashboard</AccordionListItem>
									<AccordionListItem>
										Research NFT Marketplace
									</AccordionListItem>
									<AccordionListItem>
										Research NFT Launchpad Service
									</AccordionListItem>
									<AccordionListItem>
										P2P Funding Marketplace (GUARDED)
									</AccordionListItem>
									<AccordionListItem>DAO Governance</AccordionListItem>
									<AccordionListItem>Lottery Prizes</AccordionListItem>
								</AccordionList>
							</>
						}
					/>
					<Accordion
						backgroundColor="white"
						title="Q4"
						image={cardImage3}
						subtitle="2022"
						children={
							<>
								<AccordionTitle>
									Q1 is the beginning. We will launch:
								</AccordionTitle>
								<AccordionList>
									<AccordionListItem>DeSciWorld Dashboard</AccordionListItem>
									<AccordionListItem>
										Research NFT Marketplace
									</AccordionListItem>
									<AccordionListItem>
										Research NFT Launchpad Service
									</AccordionListItem>
									<AccordionListItem>
										P2P Funding Marketplace (GUARDED)
									</AccordionListItem>
									<AccordionListItem>DAO Governance</AccordionListItem>
									<AccordionListItem>Lottery Prizes</AccordionListItem>
								</AccordionList>
							</>
						}
					/>
					<Accordion
						backgroundColor="white"
						title="Q1"
						image={cardImage4}
						subtitle="2022"
						children={
							<>
								<AccordionTitle>
									Q1 is the beginning. We will launch:
								</AccordionTitle>
								<AccordionList>
									<AccordionListItem>DeSciWorld Dashboard</AccordionListItem>
									<AccordionListItem>
										Research NFT Marketplace
									</AccordionListItem>
									<AccordionListItem>
										Research NFT Launchpad Service
									</AccordionListItem>
									<AccordionListItem>
										P2P Funding Marketplace (GUARDED)
									</AccordionListItem>
									<AccordionListItem>DAO Governance</AccordionListItem>
									<AccordionListItem>Lottery Prizes</AccordionListItem>
								</AccordionList>
							</>
						}
					/>
				</>
			) : (
				<CarouselContainer>
					<Carousel>
						<NoteCard
							subtitle="2022"
							image={cardImage1}
							title="Q2"
							text={`Q2 is the beginning. We will launch:
• DeSciWorld Dashboard
• Research NFT Marketplace
• Research NFT Launchpad Service
• P2P Funding Marketplace (GUARDED)
• DAO Governance
• Lottery Prizes`}
						/>
						<NoteCard
							subtitle="2022"
							image={cardImage2}
							title="Q3"
							text={`Q3 sees the initial launch phase expanded into a valuable service, globally.
• Expand operations to embrace developing nations
• Continuous Improvement of Dashboard, including new metrics and features
• Publish results from the P2P`}
						/>
						<NoteCard
							subtitle="2022"
							image={cardImage3}
							title="Q4"
							text={`Q4 will explore the paradigm of integrating DeSciWorld’s services with existing academic institutions, and further legitimising the P2P Funding initiative:
• Implement (de)Peer Review for P2P Funding
• Create a pooled Grant Fund in the DAO to be earmarked for 
`}
						/>
						<NoteCard
							subtitle="2023"
							image={cardImage4}
							title="Q1"
							text={`Q1 marks the beginning of DeSciWorld’s scaling ambitions:
Open DeSciWorld’s first “Citizen Laboratory”
Develop, integrate and implement decentralised solutions for publishing, reviewing, reputation and more from the Decentralised Science community.`}
						/>
					</Carousel>
				</CarouselContainer>
			)}
		</SixthSectionContainer>
	);
}
