import {
	PartnerItem,
	PartnersText,
	SeventhSectionContainer,
} from '../../../components/SeventhSection/SeventhSection.styles';
import c from '../../../../../assets/c.png';
import bloxberg from '../../../../../assets/bloxberg.png';
export function PartnerSection() {
	return (
		<SeventhSectionContainer>
			<PartnersText>Partners:</PartnersText>
			<PartnerItem url={c} />
			{/* <PartnerItem url={bloxberg} /> */}
		</SeventhSectionContainer>
	);
}
