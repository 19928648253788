import { LockIcon } from '../../containers/App/components/Footer/Footer.styles';
import {
	ContainerCardContentContainer,
	InnerContainer,
	InnerMostContainer,
	OuterContainer,
	OuterMostContainer,
} from './ContainerCard.styles';

export function ContainerCard({
	children,
	height,
	width,
	color,
	unclaimed,
}: {
	width?: string;
	height?: string;
	children?: any;
	color?: string;
	unclaimed?: Boolean;
}) {
	return (
		<OuterMostContainer width={width} unclaimed={unclaimed}>
			<OuterContainer color={color} height={height}>
				<InnerContainer>
					<InnerMostContainer>
						<ContainerCardContentContainer>
							<LockIcon unclaimed={true} />
							{children}
						</ContainerCardContentContainer>
					</InnerMostContainer>
				</InnerContainer>
			</OuterContainer>
		</OuterMostContainer>
	);
}
