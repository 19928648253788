import {
	useContext,
	useState,
	createContext,
	ReactElement,
	useEffect,
} from 'react';
import {
	Chain,
	EthereumClient,
	modalConnectors,
	walletConnectProvider,
} from '@web3modal/ethereum';

import {
	configureChains,
	createClient,
	goerli,
	useAccount,
	useNetwork,
	useSigner,
	useSwitchNetwork,
} from 'wagmi';
import { optimism, optimismGoerli, sepolia } from 'wagmi/chains';

import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import { BigNumber, ethers } from 'ethers';
import {
	MICROBE_ABI,
	MICROBE_ADDRESS,
	// TEST_MICROBE_ADDRESS,
} from '../constants/abi';
import { MintContract, Whitelist } from '../types';
import { useWeb3Modal } from '@web3modal/react';
import { CHAIN_NAME_BY_ID } from '../constants';
export const PROJECT_ID = '7a4f42b843d3cd0cb3e841958bd1049f';
const CHAIN_ID = 1;

// Wagmi client
const chains = [
	optimism,
	optimismGoerli,
	arbitrum,
	mainnet,
	polygon,
	goerli,
	sepolia,
];
export const { provider } = configureChains(chains, [
	walletConnectProvider({ projectId: PROJECT_ID }),
]);
export const wagmiClient = createClient({
	autoConnect: true,
	connectors: modalConnectors({ appName: 'web3Modal', chains }),
	provider,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiClient, chains);

export function getAlertFromChainId(chainId: number) {
	return `We have detected you are currently not on ${CHAIN_NAME_BY_ID[chainId]}, please switch your network and try again!`;
}

export function checkChain(chainId: number, chainIdToCheck: number) {
	console.log({ chainId, chainIdToCheck });
	return chainId === (chainIdToCheck || CHAIN_ID);
}
export function useWeb3State() {
	//wagmi hooks
	const { data: signer } = useSigner();
	const { chain } = useNetwork();
	const {
		error: switchError,
		isLoading: isSwitchLoading,
		pendingChainId,
		switchNetwork,
	} = useSwitchNetwork();
	const { address, isConnecting, isDisconnected } = useAccount();
	//web3modal hook
	const { isOpen } = useWeb3Modal();
	// interaction state hooks
	const [ready, setReady] = useState<boolean>(false);
	const [mintingNFT, setMintingNFT] = useState<boolean>(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [currentMintContract, setCurrentMintContract] = useState<MintContract>({
		abi: MICROBE_ABI,
		address: MICROBE_ADDRESS,
		mintMethodName: 'mintMicrobes',
		name: 'Microbes',
		value: '0.088',
		uniqueFeatures: [],
		chainId: 1,
	});
	function setMintingContract(contractToSet: MintContract) {
		setCurrentMintContract(contractToSet);
	}

	function switchChain(chainId: number) {
		console.log({ chainId, chain: chain.id });
		switchNetwork?.(chainId);
	}

	async function getETHBalance() {
		try {
			if (!signer?.getBalance) {
				return null;
			}
			const wei = await signer.getBalance();
			const eth = ethers.utils.formatEther(wei);
			return { wei, eth };
		} catch (e) {
			console.log('getETH error : ', { e });
			throw e;
		}
	}

	async function mintToken(): Promise<void> {
		try {
			if (!provider || !ready || !signer) {
				return null;
			}
			if (!checkChain(chain.id, currentMintContract.chainId)) {
				alert(getAlertFromChainId(currentMintContract.chainId));
				return;
			}
			const mintContract = new ethers.Contract(
				currentMintContract.address,
				currentMintContract.abi,
				signer,
			);
			setMintingNFT(true);
			let mintTxn = null;
			if (currentMintContract.mintMethodName !== 'mintToken') {
				mintTxn = await mintContract[currentMintContract.mintMethodName](1, {
					value: ethers.utils.parseEther(currentMintContract.value),
				});
			} else {
				mintTxn = await mintContract[currentMintContract.mintMethodName]({
					value: ethers.utils.parseEther(currentMintContract.value),
				});
			}
			setMintingNFT(false);
		} catch (e) {
			console.log('error minting : ', e);
			setMintingNFT(false);
			setError(e);
		}
	}

	async function mintMultiToken(id: number, amount: number): Promise<void> {
		try {
			if (!provider || !ready || !signer) {
				return null;
			}
			if (!checkChain(chain.id, currentMintContract.chainId)) {
				alert(getAlertFromChainId(currentMintContract.chainId));
				return;
			}
			const mintContract = new ethers.Contract(
				currentMintContract.address,
				currentMintContract.abi,
				signer,
			);
			setMintingNFT(true);
			console.log({ id });

			const selectedToken = currentMintContract?.tokens.find(
				({ id: tId }) => tId === id,
			);
			if (!selectedToken) {
				throw new Error('Id not found');
			}
			const mintTxn = await mintContract[currentMintContract.mintMethodName](
				id,
				amount,
				{
					value: ethers.utils.parseEther(selectedToken?.price).mul(amount),
				},
			);
			setMintingNFT(false);
		} catch (e) {
			console.log('error minting : ', e);
			setMintingNFT(false);
			setError(e);
		}
	}

	async function mintBatchUnique(amount: number): Promise<void> {
		try {
			if (!provider || !ready || !signer) {
				return null;
			}
			if (!checkChain(chain.id, currentMintContract.chainId)) {
				alert(getAlertFromChainId(currentMintContract.chainId));
				return;
			}
			const mintContract = new ethers.Contract(
				currentMintContract.address,
				currentMintContract.abi,
				signer,
			);
			setMintingNFT(true);
			let value;
			if (!!currentMintContract.whitelistedAddresses?.length) {
				const isPublicSaleEnabled = await mintContract.PUBLIC_SALE();
				console.log({ isPublicSaleEnabled, address });
				const isAddressInArray = currentMintContract.whitelistedAddresses.find(
					(addy) => {
						return (
							ethers.utils.getAddress(addy) === ethers.utils.getAddress(address)
						);
					},
				);
				if (!isAddressInArray && !isPublicSaleEnabled) {
					throw new Error('Your address is not whitelisted for mint');
				}
			}
			if (currentMintContract.dynamicPrice) {
				const weiPrice = await mintContract[
					currentMintContract.getPriceMethodName
				]();
				value = BigNumber.from(weiPrice).mul(amount);
			} else {
				value = ethers.utils.parseEther(currentMintContract.value);
			}
			const mintTxn = await mintContract[currentMintContract.mintMethodName](
				amount,
				{
					value,
				},
			);
			setMintingNFT(false);
		} catch (e) {
			console.log('error minting : ', e);
			setMintingNFT(false);
			setError(e);
		}
	}

	async function mintTokenAllocation(whitelist: Whitelist): Promise<void> {
		try {
			if (!provider || !ready || !signer) {
				return null;
			}
			if (!checkChain(chain.id, currentMintContract.chainId)) {
				alert(getAlertFromChainId(currentMintContract.chainId));
				return;
			}
			const mintContract = new ethers.Contract(
				whitelist.contract,
				whitelist.abi,
				signer,
			);
			setMintingNFT(true);
			const { magicNumber, allocation: allocationByAddress } = whitelist;
			if (!allocationByAddress[address]) {
				setMintingNFT(false);
				setError('No allocation for this address');
			}
			const allocation = ethers.utils.parseEther(
				allocationByAddress[address].toString(),
			);
			if (!allocation) {
				setMintingNFT(false);
				setError('No allocation for this address');
			}
			const mintTxn = await mintContract[whitelist.method](
				magicNumber,
				allocation,
			);
			setMintingNFT(false);
		} catch (e) {
			console.log('error minting : ', e);
			setMintingNFT(false);
			setError(e);
		}
	}

	async function mintTokensByCategory(amounts: number[]): Promise<void> {
		try {
			if (!provider || !ready || !signer) {
				return null;
			}
			if (!checkChain(chain.id, currentMintContract.chainId)) {
				alert(getAlertFromChainId(currentMintContract.chainId));
				return;
			}
			const mintContract = new ethers.Contract(
				currentMintContract.address,
				currentMintContract.abi,
				signer,
			);
			setMintingNFT(true);
			const value = await mintContract[currentMintContract.getPriceMethodName](
				amounts,
			);
			console.log({
				value,
				v: ethers.utils.formatEther(value),
				amounts,
				currentMintContract,
			});
			const mintTxn = await mintContract[currentMintContract.mintMethodName](
				amounts,
				{ value, gasLimit: 10000000 },
			);
			setMintingNFT(false);
		} catch (e) {
			console.log('error minting : ', e);
			setMintingNFT(false);
			setError(e);
		}
	}

	useEffect(() => {
		if (error) {
			console.log({ error });
			if (error.message.includes('insufficient funds')) {
				alert(
					'Insufficient funds! make sure you fund your wallet before minting',
				);
			} else {
				console.log({ error });
				alert(error?.error?.message || error?.reason || error.message);
			}
			setError(null);
		}
	}, [error]);
	useEffect(() => {
		if (isSwitchLoading) {
			console.log({ pendingChainId });
			setLoading(true);
		}
	}, [isSwitchLoading]);
	useEffect(() => {
		console.log({ isOpen, isConnecting, address, isDisconnected });
		if (isConnecting && isOpen) {
			setLoading(true);
			setReady(false);
		} else {
			setLoading(false);
			if (!isDisconnected && !!address) {
				setReady(true);
			} else {
				setReady(false);
			}
		}
	}, [isOpen, isConnecting, address, isDisconnected]);

	return {
		provider,
		signer,
		getETHBalance,
		mintToken,
		mintMultiToken,
		ready,
		address,
		mintingNFT,
		loading,
		error,
		setMintingContract,
		mintBatchUnique,
		mintTokenAllocation,
		mintTokensByCategory,
		switchChain,
		chain,
	};
}

export const Web3Context: React.Context<{
	provider: any; //wagmi type pending
	signer: any; //wagmi type pending
	getETHBalance: () => Promise<{ wei: ethers.BigNumber; eth: string }>;
	mintToken: () => Promise<void>;
	mintMultiToken: (id: number, amount: number) => Promise<void>;
	mintBatchUnique: (amount: number) => Promise<void>;
	mintTokensByCategory: (amounts: number[]) => Promise<void>;
	ready: boolean;
	address: string;
	mintingNFT: boolean;
	error: any;
	loading: boolean;
	setMintingContract: (contractToSet: MintContract) => void;
	mintTokenAllocation: (whitelist: Whitelist) => void;
	switchChain: (chainId: number) => void;
	chain: Chain;
}> = createContext({} as any);

export function Web3Provider({ children }: { children?: ReactElement }) {
	const web3 = useWeb3State();

	return <Web3Context.Provider value={web3}>{children}</Web3Context.Provider>;
}

export function useWeb3() {
	return useContext(Web3Context);
}
