import styled from 'styled-components';
import { FormElementProps } from '../types';

export const StyledTextArea = styled.textarea<{ disabled: boolean }>`
	width: 100%;
	border: solid 1px
		${({
			theme: {
				colors: { accent },
			},
		}) => accent};
	&:focus {
		box-shadow: 0 0 0px 2px
			${({
				theme: {
					colors: { primary },
				},
			}) => primary};
		outline: 0;
	}
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	padding: 12px;
	min-height: 120px;
	box-sizing: border-box;
`;

export const TextAreaContainer = styled.div`
	display: flex;
	width: 100%;
	min-width: 200px;
	flex-direction: column;
`;
