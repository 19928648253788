import eth from '../../../assets/icons/eth.svg';
import download from '../../../assets/icons/download.svg';
import file from '../../../assets/icons/file-copy.svg';
import paper from '../../../assets/icons/file-copy.svg';
import accordionArrowDown from '../../../assets/icons/accordion-arrow-down.svg';
import accordionArrowUp from '../../../assets/icons/accordion-arrow-up.svg';
import switchIcon from '../../../assets/icons/switch-icon.png';
import searchIcon from '../../../assets/icons/search.svg';
import roundGreen from '../../../assets/icons/round-green.svg';
import roundRed from '../../../assets/icons/round-red.svg';
import roundYellow from '../../../assets/icons/round-yellow.svg';
import calendar from '../../../assets/icons/calendar.svg';
import people from '../../../assets/icons/people.svg';
import clock from '../../../assets/icons/clock.svg';
import ticket from '../../../assets/icons/ticket.svg';
import globe from '../../../assets/icons/globe.svg';
import moneybag from '../../../assets/icons/moneybag.svg';
import optimism from '../../../assets/icons/op.svg';
import polygon from '../../../assets/icons/polygon.svg';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import question from '../../../assets/icons/question.svg';
export const IconSrc: { [key: string]: any } = {
	paper,
	accordionArrowDown,
	accordionArrowUp,
	switch: switchIcon,
	search: searchIcon,
	roundGreen,
	roundRed,
	file,
	people,
	eth,
	download,
	roundYellow,
	calendar,
	ticket,
	clock,
	globe,
	moneybag,
	chain1: eth,
	chain420: optimism,
	chain10: optimism,
	polygon,
	chain137: polygon,
	arrowLeft: arrowLeft,
	question,
};
