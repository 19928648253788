import styled from 'styled-components';
import { IconSrc } from './constants';

export const StyledSpan = styled.div<{ width?: string; iconName?: string }>`
	background: transparent
		url(${({ iconName }) => IconSrc[iconName || 'default']}) no-repeat center
		center/contain;
	width: ${({ width }) => (width ? width : '24px')};
	height: ${({ width }) => (width ? width : '24px')}; ;
`;
