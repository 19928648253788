import styled from 'styled-components';

export const FaqsContainer = styled.div`
	width: 80%;
	margin: 0 auto;
	height: max-content;
	min-height: calc(100vh - 222px);
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 95%;
		margin: 0 auto;
		height: max-content;
	}
`;
