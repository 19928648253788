import styled from 'styled-components';

export const ProposalContainer = styled.div`
	width: 100%;
	margin-left: 2rem;
	box-sizing: border-box;
	padding: 0rem 4rem;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 0;
		padding: 0 1rem;
	}
`;

export const SectionContainer = styled.section<{
	fullHeight?: boolean;
	customHeight?: string;
	mobileHeight?: string;
	background?: string;
	margin?: string;
	width?: string;
}>`
	height: ${({ fullHeight, customHeight }) =>
		customHeight ? customHeight : fullHeight ? '100vh' : 'auto'};
	background: ${({ background }) => background};
	width: ${({ width }) => width};
	margin: ${({ margin }) => margin};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-bottom: ${({ margin }) => margin};
		height: ${({ fullHeight, mobileHeight }) =>
			mobileHeight ? mobileHeight : fullHeight ? '100vh' : 'auto'};
	}
`;
