import { ChangeEvent } from 'react';
import { StyledLabel } from '../Form.styles';
import { FormElementProps } from '../types';
import { StyledTextArea, TextAreaContainer } from './TextArea.styles';

export function TextArea({
	value,
	onChange,
	placeholder,
	disabled,
	label,
}: FormElementProps) {
	return (
		<TextAreaContainer>
			{label && <StyledLabel>{label}</StyledLabel>}
			<StyledTextArea
				placeholder={placeholder}
				value={value}
				onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
					const text = e.target.value;
					onChange(text);
				}}
				disabled={disabled}
			/>
		</TextAreaContainer>
	);
}
