import {
	DecoratedParagraphContainer,
	DecoratedParagraphText,
	VerticalLine,
} from './DecoratedParagraph.styles';

export function DecoratedParagraph({
	children,
	lineCount,
}: {
	lineCount?: number;
	children: any;
}) {
	return (
		<DecoratedParagraphContainer>
			{Array.apply(null, Array(lineCount || 3)).map(() => (
				<VerticalLine />
			))}
			<DecoratedParagraphText>{children}</DecoratedParagraphText>
		</DecoratedParagraphContainer>
	);
}
