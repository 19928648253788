import {
	Container,
	Image,
	Parragraph,
	Title,
	TextContainer,
} from './ContributorSection.styles';

export function ContributorSection() {
	return (
		<Container>
			<Image />
			<TextContainer>
				<Title>Become a contributor and grow</Title>
				<Parragraph>
					DeSciWorld is a constantly evolving ecosystem with a broad scope for
					its future direction. We encourage researchers, builders, community
					leaders, artists and creators - anyone - to contribute and find a
					place in the community. Join our Discord and get involved!
				</Parragraph>
			</TextContainer>
		</Container>
	);
}
