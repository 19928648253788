import { SlimSeparator } from '../slimSeparator';
import {
	FifthSectionCardsContainer,
	FifthSectionContainer,
	FifthSectionPageTitle,
	FifthSectionSubtitle,
	FifthSectionText,
	FifthSectionTextContainer,
	FifthSectionTitle,
} from './FifthSection.styles';
import firstBackground from '../../../../assets/fifth-section-image1.png';
import secondBackground from '../../../../assets/fifth-section-image2.png';
import thirdBackground from '../../../../assets/fifth-section-image3.png';
import { Button } from '../../../../components/Button';
import { useViewport } from '../../../../utils/viewPortHook';
import { SlimSeparatorHorizontal } from '../SlimSeparatorHorizontal';

export function FifthSection() {
	const { width } = useViewport();
	const breakpoint = 475;

	return (
		<FifthSectionContainer>
			<FifthSectionPageTitle>
				Become a part of the World of DeSci
			</FifthSectionPageTitle>
			<FifthSectionCardsContainer>
				<FifthSectionTextContainer
					background={firstBackground}
					position="top-left"
				>
					<FifthSectionTitle>Learn</FifthSectionTitle>
					<FifthSectionSubtitle>& contribute</FifthSectionSubtitle>
					<FifthSectionText>
						DeSciWorld’s inclusive community welcomes all, regardless of
						education or background. Come to World of DeSci: Belgrade to meet
						new people, discover new ideas and find your place in the changing
						paradigm.
					</FifthSectionText>
				</FifthSectionTextContainer>
				{width < breakpoint ? <SlimSeparatorHorizontal /> : <SlimSeparator />}

				<FifthSectionTextContainer
					background={secondBackground}
					position="top-right"
				>
					<FifthSectionTitle>Build</FifthSectionTitle>
					<FifthSectionSubtitle>& regenerate</FifthSectionSubtitle>
					<FifthSectionText>
						Web3 and cryptocurrencies provide brand new toolsets and incentive
						mechanisms to experiment with; the scope of innovation is bound only
						by one’s imagination. Experimentation in this field is encouraged
						and our network is at hand to help direct innovation.
					</FifthSectionText>
				</FifthSectionTextContainer>
				{width < breakpoint ? <SlimSeparatorHorizontal /> : <SlimSeparator />}
				<FifthSectionTextContainer
					background={thirdBackground}
					position="top-right"
				>
					<FifthSectionTitle>Spread</FifthSectionTitle>
					<FifthSectionSubtitle>like wildfire</FifthSectionSubtitle>
					<FifthSectionText>
						DeSciWorld enables and supports those that want to decentralise
						Science. Using our platform, a DeScientist and their community can
						improve outreach for their work, grow networks and access resources
						from a vibrant ecosystem.
					</FifthSectionText>
				</FifthSectionTextContainer>
			</FifthSectionCardsContainer>
			<Button
				onClick={() => {
					window.open('https://discord.gg/jnEUqVH8xv', '_blank');
				}}
				color="primary"
			>
				Contribute
			</Button>
		</FifthSectionContainer>
	);
}
