import styled from 'styled-components';
export const SeventhSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${({
		theme: {
			colors: {
				background: { lightGray },
			},
		},
	}) => lightGray};
	display: flex;
	/* justify-content:center;*/
	align-items: center;
	flex-direction: row;
	justify-content: space-evenly;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
	}
`;

export const PartnersText = styled.span`
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 30px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin: 10px 40px;
		align-self: flex-start;
	}
`;

export const PartnerItem = styled.div<{ url: string }>`
	background: transparent url(${({ url }) => url}) no-repeat center
		center/contain;
	width: 180px;
	height: 100px;
`;
