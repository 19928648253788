import { useViewport } from '../../../../utils/viewPortHook';
import { DecoratedParagraph } from '../DecoratedParagraph';
import { LandingHighlightedText } from '../LandingHighlightedText';
import {
	StyledSectionImage,
	ThirdSectionContainer,
	ThirdSectionText,
	ThirdSectionTextContainer,
} from './ThirdSection.styles';

export function ThirdSection() {
	const { width } = useViewport();
	const breakpoint = 475;
	return (
		<ThirdSectionContainer>
			<StyledSectionImage />
			<ThirdSectionTextContainer>
				<ThirdSectionText>
					Research; unrestricted, unbiased, uncensorable.{' '}
					<LandingHighlightedText>Science</LandingHighlightedText> as it should
					be.
				</ThirdSectionText>
				<DecoratedParagraph lineCount={width < breakpoint ? 2 : null}>
					Science has become a monolithic institution: an altar at which modern
					society offers its brightest and best; at which world governments,
					NGOs and HNWIs lay uncountable tithe; where we place our faith to
					solve pressing global issues. To prevent capture, it is essential that
					we decentralise these institutions and maintain transparency and
					accountability.
				</DecoratedParagraph>
			</ThirdSectionTextContainer>
		</ThirdSectionContainer>
	);
}
