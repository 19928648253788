import eth from '../../../../../../assets/icons/eth.svg';
import { ProposalCard } from '../../../../../../components/ProposalCard';
import { ProposalGroupType, ProposalStatus } from '../../../../../../types';
import {
	FundingContainer,
	RequestContainer,
	Title,
	CommentText,
	FundingList,
	ListItem,
	SeeMoreText,
	ResearchList,
	ResearchContainer,
} from './RequestSection.styles';

export function RequestSection() {
	return (
		<RequestContainer>
			<FundingContainer>
				<Title color="primary">Funding Request</Title>
				<CommentText>// researchers looking for funding //</CommentText>
				<FundingList>
					{[1, 2, 3, 4].map((item) => (
						<ListItem>
							<ProposalCard
								name="Field Research in Mississipi River "
								address="jmisslor"
								token="eth"
								amount={3}
								status={ProposalStatus.proposed}
								groupType={ProposalGroupType.consultation}
								tokenIconUrl={eth}
								_id="1"
							/>
						</ListItem>
					))}

					<SeeMoreText>See more ⬇️</SeeMoreText>
				</FundingList>
			</FundingContainer>
			<ResearchContainer>
				<Title color="tertiary">Research Request</Title>
				<CommentText>// funders looking for research //</CommentText>
				<ResearchList>
					{[1, 2, 3, 4].map((item) => (
						<ListItem>
							<ProposalCard
								name="Field Research in Mississipi River "
								address="jmisslor"
								token="eth"
								amount={3}
								status={ProposalStatus.proposed}
								groupType={ProposalGroupType.consultation}
								tokenIconUrl={eth}
								_id="1"
							/>
						</ListItem>
					))}

					<SeeMoreText>See more ⬇️</SeeMoreText>
				</ResearchList>
			</ResearchContainer>
		</RequestContainer>
	);
}
