import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { About } from './containers/App/containers/About';
import { Contact } from './containers/App/containers/Contact';
import { Dashboard } from './containers/App/containers/Dashboard';
import { Faqs } from './containers/App/containers/Faqs';
import { Lottery } from './containers/App/containers/Lottery';
import { MapPage } from './containers/App/containers/Map';
import { NftMain } from './containers/App/containers/NftMain';
import { NftMint } from './containers/App/containers/NftMint';
import { P2P } from './containers/App/containers/P2P';
import { ProjectPage } from './containers/App/containers/Project';
import { Proposal } from './containers/App/containers/Proposal';
import { WhiteList } from './containers/App/containers/Whitelist';
import { AppRoute } from './containers/App/routes';
import { Landing } from './containers/Landing';
import { Token } from './containers/App/containers/Token';
import { BelgradeLanding } from './containers/Landing/BelgradeLanding';
import { SofiaLanding } from './containers/Landing/SofiaLanding';
import { Jobs } from './containers/App/containers/Jobs';
import { EventCalendar } from './containers/App/containers/Calendar';
import { Resources } from './containers/App/containers/Resources';
import { Mosaic } from './containers/App/containers/Mosaic';
import { MosaicQuestionDetail } from './containers/App/containers/Mosaic/QuestionDetail';
import { MosaicQuestionAsk } from './containers/App/containers/Mosaic/AskQuestion';

export const routes = [
	{ path: '/', title: 'Home Page' },
	{ path: '/Lottery', title: 'Lottery' },
];

export default function Routers() {
	return (
		<Router>
			{/* <NavigationBar routes={routes}/> */}
			<Routes>
				<Route index element={<Landing />} />
				<Route path="/belgrade" element={<BelgradeLanding />} />
				<Route path="/sofia" element={<SofiaLanding />} />
				<Route
					path="/nft"
					element={
						<AppRoute>
							<NftMain />
						</AppRoute>
					}
				/>
				<Route
					path="/resources"
					element={
						<AppRoute>
							<Resources />
						</AppRoute>
					}
				/>
				<Route
					path="/map"
					element={
						<AppRoute>
							<MapPage />
						</AppRoute>
					}
				/>
				<Route
					path="/calendar"
					element={
						<AppRoute>
							<EventCalendar />
						</AppRoute>
					}
				/>
				<Route
					path="/nft/:id"
					element={
						<AppRoute>
							<NftMint />
						</AppRoute>
					}
				/>
				<Route
					path="/whitelist/:id"
					element={
						<AppRoute>
							<WhiteList />
						</AppRoute>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<AppRoute>
							<Dashboard />
						</AppRoute>
					}
				/>
				<Route
					path="/project/:id"
					element={
						<AppRoute>
							<ProjectPage />
						</AppRoute>
					}
				/>
				<Route
					path="/proposal/:id"
					element={
						<AppRoute>
							<Proposal />
						</AppRoute>
					}
				/>
				<Route
					path="/proposal"
					element={
						<AppRoute>
							<P2P />
						</AppRoute>
					}
				/>
				<Route
					path="/about"
					element={
						<AppRoute>
							<About />
						</AppRoute>
					}
				/>
				<Route
					path="/contact"
					element={
						<AppRoute>
							<Contact />
						</AppRoute>
					}
				/>
				<Route
					path="/mosaic"
					element={
						<AppRoute>
							<Mosaic />
						</AppRoute>
					}
				/>
				<Route
					path="/mosaic/ask"
					element={
						<AppRoute>
							<MosaicQuestionAsk />
						</AppRoute>
					}
				/>
				<Route
					path="/mosaic/question/:id"
					element={
						<AppRoute>
							<MosaicQuestionDetail />
						</AppRoute>
					}
				/>

				<Route
					path="/faqs"
					element={
						<AppRoute>
							<Faqs />
						</AppRoute>
					}
				/>
				<Route
					path="/lottery"
					element={
						<AppRoute>
							<Lottery />
						</AppRoute>
					}
				/>
				<Route
					path="/jobs"
					element={
						<AppRoute>
							<Jobs />
						</AppRoute>
					}
				/>
			</Routes>

			{/* <ScrollToTop /> */}
		</Router>
	);
}
