import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '../Icon';
import { Tab } from './components/Tab';
import { SwitchIconContainer, TabBarContainer } from './TabBar.styles';

export function TabBar({
	tabs,
	color,
}: {
	color: string;
	tabs: { link: string; title: string; active: boolean }[];
}) {
	// state for now, once implementation is clearer, implement better on change
	const location = useLocation();
	const navigate = useNavigate();
	return (
		<TabBarContainer>
			{tabs.map(({ link, title, active }, index) => (
				<>
					<Tab
						key={`tab-${index}`}
						onClick={() => {
							// link behavior dependant of the use case
							if (link.includes('http')) {
								window.open(link, '_blank');
							} else {
								navigate(link, { replace: true });
							}
						}}
						color={color}
						link={link}
						title={title}
						active={location?.pathname === link}
					/>
					{index < tabs.length - 1 && (
						<SwitchIconContainer color={color} key={`switch-${index}`}>
							<Icon width="18px" name="switch" />
						</SwitchIconContainer>
					)}
				</>
			))}
		</TabBarContainer>
	);
}
