import styled from 'styled-components';

export const ResourcesContainer = styled.div`
	width: 80%;
	margin: 0 auto;
	height: max-content;
	min-height: calc(100vh - 222px);
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 95%;
		margin: 0 auto;
		height: max-content;
	}
`;

export const ListItemText = styled.span`
	font-style: normal;
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 2rem;
	line-height: 2rem;
	text-align: center;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	margin: 1rem 0;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: 700;
		text-align: left;
		letter-spacing: -0.03em;
	}
`;

export const ResourceSection = styled.div`
	width: 100%;
	height: max-content;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
`;
