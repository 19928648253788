import styled from "styled-components";

export const TabBarContainer = styled.div`
display:flex;
justify-content:center;
width:max-content;
&:hover{
  cursor:pointer;
}
@media ${({theme:{device:{mobile}}})=>mobile} {
    width: 100%;
  }
`


export const SwitchIconContainer = styled.div<{color:string}>`

background:linear-gradient(180deg, ${({theme:{colors,colors:{primary}},color})=>color ? colors[color]+'50' :primary+'50'} 0%, rgba(206, 253, 92, 0) 100%);
border-bottom:4px solid ${({theme:{colors,colors:{primary}},color})=>color ? colors[color] :primary};
align-items:center;
justify-content:center;
width:30px;
display:flex;
@media ${({theme:{device:{mobile}}})=>mobile} {
    display: none;
  }
`

