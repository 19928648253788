import styled from 'styled-components';

export const FeaturesContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
	width: 100%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const CardsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		box-sizing: border-box;
		padding: 0px 12px;
		flex-direction: column;
	}
`;

export const FeaturesTitle = styled.span`
	margin: 24px;
	width: 70%;
	font-weight: normal;
	font-size: 48px;
	line-height: 48px;
	letter-spacing: -0.02em;
	text-align: left;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 26px;
		line-height: 30px;
		color: #585a5e;
	}
`;
