import { Children, useRef } from "react"
import { ArrowLeft, ArrowRight, CarouselItem, CarouserContainer, CarouserContainerInner, LeftCarouselButton, RightCarouselButton } from "./Carousel.styles"
import { usePosition } from "./utils"






export function Carousel({children}:{children:any}) {
  const ref = useRef()

  const {
    hasItemsOnLeft,
    hasItemsOnRight,
    scrollRight,
    scrollLeft,
  } = usePosition(ref)

  return (
    <CarouserContainer role="region" aria-label="Colors carousel">
      <CarouserContainerInner ref={ref}>
        {Children.map(children, (child, index) => (
          <CarouselItem key={index}>{child}</CarouselItem>
        ))}
      </CarouserContainerInner>
      <LeftCarouselButton hasItemsOnLeft={hasItemsOnLeft} onClick={scrollLeft} aria-label='Previous slide'>
        <ArrowLeft />
      </LeftCarouselButton>
      <RightCarouselButton
        hasItemsOnRight={hasItemsOnRight}
        onClick={scrollRight}
        aria-label='Next slide'
      >
        <ArrowRight />
      </RightCarouselButton>
    </CarouserContainer>
  )
}