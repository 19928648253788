export function formatDate(dateToFormat: string | Date) {
	let date = dateToFormat as Date;
	if (typeof dateToFormat === 'string') {
		date = new Date(dateToFormat);
	}
	const month = date.getMonth() + 1;
	const monthToShow = month < 10 ? `0${month}` : month;
	const day = date.getDate();
	const dayToShow = day < 10 ? `0${day}` : day;
	return `${dayToShow}/${monthToShow}/${date.getFullYear()}`;
}
