import { useEffect, CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { useOpenSeaAPI } from '../../../../hooks/useOpenseaAPI';
import { FeaturedAssets } from '../../components/Featured';
import { Features } from '../../components/Features';
import { MainSection } from './components/MainSection';
import { MintPageContainer, SectionContainer } from './NftMint.styles';

export function NftMint() {
	const { id } = useParams();
	const {
		contract: { contract, getContractById },
	} = useAPI();
	const {
		getCollectionInfo,
		collectionInfo,
		getFeaturedAssetList,
		featuredAssets,
	} = useOpenSeaAPI();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			// ,behavior:'smooth'
		});
		getContractById(id);
	}, []);

	useEffect(() => {
		if (
			contract?._id === id ||
			contract?.openseaCollectionSlug === id ||
			contract?.name === id
		) {
			if (contract?.openseaCollectionSlug) {
				getCollectionInfo(contract.openseaCollectionSlug);
			} else {
				getCollectionInfo(contract.name);
			}
			getFeaturedAssetList(contract.featuredTokenIds, contract.address);
		}
	}, [contract, id]);

	return (
		<MintPageContainer>
			<SectionContainer
				customHeight="calc(100vh - 40px)"
				mobileHeight="auto"
				margin="15%"
			>
				<MainSection collectionInfo={collectionInfo} mintContract={contract} />
			</SectionContainer>
			{!!featuredAssets.length && (
				<SectionContainer customHeight="calc(100vh - 40px)" mobileHeight="auto">
					<FeaturedAssets
						title="More from the collection"
						assets={featuredAssets || []}
					/>
				</SectionContainer>
			)}
			{!!contract?.uniqueFeatures?.length && (
				<SectionContainer
					customHeight="calc(100vh - 40px)"
					mobileHeight="auto"
					background="linear-gradient(180deg, rgba(206, 253, 92, 0) 0%, rgba(117, 251, 76, 0.4) 0.01%, rgba(206, 253, 92, 0) 100%);"
				>
					<Features
						title="Unique Features"
						features={contract?.uniqueFeatures || []}
					/>
				</SectionContainer>
			)}
		</MintPageContainer>
	);
}
