import { useNavigate } from 'react-router-dom';
import { NFTForDisplay } from '../../../../types';
import { useViewport } from '../../../../utils/viewPortHook';
import {
	BottomContainer,
	CardContainer,
	CardTextContainer,
	CardTextContainerOffsetBorder,
	DescriptionText,
	DescriptionTextContainer,
	ImageOffsetBorder,
	InvestButton,
	LargeCardText,
	SmallCardText,
	StyledCardImage,
	StyledCardVideo,
	StyledVideoContainer,
	TextContainer,
	TopTextContainer,
} from './NftDisplayCard.styles';

export function NftDisplayCard({
	network,
	tokenSymbol,
	value,
	description,
	mediaUrl,
	link,
	remainingSupply,
	totalSupply,
	buttonText,
	name,
}: NFTForDisplay) {
	function renderSupply(remainingSupply: number, totalSupply: number) {
		if (remainingSupply != null && totalSupply) {
			return `Supply: ${remainingSupply}/${totalSupply}`;
		} else if (remainingSupply || totalSupply) {
			return `Supply: ${remainingSupply || totalSupply}`;
		}
		return '';
	}
	const { width } = useViewport();
	const breakpoint = 475;
	const navigate = useNavigate();
	return (
		<CardContainer>
			<ImageOffsetBorder></ImageOffsetBorder>
			{/\.mp4/gi.test(mediaUrl) ? (
				<StyledVideoContainer>
					<StyledCardVideo src={mediaUrl} autoPlay loop />
				</StyledVideoContainer>
			) : (
				<StyledCardImage url={mediaUrl} />
			)}

			<CardTextContainerOffsetBorder>
				<CardTextContainer>
					<TextContainer>
						<TopTextContainer>
							<SmallCardText>
								{network && `Network: ${network || 'Ethereum'}`}
							</SmallCardText>
							<SmallCardText>{tokenSymbol || ''}</SmallCardText>
						</TopTextContainer>
						<TopTextContainer>
							<LargeCardText>{name || ''}</LargeCardText>
							<LargeCardText> {value || ''} </LargeCardText>
						</TopTextContainer>
						<DescriptionTextContainer>
							<DescriptionText>{description}</DescriptionText>
						</DescriptionTextContainer>
						<BottomContainer>
							<SmallCardText>
								{renderSupply(remainingSupply as number, totalSupply as number)}
							</SmallCardText>
							<InvestButton
								color="accent"
								onClick={() => {
									if (link.includes('http')) {
										window.open(link, '_blank');
									} else {
										navigate(link);
									}
								}}
							>
								{buttonText}
							</InvestButton>
						</BottomContainer>
					</TextContainer>
				</CardTextContainer>
			</CardTextContainerOffsetBorder>
		</CardContainer>
	);
}
