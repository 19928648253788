import styled from 'styled-components';

export const HighlightedParagraphContainer = styled.div`
	min-width: 200px;
	width: 100%;
	padding: 18px 0px;
`;

export const TitleContainer = styled.div`
	width: 100%;
	position: relative;
	height: 40px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;
export const TitleShape = styled.div<{ color?: string }>`
	width: 100%;
	height: 100%;
	position: relative;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	clip-path: polygon(4% 0%, 100% 0%, 96% 100%, 0% 100%);
	background: ${({ theme: { colors }, color }) =>
		color ? colors[color] || color : colors.primary};
`;

export const HighlightedParagraphTitle = styled.span`
	position: absolute;
	top: -13px;
	left: 5%;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 30px;
`;

export const HighlightedParagraphTextContainer = styled.div<{
	customBackground?: string;
	color?: string;
}>`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	border: 8px solid
		${({ theme: { colors }, color }) =>
			color ? colors[color] || color : colors.primary};
	padding: 30px;
	line-height: 26px;
	background: ${({ customBackground }) => customBackground || 'transparent'};
`;
