import styled from 'styled-components';
import { Icon } from '../Icon';

export const LegendContainer = styled.div<{
	fullWidth?: boolean;
	unclaimed: boolean;
}>`
	min-width: 120px;
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
	height: 100px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 10px 0;
`;
export const LegendTriangle = styled.div<{ color?: string }>`
	width: 0%;
	height: calc(100% - 40px);
	border-bottom: 40px solid
		${({ theme: { colors }, color }) =>
			color ? colors[color] || color : colors.primary};
	border-left: 40px solid transparent;
`;
export const LegendElementcontainer = styled.div<{ color?: string }>`
	width: calc(100% - 40px);
	height: calc(100% - 8px);
	display: flex;
	flex-direction: column;
	border-bottom: 8px solid
		${({ theme: { colors }, color }) =>
			color ? colors[color] || color : colors.primary};
`;
export const LegendTitleContainer = styled.div`
	display: flex;
	justify-content: end;
`;

export const LegendName = styled.span`
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
`;
export const LegendValueContainer = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: end;
`;

export const LegendValue = styled.span`
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 25px;
	padding-bottom: 8px;
`;

export const LegendIcon = styled(Icon)`
	width: 40px;
	height: 40px;
	margin: 8px 12px;
`;

export const LegendBottomLine = styled.div<{ color?: string }>`
	display: flex;
	width: 100%;
	height: 5%;
	border-bottom: 2px solid
		${({ theme: { colors }, color }) =>
			color ? colors[color] || color : colors.primary};
`;
