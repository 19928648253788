import styled from 'styled-components';

export const MainSectionContainer = styled.div`
	width: 80%;
	margin: 3% auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 70%;
	margin: 0 5%;
`;

export const MainSectionTitle = styled.h3`
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
	letter-spacing: -0.02em;
	text-align: left;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const MainSectionDescription = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const ButtonsContainer = styled.div`
	width: 40%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
	align-items: end;
`;

export const FaqsText = styled.h5`
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
	text-align: right1;
	color: #152022;
	padding: 0 20px;
`;
