import {
	Gallery,
	Video,
	VideoSectionContainer,
	NoteCard,
	NoteCardList,
	NoteCardListItem,
	MainContainer,
	GalleryContainer,
} from './VideoSection.styles';
import { galleryItem, galleryItemSource } from '../../../../../../types';
import { Carousel } from '../../../../../../components/Carousel';
import { CarouselContainer } from '../../../../components/Featured/FeaturedCollection.styles';

export function VideoSection({
	gallery,
	color,
}: {
	gallery: galleryItem[];
	color?: string;
}) {
	return (
		<MainContainer>
			<VideoSectionContainer color={color}>
				<CarouselContainer>
					<Carousel>
						{gallery?.map((item, index) => {
							if (item.source === galleryItemSource.image) {
								return (
									<GalleryContainer
										onClick={() => window.open(item.link, '_blank')}
										key={index}
									>
										<Gallery src={item.mediaUrl} />
									</GalleryContainer>
								);
							} else if (item.source === galleryItemSource.youtube) {
								return (
									<Video
										key={index}
										src={item.mediaUrl}
										title={item.title}
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									/>
								);
							} else {
								return (
									<NoteCard key={index}>
										<NoteCardList>
											<NoteCardListItem>{item.title}</NoteCardListItem>
											<NoteCardListItem>read more ↗</NoteCardListItem>
										</NoteCardList>
									</NoteCard>
								);
							}
						})}
					</Carousel>
				</CarouselContainer>
			</VideoSectionContainer>
		</MainContainer>
	);
}
