import { useEffect } from 'react';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { LotteryStatus } from '../../../../types';
import { shortenAddress } from '../../../../utils/shortenAddress';
import { UnderlinedContainer } from '../../components/UnderlinedContainer/UnderlinedContainer';
import { LotteryDetail } from './components/LotteryDetail/LotteryDetail';
import { PrizeSection } from './components/PrizeSection';
import {
	LotteryContainer,
	Title,
	HorizontalSeparator,
	WinnersContainer,
	WinnersText,
	WinnerIdsContainer,
	WinnerIdItem,
	RulesContainer,
	RuleDescription,
} from './Lottery.styles';

export const MOCK_LOTTERY = {
	drawDate: '06/22/2023',
	name: 'Ultra money draw',
	description: 'this is a very dope prize on this draw',
	winnerIds: ['1'],
	deleted: true,
	external: true,
	rules: 'Only eligible if you have a microbes nft',
	eligibility: 'all ids ',
	status: LotteryStatus.open,
	prizeId: '42',
	prizeOpenseaCollectionSlug: 'desciworldmicrobes',
	isNotToken: true,
	prizeDescription: 'string',
};

export function Lottery() {
	const {
		lottery: { getCurrentLottery, lottery, loading },
	} = useAPI();
	useEffect(() => {
		getCurrentLottery();
	}, []);
	return (
		<LotteryContainer>
			<Title>Lottery</Title>
			<UnderlinedContainer>
				<LotteryDetail {...lottery} />
			</UnderlinedContainer>
			{lottery?.winnerIds && (
				<UnderlinedContainer>
					<WinnersContainer>
						<WinnersText>Winning Ids:</WinnersText>
						<WinnerIdsContainer>
							{lottery?.winnerIds.map((id) => {
								return (
									<WinnerIdItem key={id}>
										{id.length > 10 ? shortenAddress(id) : id}
									</WinnerIdItem>
								);
							})}
						</WinnerIdsContainer>
					</WinnersContainer>
				</UnderlinedContainer>
			)}
			<PrizeSection lottery={lottery} />
			<HorizontalSeparator />
			<UnderlinedContainer>
				<RulesContainer>
					<WinnersText>Rules: </WinnersText>
					<RuleDescription>{lottery?.rules}</RuleDescription>
				</RulesContainer>
			</UnderlinedContainer>
		</LotteryContainer>
	);
}
