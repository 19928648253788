import styled from 'styled-components';

export const StyledMenu = styled.nav<{ open?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	height: 40vh;
	text-align: left;
	padding: 2rem;
	position: absolute;
	top: 0;
	left: 0;
	transition: transform 0.3s ease-in-out;
	transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 40%;
	}

	a {
		font-size: 1rem;
		text-transform: uppercase;
		padding: 2rem 0;
		font-weight: bold;
		letter-spacing: 0.5rem;
		color: ${({
			theme: {
				colors: { secondary },
			},
		}) => secondary};
		text-decoration: none;
		transition: color 0.3s linear;

		@media ${({
				theme: {
					device: { mobile },
				},
			}) => mobile} {
			font-size: 0.9rem;
			letter-spacing: 0.3rem;
			text-align: left;
			padding: 1.2rem 0;
		}
	}
`;
