import styled from 'styled-components';

export const JobsContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
	display: flex;
	margin-left: 2rem;
	padding: 0rem 4rem;
	color: black;
	display: flex;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-left: 0px;
		padding: 0rem 1rem;
	}
`;

export const HeaderContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: left;
	gap: 1rem;
	padding: 0.2rem 0rem;
`;

export const DescriptionText = styled.span`
	font-size: 1rem;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.accent};
	letter-spacing: 0.01em;
`;

export const SearchBoxContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: right;
`;

export const JobsList = styled.div`
	width: 100%;
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
	}
`;

export const InputContainer = styled.div`
	display: flex;
	gap: 8px;
	width: 40%;
	padding: 1rem 0rem;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column-reverse;
		width: 100%;
	}
`;
