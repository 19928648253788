import { useLocation } from 'react-router-dom';
import { useWeb3 } from '../../../../hooks/useWeb3';
import { shortenAddress } from '../../../../utils/shortenAddress';
import {
	ConnectWalletButton,
	ButtonContainer,
	InlineIcon,
	IconTextContainer,
} from './NavBar.styles';
import { useWeb3Modal } from '@web3modal/react';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { CHAIN_NAME_BY_ID } from '../../../../constants';
import { Icon } from '../../../../components/Icon';

export function NavBar() {
	const { loading, ready, address, switchChain, chain } = useWeb3();
	const { open } = useWeb3Modal();
	const currentPage = useLocation();
	const pathname = currentPage.pathname;
	return (
		<ButtonContainer>
			{!pathname.includes('jobs') && (
				<ConnectWalletButton
					onClick={() => {
						window.open('https://cutt.ly/RHCFQIh', '_blank');
					}}
				>
					List your project
				</ConnectWalletButton>
			)}
			{pathname.includes('jobs') && (
				<ConnectWalletButton
					onClick={() => {
						window.open('https://airtable.com/shr7ARD2zRS9Fn9kN', '_blank');
					}}
				>
					List your Job
				</ConnectWalletButton>
			)}
			{/* <div style={{ width: 'auto' }}>
				<Dropdown
					placeholder={'Select Network'}
					onChange={({ value }) => {
						switchChain(value);
					}}
					value={
						chain?.id
							? {
									value: chain?.id,
									label: CHAIN_NAME_BY_ID[chain.id],
							  }
							: null
					}
					options={[
						{
							value: 1,
							label: CHAIN_NAME_BY_ID[1],
						},
						{
							value: 4,
							label: CHAIN_NAME_BY_ID[4],
						},
						{
							value: 137,
							label: CHAIN_NAME_BY_ID[137],
						},
						{
							value: 420,
							label: CHAIN_NAME_BY_ID[420],
						},
					]}
				/>
			</div> */}

			<ConnectWalletButton disabled={!!address} onClick={open}>
				<IconTextContainer>
					<InlineIcon name={`chain${chain?.id}`} />
					{!loading && !ready
						? ' Connect wallet'
						: loading && !ready
						? 'Connecting...'
						: shortenAddress(address)}
				</IconTextContainer>
			</ConnectWalletButton>
		</ButtonContainer>
	);
}
