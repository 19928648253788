export const data = {
	network: 'Ethereum',
	title: 'DNAVERSE #1: Cryptoproteins',
	mediaUrl: '',
	description:
		'The 200 essential proteins for Life, with the exact same body structure as in real life. Cryptoproteins are 3D assets with complete scientific accuracy, endorsed by the Protein Data Bank, an internationally renowned scientific organization. The Cryptoproteins contain the grandfather traits of all the DNArts merged into the blockchain. Cryptoproteins holders will also receive a DNArt NFT with genotype 0, becoming the ancestors of human life in the metaverse, and the rulers of the Replication Breeding Process.',
	totalSupply: 150,
	publicSalePrice: '1.618',
	whitelistPrice: ' 1.37',

	infoTitle: 'Cryptoproteins Info',
	info: [
		{
			title: 'Why are CRYPTOPROTEINS so important?',
			mediaUrl:
				'https://dnaverse.io/wp-content/uploads/2022/03/Protein-360.png',
			description:
				'Similar to proteins being the genesis of life, CRYPTOPROTEINS are the Genesis NFTs of DNAVERSE. The Cryptoproteins are the pillars of the project and LIMITED to only 200 NFTs. They provide ancestor traits, rarity and replication power to codify new DNA Pass NFTs. They are the rulers of the replication process and grant OG status for holders, including DAO membership, private airdrops, passive income, etc.',
		},
		{
			title: 'What is a DNA Pass?',
			mediaUrl:
				'https://dnaverse.io/wp-content/uploads/2022/03/dna-pass-360.png',
			description:
				'Cryptoproteins holders are airdropped a free DNA Pass. The DNA Pass is a redeemable ticket (a minting pass) that allows holders to receive an individually customized DNArt NFT. There will only be 3,200 centralized and price-controlled DNA Passes. Once the minting is over, the secondary market and the Replication process (Marketlab) will be the only way to obtain new DNA Passes to be redeemed.',
		},
		{
			title: 'Who is the team behind this project?',
			mediaUrl: 'https://dnaverse.io/wp-content/uploads/2022/03/dnart-360.png',
			description:
				'DNAVERSE was created by 3DforScience, one of the world’s most influential scientific visualization studios. With over 10 years of experience and international recognition, a team of 28 individuals has been brought together to develop the BIOmetaverse, including all planned additions to deliver extraordinary benefits and an amazing experience to our NFT community.',
		},
	],
};
