import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useThirdPatryAPIs } from '../../../../hooks/useAPI';
import { useAPI } from '../../../../hooks/useDesciWorldAPI';
import { useSnapshotAPI } from '../../../../hooks/useSnapshotAPI';
import { LockIcon } from '../../components/Footer/Footer.styles';
import { InvestButton } from '../../components/NftDisplayCard/NftDisplayCard.styles';
import { ButtonContainer } from '../Dashboard/components/TableSection/TableSection.styles';
import { SectionContainer } from '../NftMint/NftMint.styles';
import { MainSection } from './components/MainSection';
import { VideoSection } from './components/VideoSection';
import { ProjectContainer, VideoSectionContainer } from './Project.styles';

export function ProjectPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const {
		organization: {
			getOrganizationById,
			clearOrganization,
			organization,
			loading: organizationLoading,
		},
	} = useAPI();
	const {
		recentTweets,
		clearState,
		getRecentTweets,
		getPriceChartData,
		historicalPrices,
		loading: chartLoading,
	} = useThirdPatryAPIs();

	const {
		proposals,
		totalMembers,
		getProposalsById,
		getTotalFollowerCountById,
		clearProposals,
		loading: proposalLoading,
	} = useSnapshotAPI();
	useEffect(() => {
		getOrganizationById(id);
	}, [id]);

	useEffect(() => {
		return () => {
			clearOrganization();
			clearState();
			clearProposals();
		};
	}, []);

	useEffect(() => {
		if (!organizationLoading && !proposalLoading) {
			if (organization?.snapshotId) {
				console.log({ orgId: organization.snapshotId });
				getProposalsById(organization.snapshotId);
				getTotalFollowerCountById(organization.snapshotId);
			} else {
				clearProposals();
			}

			if (organization?.twitterHandle) {
				getRecentTweets(organization.twitterHandle);
			}
			if (organization?.coingeckoId) {
				getPriceChartData(organization?.coingeckoId);
			}
		}
	}, [organization, organization?.snapshotId]);
	return (
		<ProjectContainer loading={organizationLoading || proposalLoading}>
			{!organizationLoading && !proposalLoading && (
				<>
					<SectionContainer customHeight="max-content">
						<MainSection
							totalMembers={totalMembers}
							proposals={proposals}
							organization={organization}
							tweets={recentTweets}
							chartData={historicalPrices}
							chartLoading={chartLoading}
							proposalLoading={proposalLoading}
						/>
					</SectionContainer>
					{!!organization?.gallery?.length && (
						<VideoSectionContainer
							customHeight="max-content"
							unclaimed={organization?.unclaimed}
						>
							<LockIcon unclaimed={organization?.unclaimed} />
							<VideoSection
								color={organization?.primaryColor}
								gallery={organization?.gallery}
							/>
						</VideoSectionContainer>
					)}

					<ButtonContainer>
						<InvestButton
							color="accent"
							onClick={() => {
								navigate('/dashboard');
							}}
						>
							Back to projects
						</InvestButton>
					</ButtonContainer>
				</>
			)}
			{organizationLoading && 'Loading project...'}
		</ProjectContainer>
	);
}
