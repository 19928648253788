import styled from 'styled-components';
import { Button } from '../../../../../../components/Button';
import { LogoItem } from '../../../Project/components/NewsSidebar/NewsSidebar.styles';

export const MainSectionContainer = styled.div`
	width: 80%;
	height: 100%;
	box-sizing: border-box;
	background: transparent;
	display: flex;
	align-items: center;
	margin: auto;
	flex-direction: column;
	margin-top: 80px;
	box-sizing: border-box;
	padding: 24px;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		margin: auto;
		margin-top: auto;
	}
`;

export const Container = styled.div`
	box-sizing: border-box;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
	}
`;

export const LeftContentContainer = styled.div`
	width: 60%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	box-sizing: border-box;
	filter: drop-shadow(8px 8px 20px rgba(0, 48, 63, 0.2));
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const InfoMessage = styled.div`
	margin: 10px 0;
	padding: 10px;
	border-radius: 3px 3px 3px 3px;
	font-size: 12px;
	color: ${({ theme: { colors } }) => colors.accent};
	background-color: ${({ theme: { colors } }) => `${colors.primary}55`};
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: <center></center>;
`;

export const DisclaimerText = styled.span`
	margin: 2% 0;
	font-size: 14px;
	line-height: 20px;
	opacity: 0.7;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
`;

export const ImageContainer = styled.img`
	width: 464px;
	height: 464px;
	border: 2px solid #ffffff;

	box-sizing: border-box;
	filter: drop-shadow(8px 8px 20px rgba(0, 48, 63, 0.2));
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 330px;
		height: 330px;
	}
`;

export const RightContentContainer = styled.div`
	width: 70%;
	height: 100%;
	display: flex;
	align-items: center;
	background: transparent;
	justify-content: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
	}
`;

export const WhitelistDescriptionContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	background: transparent;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
`;

export const NetworkName = styled.h5`
	margin: 0;
	box-sizing: border-box;
	font-size: 16px;
	line-height: 18px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
	}
`;

export const WhitelistInfoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0px; ;
`;

export const JoinLink = styled.a`
	text-decoration: none;
	color: inherit;
`;

export const WhitelistName = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 6.2vh;
	line-height: 6.3vh;
	letter-spacing: -0.02em;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-weight: bold;
		font-size: 24px;
	}
`;

export const WhitelistPriceContainer = styled.div`
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
`;

export const WhitelistCurrencyText = styled.span`
	font-size: 16px;
	line-height: 30px;
	text-align: right;
	margin: 0;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	opacity: 0.7;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
	}
`;

export const WhitelistPriceText = styled.p`
	font-size: 30px;
	line-height: 30px;
	text-align: right;
	margin: 0;
	letter-spacing: 0.02em;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 20px;
	}
`;

export const WhitelistDescription = styled.span`
	font-size: 16px;
	line-height: 18px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
		line-height: 20px;
	}
`;

export const WhitelistDescriptionFooterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding-top: 30px;
	justify-self: flex-end;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin: 20px 0;
	}
`;

export const WhitelistSuply = styled.div`
	margin: 10px 0;
	flex: 2;
	font-size: 16px;
	line-height: 30px;
	color: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};

	&:span {
		opacity: 0, 7;
	}

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 15px;
	}
`;

export const MintButton = styled(Button)`
	width: 200px;
	height: 50px;
	align-self: center;
	button {
		padding: 6px;
	}
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		width: 100%;
		margin-right: 10px;
	}
`;

export const StyledLogoItem = styled(LogoItem)`
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: none;
	}
`;
