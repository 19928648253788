import styled from 'styled-components';

export const FifthSectionContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 0.5rem;
	box-sizing: border-box;
	background: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	display: flex;
	/* justify-content:center;*/
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;
export const FifthSectionCardsContainer = styled.div`
	width: 100%;
	height: 70%;
	background: ${({
		theme: {
			colors: {
				background: { softBlack },
			},
		},
	}) => softBlack};
	display: flex;
	/* justify-content:center;*/
	align-items: center;
	flex-direction: row;
	justify-content: center;

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		height: 85%;
	}
`;

export const FifthSectionPageTitle = styled.span`
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
	color: ${({
		theme: {
			colors: { secondary },
		},
	}) => secondary};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		display: flex;
		height: 5%;

		text-align: center;
		justify-content: center;
		/* margin: 40px 0; */
	}
`;

export const FifthSectionTextContainer = styled.div<{
	background?: any;
	position?: string;
}>`
	padding: 48px;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background: transparent url(${({ background }) => background})
		${({ position }) => {
			switch (position) {
				case 'top-left':
					return ' 0% -20%/250px 250px  no-repeat';
				case 'top-right':
					return ' 100% -30%/250px 250px  no-repeat';
				case 'bottom-right':
					return '100% 120%/250px 250px no-repeat';
				default:
					return ' 0% -20%/250px 250px  no-repeat';
			}
		}};

	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		background: transparent url(${({ background }) => background})
			${({ position }) => {
				switch (position) {
					case 'top-left':
						return ' 50% -5%/150px 150px  no-repeat';
					case 'top-right':
						return ' 50% -5%/150px 150px  no-repeat';
					case 'bottom-right':
						return '50% -5%/150px 150px no-repeat';
					default:
						return ' 50% -5%/150px 150px  no-repeat';
				}
			}};
		height: auto;
		justify-content: flex-end;
	}
`;

export const FifthSectionTitle = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 48px;
	line-height: 48px;

	color: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	justify-content: center;
	text-align: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 8px;
		font-weight: bold;
		font-size: 32px;
		line-height: 34px;
	}
`;

export const FifthSectionSubtitle = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 48px;
	color: ${({
		theme: {
			colors: { primary },
		},
	}) => primary};
	justify-content: center;
	text-align: center;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		padding: 8px;
		font-size: 30px;
		line-height: 32px;
	}
`;

export const FifthSectionText = styled.span`
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		font-size: 16px;
		line-height: 18px;
		width: 100%;
		padding: 6px;
	}
`;
