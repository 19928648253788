import styled from 'styled-components';

export const RecentDevelopmentContainer = styled.div<{
	unclaimed: Boolean;
}>`
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	margin: 2% 0;
	display: flex;
	position: relative;
	flex-direction: column;
`;

export const RecentDevelopmentTitle = styled.h3`
	font-weight: bold;
	font-size: 30px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
`;

export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background-color: #f4f4f4;
	height: 200px;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		flex-direction: column;
		height: auto;
	}
`;
