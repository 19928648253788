import { useViewport } from '../../../../../utils/viewPortHook';
import { DecoratedParagraph } from '../../../components/DecoratedParagraph';
import { LandingHighlightedText } from '../../../components/LandingHighlightedText';
import {
	ThirdSectionContainer,
	ThirdSectionText,
} from '../../../components/ThirdSection/ThirdSection.styles';
import { AccentText } from '../../SofiaLanding.styles';
import {
	ColoredText,
	InfoSectionTextContainer,
	MainTextContainer,
} from './InfoSection.styles';

export function InfoSection() {
	const { width } = useViewport();
	const breakpoint = 475;
	const isMobile = width < breakpoint;
	return (
		<ThirdSectionContainer>
			<MainTextContainer>
				<ThirdSectionText>
					Built to{' '}
					<LandingHighlightedText color="secondary" noTransform>
						incubate
					</LandingHighlightedText>{' '}
					and{' '}
					<LandingHighlightedText color="tertiary" noTransform>
						empower
					</LandingHighlightedText>{' '}
					local DeSci communities
				</ThirdSectionText>
				<ColoredText>• network & idea share</ColoredText>
				<ColoredText>• funding</ColoredText>
				<ColoredText>• recruitment & consultation</ColoredText>
				<ColoredText>• Business Development</ColoredText>
				<ColoredText>• signal boosting & outreach</ColoredText>
				<ColoredText>• Web3 marketing</ColoredText>
			</MainTextContainer>
			<InfoSectionTextContainer>
				{!isMobile && (
					<DecoratedParagraph lineCount={isMobile ? 2 : null}>
						<strong>World of DeSci</strong> is a global conference circuit
						powered by <strong>DeSciWorld</strong>, designed to bring DeSci to
						researchers and help the world’s scientific community explore its
						potential.
						<br />
						<br />
						We are working with Serbian universities, founders and innovators to
						deliver a conference that is both locally and globally relevant.
						World of DeSci aims to foster a globally distributed, aligned, and
						connected network of DeSci communities. <strong>
							DeSci Sofia
						</strong>{' '}
						will be incubated in the process of this conference, serving as a
						central hub for the activities and support of the Serbian
						decentralised science community.
						<br />
						<br />
						Science is changing and the rise of Web3 and decentralised
						technologies has created space for DeSci to grow. Now more than ever
						it is a great time to explore this emerging technology.
					</DecoratedParagraph>
				)}
				{isMobile && (
					<AccentText>
						<strong>World of DeSci</strong> is a global conference circuit
						powered by <strong>DeSciWorld</strong>, designed to bring DeSci to
						researchers and help the world’s scientific community explore its
						potential.
						<br />
						<br />
						We are working with Serbian universities, founders and innovators to
						deliver a conference that is both locally and globally relevant.
						World of DeSci aims to foster a globally distributed, aligned, and
						connected network of DeSci communities. <strong>
							DeSci Sofia
						</strong>{' '}
						will be incubated in the process of this conference, serving as a
						central hub for the activities and support of the Serbian
						decentralised science community.
						<br />
						<br />
						Science is changing and the rise of Web3 and decentralised
						technologies has created space for DeSci to grow. Now more than ever
						it is a great time to explore this emerging technology.
					</AccentText>
				)}
			</InfoSectionTextContainer>
		</ThirdSectionContainer>
	);
}
