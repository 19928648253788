import { useNavigate } from 'react-router-dom';
import { NavBar } from '../components/NavBar';
import { MainSection } from './components/MainSection';
import { ScrollableToSection } from '../Landing.styles';
import { IntroSection } from './components/IntroSection';
import { InfoSection } from './components/InfoSection';
import { SpeakerSection } from './components/SpeakersSection';
import { DeSciSection } from './components/DesciSection';
import { Footer } from '../components/Footer';
import { PartnerSection } from './components/PartnerSection';
import { useRef } from 'react';
import { PresentationSection } from './components/PresentationSection';

export function SofiaLanding() {
	const navigate = useNavigate();
	const routes = [
		{
			title: 'NFT',
			onClick() {
				navigate('/nft', { replace: true });
			},
		},
		{
			title: 'FAQ',
			onClick() {
				navigate('/faqs', { replace: true });
			},
		},
		{
			title: 'Documentation',
			onClick() {
				window.open(
					'https://firebasestorage.googleapis.com/v0/b/desci-5b3ec.appspot.com/o/DeSciWorld%20Whitepaper%20(3).pdf?alt=media&token=70573b9d-6236-4b05-83b9-11d1cb97a10a',
					'_blank',
				);
			},
		},
		{
			title: 'Contact Us',
			onClick() {
				navigate('/contact', { replace: true });
			},
		},
		{
			title: 'About Us',
			onClick() {
				navigate('/About', { replace: true });
			},
		},
	];
	const introRef = useRef(null);
	return (
		<>
			<NavBar routes={routes} />
			<ScrollableToSection
				customHeight="calc(100vh - 70px)"
				mobileHeight="calc(130vh - 70px)"
			>
				<MainSection scrollToRef={introRef} />
			</ScrollableToSection>
			<ScrollableToSection
				customHeight="70vh"
				mobileHeight="max-content"
				ref={introRef}
			>
				<IntroSection />
			</ScrollableToSection>

			<ScrollableToSection customHeight="70vh" mobileHeight="max-content">
				<InfoSection />
			</ScrollableToSection>
			<ScrollableToSection
				customHeight="max-content"
				mobileHeight="max-content"
			>
				<PresentationSection />
			</ScrollableToSection>
			<ScrollableToSection customHeight="max-content">
				<SpeakerSection />
			</ScrollableToSection>
			<ScrollableToSection fullHeight mobileHeight="calc(200vh - 70px)">
				<DeSciSection />
			</ScrollableToSection>
			<ScrollableToSection customHeight="30vh" mobileHeight="calc(80vh - 70px)">
				<PartnerSection />
			</ScrollableToSection>
			<ScrollableToSection customHeight="20vh" mobileHeight="calc(20vh - 70px)">
				<Footer />
			</ScrollableToSection>
		</>
	);
}
