import { Legend } from '../../../../../../components/Legend';
import {
	ButtonWithMargin,
	LogoContainer,
	LogoItem,
	NewsSidebarContainer,
	NoteContainer,
	NoteItem,
	NoteItemDescription,
	NoteItemTitle,
	NoteTitle,
} from './NewsSidebar.styles';
import twitter from '../../../../../../assets/icons/twitter.svg';
import telegram from '../../../../../../assets/icons/telegram.svg';
import discord from '../../../../../../assets/icons/discord.svg';
import website from '../../../../../../assets/icons/website.svg';
import { Organization, SnapshotProposal } from '../../../../../../types';
import { LockIcon } from '../../../../components/Footer/Footer.styles';

export function NewsSidebar({
	organization,
	proposals,
	totalMembers,
}: {
	organization: Organization;
	proposals: SnapshotProposal[];
	totalMembers: number;
}) {
	return (
		<NewsSidebarContainer>
			<LogoContainer unclaimed={organization?.unclaimed}>
				<LockIcon unclaimed={organization?.unclaimed} />
				{(organization?.website || organization?.whitePaperLink) && (
					<LogoItem
						src={website}
						href={organization?.website || organization?.whitePaperLink}
						target="_blank"
					/>
				)}
				{organization?.twitterHandle && (
					<LogoItem
						src={twitter}
						href={`https://twitter.com/${organization.twitterHandle}`}
						target="_blank"
					/>
				)}

				{organization?.discordLink && (
					<LogoItem
						src={discord}
						href={organization.discordLink}
						target="_blank"
					/>
				)}
				{organization?.telegramLink && (
					<LogoItem
						src={telegram}
						href={organization.telegramLink}
						target="_blank"
					/>
				)}
			</LogoContainer>
			{(organization?.callToActionLink || organization?.website) && (
				<ButtonWithMargin
					onClick={() => {
						window.open(
							organization.callToActionLink || organization.website,
							'_blank',
						);
					}}
					disabled={organization?.unclaimed}
					unclaimed={organization?.unclaimed}
				>
					Contribute ⭐{' '}
				</ButtonWithMargin>
			)}
			{!!organization?.whitePaperLink && (
				<ButtonWithMargin
					color={`${organization?.primaryColor}ff`}
					onClick={() => {
						window.open(organization.whitePaperLink, '_blank');
					}}
					unclaimed={organization?.unclaimed}
					disabled={organization?.unclaimed}
				>
					WHITEPAPER{' '}
				</ButtonWithMargin>
			)}
			{!!totalMembers && (
				<Legend
					unclaimed={organization?.unclaimed}
					name="Active DAO Members"
					value={totalMembers + ''}
					icon="people"
					color={organization?.primaryColor}
				/>
			)}
			{!!proposals?.length && (
				<NoteContainer
					color={organization?.primaryColor}
					unclaimed={organization?.unclaimed}
				>
					<NoteTitle>
						DAO
						<br /> Proposals
					</NoteTitle>
					{proposals.map(({ title, body, link }, index) => {
						return (
							<NoteItem key={index}>
								<NoteItemTitle href={link} target="_blank">
									🗳 {title}
								</NoteItemTitle>
								<NoteItemDescription>
									{body.substring(0, 36)}...
								</NoteItemDescription>
							</NoteItem>
						);
					})}
					<LockIcon unclaimed={organization?.unclaimed} />
				</NoteContainer>
			)}
			{/* <NoteContainer>
				<NoteTitle>EVENTS</NoteTitle>

				<NoteItemEvent>
					<NoteItemTitleDate>Nov</NoteItemTitleDate>
					<NoteItemDescriptionEvent>
						<NoteItemDate>4</NoteItemDate>
						<NoteItemDateDescription>NYC Meet-up</NoteItemDateDescription>
					</NoteItemDescriptionEvent>
				</NoteItemEvent>
				<NoteItemEvent>
					<NoteItemTitleDate>Nov</NoteItemTitleDate>
					<NoteItemDescriptionEvent>
						<NoteItemDate>4</NoteItemDate>
						<NoteItemDateDescription>NYC Meet-up</NoteItemDateDescription>
					</NoteItemDescriptionEvent>
				</NoteItemEvent>
			</NoteContainer> */}
		</NewsSidebarContainer>
	);
}
