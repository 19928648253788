import styled from 'styled-components';

export const ProjectContainer = styled.div<{ loading?: boolean }>`
	width: 80%;
	margin: 8vh auto;
	max-width: 1000px;
	display: flex;
	align-items: center;
	color: ${({
		theme: {
			colors: { accent },
		},
	}) => accent};
	font-family: ${({
		theme: {
			fonts: { paragraph },
		},
	}) => paragraph};
	flex-direction: column;
	${({ loading }) => loading && 'height:100vh;'}
	@media ${({
		theme: {
			device: { mobile },
		},
	}) => mobile} {
		margin: 0 auto;
		width: 90%;
	}
`;

export const VideoSectionContainer = styled.section<{
	fullHeight?: boolean;
	customHeight?: string;
	mobileHeight?: string;
	background?: string;
	margin?: string;
	unclaimed: boolean;
}>`
	height: ${({ fullHeight, customHeight }) =>
		customHeight ? customHeight : fullHeight ? '100vh' : 'auto'};
	background: ${({ background }) => background};
	opacity: ${(props) => (props.unclaimed ? '0.4' : 'none')};
	width: 100%;
	@media ${({
			theme: {
				device: { mobile },
			},
		}) => mobile} {
		margin-bottom: ${({ margin }) => margin};
		height: ${({ fullHeight, mobileHeight }) =>
			mobileHeight ? mobileHeight : fullHeight ? '100vh' : 'auto'};
	}
`;
